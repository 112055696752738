<div class="inline-edit-section" *ngIf="isEditModeActive()" #inlineDialog>
    <div class="editing-section-overlay" (click)="outsideClick()"></div>
    <div class="editable-section">
        <div class="inline-edit-content">
            <cb-dynamic-factory
                [component]="component"
                [options]="options"
                [scrollToElement]="inlineDialog"
            >
            </cb-dynamic-factory>
        </div>
    </div>
</div>
