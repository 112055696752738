import { Component, Input } from '@angular/core';
import { SurveyItemView } from '../../../infrastructure/consts/surveys.consts';
import { transformToCloudfrontUrl } from '../../../infrastructure/helpers/cloudfront.helper';

@Component({
    selector: 'cb-item-type',
    templateUrl: './item-type.component.html',
    styleUrls: ['./item-type.component.scss']
})
export class ItemTypeComponent {
    @Input() itemType: string;
    @Input() showTooltip: boolean = false;
    @Input() isMatrix: boolean = false;
    public questionView = SurveyItemView;

    public getSvgPath(surveyViewIndex: string): string {
        const svgName = this.questionView[surveyViewIndex].svgName;
        return transformToCloudfrontUrl(`assets/icons/${svgName}.svg`);
    }
    public getSvgIconName(surveyViewIndex: string): string {
        const svgName = this.questionView[surveyViewIndex].svgName;
        return svgName;
    }
    public getTooltipTextKey(surveyViewIndex: string): string {
        if (!this.showTooltip)
            return '';
        
        return this.isMatrix
            ? this.questionView[surveyViewIndex].matrixColumnTooltip
            : this.questionView[surveyViewIndex].tooltip;
    }
}
