import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { SharedService } from '../../../../../../infrastructure/services';
import { SurveysPagesActions } from '../../../../../../infrastructure/consts/surveys.consts';
import { ContactFormFieldDialogComponent } from '../contact-form-field-dialog/contact-form-field-dialog.component';
import { SurveyQuestionOptions } from '../../../../../models/page-items.model';
import { QuestionItem } from '../../../../../../shared/models/survey-items/question-items/questionItem';
import { SurveyPage } from '../../../../../../infrastructure/models/survey-page.model';
import { Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../../../../shared/decorators/autoUnsubscribe.decorator';
import { takeUntil } from 'rxjs/operators';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ContactFormField } from '../../../../../../shared/models/survey-items/question-items/contactFormItem';
import { SINGLE_LINE_TYPES } from '../../../../../../shared/models/survey-items/question-items/singleLineItem';
import { scrollIntoView } from '../../../../../../infrastructure/helpers/javascript.helper';

@Component({
    selector: 'cb-contact-form-fields',
    templateUrl: './contact-form-fields.component.html',
    styleUrls: ['./contact-form-fields.component.scss']
})
@AutoUnsubscribe()
export class ContactFormFieldsComponent implements OnInit, OnDestroy {
    @Input() fields: ContactFormField[];
    @Input() contactFormItemId: number;
    @Input() surveyId: number;
    @Input() pageId: number;
    @Input() softRequiredEnabled: boolean;

    @Output() fieldsUpdated = new EventEmitter<ContactFormField[]>();

    @ViewChild('formFieldList') private formFieldListRef: ElementRef;

    public dragAndDrop: any;
    public editFieldOptions: SurveyQuestionOptions;
    public fieldEditorComponent: any;
    public isEditFormFieldProcess = false;
    public contactFormItemPage: SurveyPage;
    public formFieldQuestion: QuestionItem;
    private answerFormats = Object.entries(SINGLE_LINE_TYPES);
    private componentDestroyed = new Subject();

    constructor(
        private sharedService: SharedService
    ) {}

    ngOnInit() {
        this.fields.sort(function(a, b) {
            return a.position - b.position;
        });
        this.shareFields();

        this.contactFormItemPage = <SurveyPage>{ id: this.pageId };
        this.dragAndDropInit();

        this.sharedService
            .getData(SurveysPagesActions.UPDATE_CONTACT_FORM_FIELD)
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe((message: any) => {
                this.updateField(message.data);
                this.hideFormFieldDialog();
            });

        this.sharedService
            .getData(SurveysPagesActions.CANCEL_CONTACT_FORM_FIELD)
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(() => {
                this.hideFormFieldDialog();
            });
    }

    private shareFields(): void {
        this.fieldsUpdated.emit(this.fields);
    }

    public getAnswerFormatTextKey(field: ContactFormField) {
        const answerFormatIndex = this.answerFormats.findIndex(f => f[0] === field.question.answer_format);
        return answerFormatIndex > -1
            ? this.answerFormats[answerFormatIndex][1]
            : this.answerFormats[0][1];

    }

    private dragAndDropInit(): void {
        this.dragAndDrop = {
            onUpdate: (event: any) => {
                this.updatePositions();
            }
        };
    }

    onDrop(event: CdkDragDrop<any[]>) {
        moveItemInArray(this.fields, event.previousIndex, event.currentIndex);
        this.updatePositions();
    }

    private updatePositions() {
        let i = 1;
        this.fields.forEach(c => {
            c['position'] = i;
            i++;
        });
        this.shareFields();
    }

    public onToggleFormFieldVisibility(index: number): void {
        this.fields[index].enabled = !this.fields[index].enabled;
        this.shareFields();
    }

    public onEditFormFieldClick(index: number): void {
        const formField = this.fields[index];
        this.formFieldQuestion = formField.question;
        this.fieldEditorComponent = ContactFormFieldDialogComponent;
        this.editFieldOptions = <SurveyQuestionOptions>{
            itemType: formField.question?.item_type,
            surveyId: this.surveyId.toString(),
            pageId: this.pageId,
            itemData: formField.question,
            contactFormFieldSysName: formField.system_name,
            parentQuestionId: this.contactFormItemId,
            softRequiredEnabled: this.softRequiredEnabled
        };
        this.isEditFormFieldProcess = true;
    }

    private updateField(data: any): void {
        if (!data || !this.editFieldOptions) {
            return;
        }
        
        const editFieldSysName = this.editFieldOptions.contactFormFieldSysName;
        const editFieldIndex = this.fields.findIndex(c => c.system_name === editFieldSysName);

        if (editFieldIndex > -1) {
            this.fields[editFieldIndex].question = data;
            this.shareFields();
        }
    }

    private hideFormFieldDialog() {
        this.fieldEditorComponent = null;
        this.editFieldOptions = null;
        this.isEditFormFieldProcess = false;

        setTimeout(() => {
            scrollIntoView(this.formFieldListRef.nativeElement);
        }, 0);
    }

    ngOnDestroy() {}
}
