<form [formGroup]="form" autocomplete="off">
    <div class="form-section">
        <cb-question-item-title></cb-question-item-title>
        <div class="cb-change-type-section">
            <cb-item-type [itemType]="imageChoice"></cb-item-type>
        </div>
        <cb-question-and-subtext
            [questionItem]="questionItem"
        ></cb-question-and-subtext>
    </div>
    <div class="form-section">
        <cb-multiple-options-image-item
            [options]="questionItem.choices"
            [questionType]="imageChoice"
            [resetChoicesEvent] = "resetChoicesSubject.asObservable()"
        ></cb-multiple-options-image-item>
    </div>
    <cb-behavior-options [parentForm]="form" [softRequiredEnabled]="questionItem.softRequiredEnabled">
        <div class="form-fields-group">                
            <mat-form-field>
                <input
                    matInput
                    type="number"
                    autocomplete="off"
                    (keypress)="onNumericKeyUp($event)"
                    (change)="onMinSelectChange($event)"
                    placeholder="{{
                        'SURVEY-EDITOR.ITEMS.CHECKBOX.MIN-SELECT'
                            | translate
                    }}"
                    formControlName="select_min_limit"
                />
            </mat-form-field>
            <mat-form-field>
                <input
                    matInput
                    type="number"
                    autocomplete="off"
                    (keypress)="onNumericKeyUp($event)"
                    placeholder="{{
                        'SURVEY-EDITOR.ITEMS.CHECKBOX.MAX-SELECT'
                            | translate
                    }}"
                    formControlName="select_max_limit"
                />
            </mat-form-field>
            <mat-checkbox
                class="cb-form-field"
                formControlName="randomize"
            >
                {{ 'SURVEY-EDITOR.SHARED.RANDOMIZE' | translate }}
            </mat-checkbox>
        </div>
    </cb-behavior-options>

    <div class="form-section border-top">
        <cb-question-collapsed-panel
            title="{{
                'SURVEY-EDITOR.SHARED.APPEARANCE.APPEARANCE' | translate
            }}"
        >

            <div class="form-fields-group">
                <mat-form-field class="cb-form-field">
                    <mat-select
                        placeholder="{{
                            'SURVEY-EDITOR.SHARED.APPEARANCE.LAYOUT' | translate
                        }}"
                        formControlName="layout"
                    >
                        <mat-option value="Vertical"
                        >{{
                            'SURVEY-EDITOR.SHARED.APPEARANCE.VERTICAL'
                                | translate
                            }}
                        </mat-option>
                        <mat-option value="Horizontal">
                            {{
                            'SURVEY-EDITOR.SHARED.APPEARANCE.HORIZONTAL'
                                | translate
                            }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field>
                    <input
                        matInput
                        type="number"
                        autocomplete="off"
                        (keypress)="onNumericKeyUp($event)"
                        placeholder="{{
                            'SURVEY-EDITOR.SHARED.APPEARANCE.IMAGE_WIDTH'
                                | translate
                        }}"
                        formControlName="image_width"
                    />
                </mat-form-field>
                <mat-form-field>
                    <input
                        matInput
                        type="number"
                        autocomplete="off"
                        (keypress)="onNumericKeyUp($event)"
                        placeholder="{{
                            'SURVEY-EDITOR.SHARED.APPEARANCE.IMAGE_HEIGHT'
                                | translate
                        }}"
                        formControlName="image_height"
                    />
                </mat-form-field>
            </div>
            <div class="form-fields-group">
                <div class="form-group-position">
                    <cb-label-position
                        class="position-control-group"
                        [selectedOption]="textPosition"
                        (selected)="labelPositionSelected($event)"
                    ></cb-label-position>
                    <cb-item-position
                        class="position-control-group"
                        [selectedOption]="itemPosition"
                        (selected)="itemPositionSelected($event)"
                    ></cb-item-position>
                </div>
                <mat-checkbox
                    class="cb-form-field"
                    formControlName="show_text_labels"
                >
                    {{
                    'SURVEY-EDITOR.SHARED.APPEARANCE.SHOW-TEXT-LABELS'
                        | translate
                    }}
                </mat-checkbox>
            </div>
        </cb-question-collapsed-panel>
    </div>

    <div class="form-section">
        <cb-question-collapsed-panel
            title="{{ 'SURVEY-EDITOR.SHARED.CONDITIONS' | translate }}"
            [tooltip]="'SURVEY-EDITOR.SHARED.TOOLTIPS.CONDITIONS' | translate"
        >
            <cb-groups-expressions-element
                [source]="questionItem"
            ></cb-groups-expressions-element>
        </cb-question-collapsed-panel>
    </div>
</form>
