<form [formGroup]="form">
    <h3>{{ 'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.MESSAGE-FORMAT' | translate }}</h3>
    <mat-radio-group
        class="action-item-controls"
        (change)="onFormatChanged($event)"
    >
        <mat-radio-button value="Html" [checked]="messageFormat === 'Html'"
            >Html</mat-radio-button
        >
        <mat-radio-button value="Text" [checked]="messageFormat === 'Text'">{{
            'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.TEXT' | translate
        }}</mat-radio-button>
    </mat-radio-group>
    <br />
    <br />
    <mat-form-field *ngIf="messageFormat === 'Text'">
        <textarea
            matInput
            placeholder="{{
                'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.MESSAGE' | translate
            }}"
            formControlName="body"
        ></textarea>
    </mat-form-field>
    <div *ngIf="messageFormat === 'Html'">
        <cb-tiny-rich-editor
            formControlName="body"
            #messageBodyEditor
        ></cb-tiny-rich-editor>
        <cb-merge-text-list
            [surveyId]="actionItem.surveyId"
            [pageId]="actionItem.page_id"
            [returnCode]="true"
            (updateText)="mergeInTinyEditor($event)"
        >
        </cb-merge-text-list>
    </div>
    <br />
    <!-- todo - remove this when its time to implement the style feature -->
    <mat-form-field *ngIf="false">
        <mat-select
            placeholder="{{
                'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.STYLE-TEMPLATE' | translate
            }}"
            formControlName="style_template_id"
            [(value)]="actionItem.style_template_id"
        >
            <mat-option
                *ngFor="let styleTemplate of styleTemplateList"
                [value]="styleTemplate.template_id"
                [innerHTML]="styleTemplate.name | keepHtml"
            ></mat-option>
        </mat-select>
    </mat-form-field>
    <br />
    <mat-checkbox
        (change)="onSendOnceChanged($event)"
        [checked]="actionItem.send_once"
        >{{
            'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.SEND-ONCE' | translate
        }}</mat-checkbox
    >
    <div>
        <mat-form-field>
            <input
                matInput
                placeholder="{{
                    'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.FROM-EMAIL' | translate
                }}"
                formControlName="from"
            />
        </mat-form-field>
        <cb-merge-text-list
            [surveyId]="actionItem.surveyId"
            [pageId]="actionItem.page_id"
            [text]="editorText"
            (updateText)="onMergeText($event, 'from')"
        >
        </cb-merge-text-list>
    </div>
    <div>
        <mat-form-field>
            <input
                matInput
                placeholder="{{
                    'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.TO-EMAIL' | translate
                }}"
                formControlName="to"
            />
        </mat-form-field>
        <cb-merge-text-list
            [surveyId]="actionItem.surveyId"
            [pageId]="actionItem.page_id"
            [text]="editorText"
            (updateText)="onMergeText($event, 'to')"
        >
        </cb-merge-text-list>
    </div>
    <div>
        <mat-form-field>
            <input
                matInput
                placeholder="{{
                    'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.SUBJECT' | translate
                }}"
                formControlName="subject"
            />
        </mat-form-field>
        <cb-merge-text-list
            [surveyId]="actionItem.surveyId"
            [pageId]="actionItem.page_id"
            [text]="editorText"
            (updateText)="onMergeText($event, 'subject')"
        >
        </cb-merge-text-list>
    </div>
    <div>
        <mat-form-field>
            <input
                matInput
                placeholder="{{
                    'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.BCC' | translate
                }}"
                formControlName="bcc"
            />
        </mat-form-field>
        <cb-merge-text-list
            [surveyId]="actionItem.surveyId"
            [pageId]="actionItem.page_id"
            [text]="editorText"
            (updateText)="onMergeText($event, 'bcc')"
        >
        </cb-merge-text-list>
    </div>

    <cb-question-collapsed-panel
        title="{{ 'SURVEY-EDITOR.SHARED.CONDITIONS' | translate }}"
        [tooltip]="'SURVEY-EDITOR.SHARED.TOOLTIPS.CONDITIONS' | translate"
    >
        <cb-groups-expressions-element
            [source]="actionItem"
        ></cb-groups-expressions-element>
    </cb-question-collapsed-panel>
</form>
