<form [formGroup]="form" autocomplete="off">
    <div class="form-section">
        <cb-question-item-title></cb-question-item-title>
        <div class="cb-change-type-section">
            <cb-item-type [itemType]="rating"></cb-item-type>
        </div>
        <cb-question-and-subtext
            [questionItem]="questionItem"
        ></cb-question-and-subtext>
    </div>
    <div class="form-section">
        <cb-multiple-options-item
            [surveyId]="questionItem.surveyId"
            [pageId]="questionItem.page_id"
            [options]="questionItem.choices"
            [expanded]="true"
            [questionType]="rating"
            [hideHtml]="true"
            [resetChoicesEvent] = "resetChoicesSubject.asObservable()"
        >
        </cb-multiple-options-item>
    </div>

    <cb-behavior-options [parentForm]="form" [softRequiredEnabled]="questionItem.softRequiredEnabled">
        <div class="form-fields-group">
            <mat-form-field class="cb-form-field">
                <mat-select
                    placeholder="{{
                        'SURVEY-EDITOR.ITEMS.RATING.RATING-TYPE' | translate
                    }}"
                    formControlName="rating_type"
                >
                    <mat-option value="Star">{{
                        'SURVEY-EDITOR.ITEMS.RATING.STAR' | translate
                    }}</mat-option>
                    <mat-option value="HorizontalBar">{{
                        'SURVEY-EDITOR.ITEMS.RATING.HORIZONTAL-BAR'
                            | translate
                    }}</mat-option>
                    <mat-option value="VerticalBar">{{
                        'SURVEY-EDITOR.ITEMS.RATING.VERTICAL-BAR'
                            | translate
                    }}</mat-option>
                    <mat-option value="Line">{{
                        'SURVEY-EDITOR.ITEMS.RATING.LINE' | translate
                    }}</mat-option>
                    <mat-option value="Square">{{
                        'SURVEY-EDITOR.ITEMS.RATING.BOX' | translate
                    }}</mat-option>
                    <mat-option value="Pill">{{
                        'SURVEY-EDITOR.ITEMS.RATING.PILL' | translate
                    }}</mat-option>
                    <mat-option value="ReversedBox">{{
                        'SURVEY-EDITOR.ITEMS.RATING.REVERSED-BOX'
                            | translate
                    }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field
                class="cb-form-field"
                *ngIf="ratingType === 'Star'"
            >
                <input
                    matInput
                    type="number"
                    autocomplete="off"
                    (keypress)="onNumericKeyUp($event)"
                    placeholder="{{
                        'SURVEY-EDITOR.ITEMS.RATING.START-SIZE' | translate
                    }}"
                    formControlName="option_size"
                />
            </mat-form-field>
        </div>
        <div class="form-fields-group">
            <mat-checkbox
                class="cb-form-field"
                formControlName="show_values"
                *ngIf="!isHideOption"
                >{{
                    'SURVEY-EDITOR.ITEMS.RATING.SHOW-OPTION-TEXT'
                        | translate
                }}
            </mat-checkbox>
            <mat-checkbox
                class="cb-form-field"
                formControlName="show_selected_value"
                *ngIf="ratingType !== 'Star'"
                >{{
                    'SURVEY-EDITOR.ITEMS.RATING.SHOW-SELECTED-VALUE'
                        | translate
                }}
            </mat-checkbox>
        </div>
    </cb-behavior-options>

    <div class="form-section border-top">
        <cb-question-collapsed-panel
            title="{{
                'SURVEY-EDITOR.SHARED.APPEARANCE.APPEARANCE' | translate
            }}"
        >
            <div class="form-group-position">
                <cb-label-position
                    class="position-control-group"
                    [selectedOption]="textPosition"
                    (selected)="labelPositionSelected($event)"
                ></cb-label-position>
                <cb-item-position
                    class="position-control-group"
                    [selectedOption]="itemPosition"
                    (selected)="itemPositionSelected($event)"
                ></cb-item-position>
            </div>
        </cb-question-collapsed-panel>
    </div>
    <div class="form-section">
        <cb-question-collapsed-panel
            title="{{ 'SURVEY-EDITOR.SHARED.CONDITIONS' | translate }}"
            [tooltip]="'SURVEY-EDITOR.SHARED.TOOLTIPS.CONDITIONS' | translate"
        >
            <cb-groups-expressions-element
                [source]="questionItem"
            ></cb-groups-expressions-element>
        </cb-question-collapsed-panel>
    </div>
    <cb-wcag-alert></cb-wcag-alert>
</form>
