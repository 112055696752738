<form [formGroup]="form" autocomplete="off">
    <div class="form-section">
        <cb-question-item-title></cb-question-item-title>
        <div class="cb-change-type-section">
            <cb-item-type [itemType]="ratingScale"></cb-item-type>
        </div>
        <cb-question-and-subtext
            [questionItem]="questionItem"
            [isMatrix]="isMatrix"
        ></cb-question-and-subtext>
    </div>
    <div class="form-section">
        <cb-multiple-options-item
            [surveyId]="questionItem.surveyId"
            [pageId]="questionItem.page_id"
            [options]="questionItem.choices | pureOptionsOnly"
            [expanded]="true"
            [questionType]="'RadioButtonScale'"
            [resetChoicesEvent] = "resetChoicesSubject.asObservable()"
            [isMatrix]="isMatrix"
            [isSurveyTemplate] = "isSurveyTemplate"
        ></cb-multiple-options-item>
    </div>
    <cb-behavior-options [parentForm]="form" [softRequiredEnabled]="questionItem.softRequiredEnabled">
        <div class="form-fields-group" *ngIf="showTextInuts">
            <mat-form-field class="cb-form-field">
                <input
                    matInput
                    autocomplete="off"
                    type="text"
                    formControlName="start_text"
                    placeholder="{{
                        'SURVEY-EDITOR.ITEMS.RATING_SCALE.SCALE-START-TEXT'
                            | translate
                    }}"
                />
            </mat-form-field>
            <mat-form-field class="cb-form-field">
                <input
                    matInput
                    autocomplete="off"
                    type="text"
                    formControlName="mid_text"
                    placeholder="{{
                        'SURVEY-EDITOR.ITEMS.RATING_SCALE.SCALE-MID-TEXT'
                            | translate
                    }}"
                />
            </mat-form-field>
            <mat-form-field class="cb-form-field">
                <input
                    matInput
                    autocomplete="off"
                    type="text"
                    formControlName="end_text"
                    placeholder="{{
                        'SURVEY-EDITOR.ITEMS.RATING_SCALE.SCALE-END-TEXT'
                            | translate
                    }}"
                />
            </mat-form-field>
        </div>
        <div class="form-fields-group">
            <div class="form-fields-group">
                <mat-checkbox
                    class="cb-form-field"
                    formControlName="enable_not_applicable"
                    #allow_none
                    >{{ 'SURVEY-EDITOR.SHARED.NOT-APPLICABLE' | translate }}
                </mat-checkbox>
                <div class="cb-form-field" *ngIf="allow_none.checked">
                    <mat-form-field>
                        <input
                            matInput
                            type="text"
                            autocomplete="off"
                            formControlName="not_applicable_text"
                            placeholder="{{
                                'SURVEY-EDITOR.SHARED.NOT-APPLICABLE-TEXT'
                                    | translate
                            }}"
                        />
                    </mat-form-field>
                </div>
            </div>
        </div>
    </cb-behavior-options>

    <div class="form-section border-top">
        <cb-question-collapsed-panel
            title="{{
                'SURVEY-EDITOR.SHARED.APPEARANCE.APPEARANCE' | translate
            }}"
        >
            <div class="form-fields-group">
                <mat-form-field class="cb-form-field" *ngIf="!isMatrix">
                    <mat-select
                        placeholder="{{
                            'SURVEY-EDITOR.SHARED.LAYOUT' | translate
                        }}"
                        formControlName="layout"
                    >
                        <mat-option value="Vertical"
                            >{{
                                'SURVEY-EDITOR.SHARED.APPEARANCE.VERTICAL'
                                    | translate
                            }}
                        </mat-option>
                        <mat-option value="Horizontal">
                            {{
                                'SURVEY-EDITOR.SHARED.APPEARANCE.HORIZONTAL'
                                    | translate
                            }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="cb-form-field">
                    <mat-select
                        placeholder="{{
                            'SURVEY-EDITOR.ITEMS.RATING_SCALE.LABEL-TYPE' | translate
                        }}"
                        formControlName="choice_label_type"
                    >
                        <mat-option value="Rating"
                            >{{
                                'SURVEY-EDITOR.ITEMS.RATING_SCALE.RATING'
                                    | translate
                            }}
                        </mat-option>
                        <mat-option value="Text"
                            >{{
                                'SURVEY-EDITOR.ITEMS.RATING_SCALE.TEXT'
                                    | translate
                            }}
                        </mat-option>
                        <mat-option value="TextAndRating">
                            {{
                                'SURVEY-EDITOR.ITEMS.RATING_SCALE.TEXT-AND-RATING'
                                    | translate
                            }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="form-group-position" *ngIf="!isMatrix">
                <cb-label-position
                    class="position-control-group"
                    [selectedOption]="textPosition"
                    (selected)="labelPositionSelected($event)"
                ></cb-label-position>
                <cb-item-position
                    class="position-control-group"
                    [selectedOption]="itemPosition"
                    (selected)="itemPositionSelected($event)"
                ></cb-item-position>
            </div>
            <div class="form-fields-group">
                <mat-form-field class="cb-form-field">
                    <mat-select
                        placeholder="{{
                            'SURVEY-EDITOR.SHARED.APPEARANCE.SEPARATOR'
                                | translate
                        }}"
                        formControlName="show_separator"
                    >
                        <mat-option [value]="true">{{
                            'SHARED.YES' | translate
                        }}</mat-option>
                        <mat-option [value]="false">{{
                            'SHARED.NO' | translate
                        }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="cb-form-field" *ngIf="!isMatrix">
                    <input
                        matInput
                        type="number"
                        autocomplete="off"
                        formControlName="option_width"
                        (keypress)="onNumericKeyUp($event)"
                        placeholder="{{
                            'SURVEY-EDITOR.SHARED.APPEARANCE.WIDTH' | translate
                        }}"
                    />
                </mat-form-field>
            </div>
        </cb-question-collapsed-panel>
    </div>
    <div class="form-section" *ngIf="!isMatrix">
        <cb-question-collapsed-panel
            title="{{ 'SURVEY-EDITOR.SHARED.CONDITIONS' | translate }}"
            [tooltip]="'SURVEY-EDITOR.SHARED.TOOLTIPS.CONDITIONS' | translate"
        >
            <cb-groups-expressions-element
                [source]="questionItem"
            ></cb-groups-expressions-element>
        </cb-question-collapsed-panel>
    </div>
</form>
