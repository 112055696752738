<ng-container *ngIf="survey$ | async; let survey">
  <div class="detail-main-block">
    <div class="info-block">
      <div>
        <h3>
          {{ survey.name }}
        </h3>
        <mat-icon *ngIf="!survey.is_favorite" [matTooltip]="'SURVEYS-LIST.ADD-TO-FAVORITES' | translate" mat-ripple
          class="default" svgIcon="fi_star" (click)="toggleFavorite()"></mat-icon>
        <mat-icon *ngIf="survey.is_favorite" [matTooltip]="'SURVEYS-LIST.REMOVE-FROM-FAVORITES' | translate" mat-ripple
          class="favorite" svgIcon="fi_star_fill" (click)="toggleFavorite()"></mat-icon>
      </div>
      <cb-survey-owner-show [user]="survey.created_by" [showLabel]="true"></cb-survey-owner-show>
    </div>
    <div class="menu-block">
      <button [ngClass]="{
        'status-selector':true, 'menu-opened':isMenuOpened, 'menu-closed':!isMenuOpened
      }" mat-stroked-button [matMenuTriggerFor]="statusMenu" (menuClosed)="appMenuClose()"
        (menuOpened)="appMenuOpen()">
        <div>
          <span><cb-survey-item-status [status]="survey.status"></cb-survey-item-status></span>
          <div class="caret-icon">
            <mat-icon class="icon_down" svgIcon="fi_chevron_down"></mat-icon>
          </div>
        </div>
      </button>
      <cb-button type="stroked" icon="fi_more_vertical" [matMenuTriggerFor]="surveyMenu"></cb-button>




      <mat-menu #statusMenu="matMenu" [overlapTrigger]="false" xPosition="before">
        <span class="status-item" *ngFor="let item of surveyStatus" mat-menu-item (click)="changeStatus(item.value, survey)">
          <cb-survey-item-status [status]="item.value"></cb-survey-item-status>
        </span>
      </mat-menu>
      <mat-menu #surveyMenu="matMenu" [overlapTrigger]="false" xPosition="before">
        <button  mat-menu-item (click)="toggleLock()" *ngIf="hasRole(
            userRoles.SYSTEM_ADMINISTRATOR,
            userRoles.SURVEY_ADMINISTRATOR
        )">
          <mat-icon [svgIcon]="survey.allow_edit_while_active ? 'fi_lock' : 'fi_unlock'"></mat-icon>
          <span>
            {{(survey.allow_edit_while_active ? 'SURVEY-SETTINGS.MENU-ITEMS.LOCK' :
            'SURVEY-SETTINGS.MENU-ITEMS.UNLOCK')|translate}}
          </span>
        </button>
        <a mat-menu-item  [routerLink]="['surveys', survey.id,'print']" [target]="'_blank'" routerLinkActive="active">
          <mat-icon svgIcon="fi_printer"></mat-icon>
          <span>{{'SURVEY-SETTINGS.MENU-ITEMS.PRINT'|translate}}</span>
        </a>
        <button *ngIf="hasRole(
              userRoles.SYSTEM_ADMINISTRATOR,
              userRoles.SURVEY_ADMINISTRATOR
          )" mat-menu-item (click)="duplicateSurvey(survey)">
          <mat-icon svgIcon="fi_copy"></mat-icon>
          <span>{{'SURVEY-SETTINGS.MENU-ITEMS.DUPLICATE'|translate}}</span>
        </button>
        <button mat-menu-item (click)="exportJson(survey)">
          <mat-icon svgIcon="fi_json"></mat-icon>
          <span>{{'SURVEY-SETTINGS.MENU-ITEMS.EXPORT-JSON'|translate}}</span>
        </button>
        <button mat-menu-item (click)="saveAsTemplate(survey)" *ngIf="hasRole(
          userRoles.SYSTEM_ADMINISTRATOR,
          userRoles.SURVEY_ADMINISTRATOR
        )">
          <mat-icon svgIcon="fi_file"></mat-icon>
          <span>{{'SURVEY-SETTINGS.MENU-ITEMS.SAVE-AS-TEMPLATE'|translate}}</span>
        </button>
        <button mat-menu-item (click)="deleteSurvey(survey)" *ngIf="hasRole(
          userRoles.SYSTEM_ADMINISTRATOR,
          userRoles.SURVEY_ADMINISTRATOR
        )">
          <mat-icon svgIcon="fi_trash"></mat-icon>
          <span>{{'SURVEY-SETTINGS.MENU-ITEMS.DELETE'|translate}}</span>
        </button>
      </mat-menu>
    </div>
  </div>
</ng-container>