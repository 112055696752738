import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { SurveyQuestionType } from '../../../../../infrastructure/consts/surveys.consts';
import { SharedService } from '../../../../../infrastructure/services';
import { AutocompleteListProvider } from '../../../../providers';
import { MultilinesItem } from '../../../../../shared/models/survey-items/question-items/multilinesItem';
import { SurveyEditorValidator } from '../../../../../infrastructure/validators/survey-editor.validators';
import { AutoUnsubscribe } from '../../../../../shared/decorators/autoUnsubscribe.decorator';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'cb-multilines-item',
    templateUrl: './multilines-item.component.html',
    styleUrls: ['./multilines-item.component.scss']
})
@AutoUnsubscribe()
export class MultilinesItemComponent implements OnInit, OnDestroy {
    @Input() questionItem: MultilinesItem;

    multiLineText = SurveyQuestionType.MULTI_LINE_TEXT;
    form: FormGroup;
    autocompleteLists: any[];
    list: string;
    itemPosition: string;
    textPosition: string;
    allow_html: boolean;
    private componentDestroyed = new Subject();

    constructor(
        private fb: FormBuilder,
        private sharedService: SharedService,
        private autocompleteListProvider: AutocompleteListProvider
    ) {}

    ngOnInit() {
        this.autocompleteListProvider.getLists().subscribe(c => {
            this.autocompleteLists = c;
        });

        this.createFormGroup();
        this.createQuestionItemObject();
    }

    onAutocompleteChange(alias) {
        const item = this.autocompleteLists.find(c => c.alias === alias);

        if (!item) {
            return;
        }

        const url = item.url;
        this.autocompleteListProvider.getListById(url).subscribe(list => {
            this.list = '';
            for (let i = 0; i < list.length; i++) {
                this.list += list[i].name + '\n';
            }
        });
    }

    itemPositionSelected(value): void {
        this.itemPosition = value;
        this.form.controls['item_position'].setValue(value);
    }

    labelPositionSelected(value): void {
        this.textPosition = value;
        this.form.controls['question_text_position'].setValue(value);
    }

    private createFormGroup(): void {
        let isAllQuestionsRequired = false;
        if (!this.questionItem) {
            this.questionItem = <MultilinesItem>{};
        }
        if (!this.questionItem.id) {
            isAllQuestionsRequired = this.sharedService.surveyRespondentSettings.getValue() ?
            this.sharedService.surveyRespondentSettings.getValue().makeQuestionsRequired : false;
        }
        this.form = this.fb.group({
            alias: new FormControl(this.questionItem.alias),
            is_required: new FormControl(this.questionItem.is_required || isAllQuestionsRequired),
            is_soft_required: new FormControl(this.questionItem.softRequiredEnabled ? !!this.questionItem.is_soft_required : false),
            enable_sentiment_analysis: new FormControl(this.questionItem.enable_sentiment_analysis),
            default_text: new FormControl(this.questionItem.default_text),
            item_position: new FormControl(this.questionItem.item_position),
            question_text_position: new FormControl(
                this.questionItem.question_text_position
            ),
            html_class: new FormControl(this.questionItem.html_class),
            max_length: new FormControl(this.questionItem.max_length),
            min_length: new FormControl(this.questionItem.min_length),
            rows: new FormControl(this.questionItem.rows),
            cols: new FormControl(this.questionItem.cols),
            allow_html: new FormControl(!!this.questionItem.allow_html)
        });
        this.allow_html = this.questionItem.allow_html;
        this.itemPosition = this.questionItem.item_position;
        this.textPosition = this.questionItem.question_text_position;
    }

    private createQuestionItemObject(): void {
        this.updateQuestionItemObject();
        this.form.valueChanges
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(s => {
                this.updateQuestionItemObject();
            });
    }

    private updateQuestionItemObject(): void {
        const control = this.form.controls;
        const questionItem = new MultilinesItem();
        questionItem.item_type = SurveyQuestionType.MULTI_LINE_TEXT;
        questionItem.max_length = control['max_length'].value ? control['max_length'].value : null;
        questionItem.min_length = control['min_length'].value ? control['min_length'].value : null;
        questionItem.is_required = control['is_required'].value;
        questionItem.is_soft_required = control['is_soft_required'].value;
        questionItem.alias = control['alias'].value;
        questionItem.default_text = control['default_text'].value;
        questionItem.html_class = control['html_class'].value;
        questionItem.question_text_position =
            control['question_text_position'].value;
        questionItem.item_position = control['item_position'].value;
        questionItem.rows = control['rows'].value;
        questionItem.cols = control['cols'].value;
        questionItem.allow_html = control['allow_html'].value;
        questionItem.enable_sentiment_analysis = control['enable_sentiment_analysis'].value;

        this.sharedService.share('questionItemSettings', questionItem);
        this.allow_html = questionItem.allow_html;
    }

    public onNumericKeyUp(event: any) {
        return SurveyEditorValidator.validateNumericInput(event);
    }

    ngOnDestroy() {}
}
