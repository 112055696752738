import { createSelector } from 'reselect';

import { AppState } from '../reducers';
import * as users from './users.selectors';
import * as surveys from './surveys.selectors';

/* -- root selectors -- */
const getUsersState = (state: AppState) => state.users;
const getSurveysState = (state: AppState) => state.surveys;

/*--- users selectors -----*/
export const getUsersLoadingStatus = createSelector(
    getUsersState,
    users.getIsLoading
);
export const getSelectedUserInUsersList = createSelector(
    getUsersState,
    users.getSelectedUser
);
export const getAddedUserInUsersList = createSelector(
    getUsersState,
    users.getAddedUser
);
export const getUsersList = createSelector(
    getUsersState,
    users.getUsers
);

/*--- surveys selectors -----*/
export const getSurveysLoadingStatus = createSelector(
    getSurveysState,
    surveys.getIsLoading
);
export const getSurveyList = createSelector(
    getSurveysState,
    surveys.getSurveyList
);
export const getFavoriteSurveys = createSelector(
    getSurveysState,
    surveys.getFavoriteSurveys
);
export const getSelectedSurveyIds = createSelector(
    getSurveysState,
    surveys.getSelectedSurveyIds
);
export const getParameters = createSelector(
    getSurveysState,
    surveys.getParameters
);
export const getFavoriteParameters = createSelector(
    getSurveysState,
    surveys.getFavoriteParameters
);




