import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    OnDestroy
} from '@angular/core';
import { String } from 'typescript-string-operations-ng4';
import { Survey } from '../../../models';
import {
    AclProvider,
    DashboardsProvider,
    LimitsProvider,
    SurveysProvider
} from '../../../providers';
import {
    SharedService,
    ToasterService
} from '../../../../infrastructure/services';
import { StorageProvider } from '../../../../infrastructure/providers';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { getShortenedString } from '../../../../infrastructure/helpers/string.helpers';
import { AddSurveyDialogComponent } from '../dialog-windows/add-survey-dialog/add-survey-dialog.component';
import {
    SurveysPagesActions,
    SurveyStatus
} from '../../../../infrastructure/consts/surveys.consts';
import { CopySurveyDialogComponent } from '../dialog-windows/copy-survey-dialog/copy-survey-dialog.component';
import { SaveAsTEmplateDialogComponent } from '../save-as-template-dialog/save-as-template-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { of, Subject } from 'rxjs';
import { LanguageService } from '../../../../infrastructure/services';
import { LanguageItemModel } from '../../../../infrastructure/models/survey-settings.model';
import { first, map, flatMap, takeUntil } from 'rxjs/operators';
import { UserRoles } from '../../../../infrastructure/consts/auth.consts';
import * as _ from 'lodash';
import { DialogsService } from '../../../../infrastructure/services/dialogs.service';
import { generateUniversalLink } from '../../../../infrastructure/helpers/link-generator.helper';
import { AutoUnsubscribe } from '../../../../shared/decorators/autoUnsubscribe.decorator';

@Component({
    selector: 'cb-survey-toolbar',
    templateUrl: './survey-toolbar.component.html',
    styleUrls: ['./survey-toolbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class SurveyToolbarComponent implements OnInit, OnChanges, OnDestroy {
    @Input() survey: Survey;
    isFavoriteSurvey: boolean;
    deleteConfirmResult: any;
    editSurveyDialogRef: MatDialogRef<AddSurveyDialogComponent>;
    nameEditMode: boolean;
    surveyName: string;
    lockStatusIcon: string;
    surveyStatus = this.getStatusesArray();
    allowMultiLanguage: boolean;
    showReportTab: boolean;
    isLoading: boolean;
    supportedLanguages: LanguageItemModel[];
    dialogOptionsTall = { width: '1024px' };
    administerTemplate: boolean;
    isBlockLoading: boolean;
    currentUserRoles: string[];
    isExportingToPdf: boolean;
    isExportingToJSON: boolean;
    translates = {
        archivedMessage: 'SURVEYS-LIST.ARCHIVED-MESSAGE',
        exportJSON: 'SHARED.EXPORT_JSON_MESSAGE',
        deleteTitle: 'SURVEY-SETTINGS.NOTIFICATIONS.DELETE-TITLE',
        deleteSubTitle: 'SURVEY-SETTINGS.NOTIFICATIONS.DELETE-SUBTITLE',
        deleteSuccessful: 'SURVEY-SETTINGS.NOTIFICATIONS.DELETE-SUCCESSFUL',
        surveyRenamed: 'SURVEY-SETTINGS.NOTIFICATIONS.SURVEY-RENANED',
        surveyLocked: 'SURVEY-SETTINGS.NOTIFICATIONS.SURVEY-LOCKED'
    };
    private componentDestroyed = new Subject();

    constructor(
        private router: Router,
        private dialogsService: DialogsService,
        private surveysProvider: SurveysProvider,
        private sharedService: SharedService,
        private dialog: MatDialog,
        private limitProvider: LimitsProvider,
        private dashboardsProvider: DashboardsProvider,
        private toasterService: ToasterService,
        private translateService: TranslateService,
        private changeDetectorRef: ChangeDetectorRef,
        private languageService: LanguageService,
        private storageProvider: StorageProvider,
        private aclProvider: AclProvider
    ) { }

    ngOnInit() {
        this.nameEditMode = false;
        if (this.survey) {
            this.surveyName = this.survey.name;
        }

        this.initFavoriteInfo();
        this.getLanguageData();
        this.initUserInfo().pipe(first()).subscribe();
        this.getMenuItemsRestrictions();
    }

    getLanguageData() {
        this.languageService.surveyLanguages
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(
                (data) => {
                    this.supportedLanguages = data;
                }
            );

        this.translateService
            .get(Object.values(this.translates))
            .subscribe((data) => {
                for (const key in this.translates) {
                    if (this.translates.hasOwnProperty(key)) {
                        this.translates[key] = data[this.translates[key]];
                    }
                }
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.survey) {
            this.lockStatusIcon = this.getLockClass(
                changes.survey.currentValue.allow_edit_while_active
            );
        }
    }

    getMenuItemsRestrictions() {
        this.isLoading = true;
        this.getReportsPermission();
        this.getMultiLanguageLimit()
            .pipe(first())
            .subscribe(
                (multiLanguageLimitData) => {
                    this.allowMultiLanguage =
                        multiLanguageLimitData.status === 'LimitNotReached';
                    this.isLoading = false;
                    this.changeDetectorRef.detectChanges();
                },
                (err) => this.errorHandler(err)
            );
    }

    private getMultiLanguageLimit() {
        return this.limitProvider.getLimitByName('MultiLanguageLimit');
    }

    getReportsPermission() {
        this.aclProvider.getCreateReportForSurveyPermission(this.survey.id)
            .pipe(first())
            .subscribe(
                accessible => {
                    this.showReportTab = accessible;
                    if (accessible) {
                        this.changeDetectorRef.detectChanges();
                    }
                },
                err => this.errorHandler(err)
            );
    }

    private getLockClass(isAllowed: boolean): string {
        return isAllowed ? 'lock_open' : 'lock';
    }

    private errorHandler(error) {
        this.isLoading = false;
        this.isExportingToPdf = false;
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    }

    private getStatusesArray() {
        const keys = Object.keys(SurveyStatus);
        return keys.map((key) => {
            return { key, value: SurveyStatus[key] };
        });
    }

    deleteItem() {
        this.dialogsService
            .confirm(
                this.translates.deleteTitle,
                String.Format(this.translates.deleteSubTitle, this.survey.name),
                this.survey.name
            )
            .subscribe((res) => {
                this.deleteConfirmResult = res;
                this.onConfirmationSelect();
            });
    }

    onConfirmationSelect() {
        if (this.deleteConfirmResult) {
            this.surveysProvider
                .deleteSurvey(this.survey.id)
                .pipe(first())
                .subscribe((data) => {
                    this.toasterService.showInfo(
                        String.Format(
                            this.translates.deleteSuccessful,
                            this.survey.name
                        )
                    );
                    this.router.navigate(['surveys']);
                });
        }
    }

    copySurvey() {
        const copySurveyDialogRef = this.dialog.open(
            CopySurveyDialogComponent,
            {
                width: '500px',
                data: {
                    survey: this.survey
                }
            }
        );

        copySurveyDialogRef.afterClosed().subscribe((survey) => {
            if (survey) {
                window.open(
                    generateUniversalLink(`surveys/${survey.id}/editor`),
                    '_blank'
                );
            }
        });
    }

    editSurvey() {
        this.editSurveyDialogRef = this.dialog.open(AddSurveyDialogComponent, {
            width: '500px',
            data: {
                survey: this.survey
            }
        });

        this.editSurveyDialogRef.afterClosed().subscribe((data) => {
            if (data) {
                this.survey.name = this.getSurveyName(data.name, 40);
            }
        });
    }

    getSurveyName(inputString, length) {
        if (!this.survey) {
            return '';
        }
        return getShortenedString(inputString, length);
    }

    onEditNameClick() {
        this.surveyName = this.survey.name;
        this.nameEditMode = true;
    }

    cancelNameChanges() {
        this.surveyName = this.survey.name;
        this.nameEditMode = false;
    }

    saveNameChanges() {
        if (this.surveyName !== this.survey.name && this.surveyName.trim()) {
            this.survey.name = this.surveyName;
            this.surveysProvider
                .updateSurvey(this.survey.id, this.survey)
                .pipe(first())
                .subscribe(
                    () => {
                        this.renameQuickReport();
                        setTimeout(() => {
                            this.toasterService.showInfo(
                                this.translates.surveyRenamed
                            );
                        }, 500);
                    },
                    (err) => this.errorHandler(err)
                );
        } else {
            this.surveyName = this.survey.name;
        }
        this.nameEditMode = false;
    }

    renameQuickReport() {
        return this.dashboardsProvider
            .getQuickDashboardBySurveyId(this.survey.id)
            .pipe(
                first(),
                flatMap((id) =>
                    _.isNull(id)
                        ? of(null)
                        : this.dashboardsProvider.loadDashboardById(id)
                ),
                flatMap((report) =>
                    _.isNull(report)
                        ? of(null)
                        : this.dashboardsProvider.updateDashboardById(
                            report.id,
                            { ...report, ...{ name: this.survey.name } }
                        )
                )
            )
            .subscribe();
    }

    exportPDF(lang) {
        if (!this.isExportingToPdf) {
            this.isExportingToPdf = true;
            this.surveysProvider.generatePDF(this.survey.id, lang).subscribe(
                () => {
                    this.isExportingToPdf = false;
                    if (!this.changeDetectorRef['destroyed']) {
                        this.changeDetectorRef.detectChanges();
                    }
                },
                (err) => this.showExportFailedMessage()
            );
        }
    }

    showExportFailedMessage() {
        this.translateService.get('SHARED.EXPORT_PDF_FAIL').subscribe((data: string) => {
            return this.errorHandler({ message: data });
        });
    }

    exportJSON(lang) {
        this.isExportingToJSON = true;
        this.surveysProvider.exportSurveyJSON(this.survey.id, lang).subscribe(
            () => {
                this.isExportingToJSON = false;
            },
            (err) => this.errorHandler(err)
        );
        this.toasterService.showInfo(this.translates.exportJSON);
    }


    public get userRoles() {
        return UserRoles;
    }

    initUserInfo() {
        return this.storageProvider.getIdentity().pipe(
            map((_identity) => {
                const identity = JSON.parse(_identity);
                this.currentUserRoles = _.chain(identity)
                    .get('roles')
                    .split(',')
                    .map((role) => _.trim(role))
                    .value();
                this.changeDetectorRef.detectChanges();
            })
        );
    }

    hasRole(...roles: string[]): boolean {
        if (this.currentUserRoles && this.currentUserRoles.length) {
            const foundRole = _.find(this.currentUserRoles, (r) => {
                return roles.includes(r);
            });
            if (foundRole) {
                return true;
            }
        }
        return false;
    }

    checkTranslate(item, translate) {
        return translate === 'LANGUAGES.' + item.value
            ? item.viewValue
            : translate;
    }

    checkOnLockedSurvey(callback, ...arg) {
        if (
            !this.survey.allow_edit_while_active &&
            this.survey['status'] === 'Published'
        ) {
            this.toasterService.showWarning(this.translates.surveyLocked);
        } else {
            callback.apply(this, arg);
        }
    }

    private initFavoriteInfo() {
        this.isFavoriteSurvey = false;
        this.surveysProvider.isFavorite(this.survey.id)
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(isFavorite => {
                this.isFavoriteSurvey = isFavorite;
                this.changeDetectorRef.detectChanges();

        });
    }

    addToFavorites() {
        if (
            this.survey.status.toUpperCase() !==
            SurveyStatus.ARCHIVED.toUpperCase()
        ) {
            this.surveysProvider.setFavorite(this.survey.id)
                .pipe(takeUntil(this.componentDestroyed))
                .subscribe(c => {
                    this.isFavoriteSurvey = true;
                    this.changeDetectorRef.detectChanges();
            });
        }
    }

    removeFromFavorites() {
        this.surveysProvider.removeFavorite(this.survey.id)
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(c => {
                this.isFavoriteSurvey = false;
                this.changeDetectorRef.detectChanges();
        });
    }

    ngOnDestroy() {}
}

