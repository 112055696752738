import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    Inject,
    OnDestroy
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FoldersProvider } from '../../../../providers';
import { AllSurveysListComponent } from '../../surveys-list/all-surveys-list/all-surveys-list.component';
import { AutoUnsubscribe } from '../../../../../shared/decorators/autoUnsubscribe.decorator';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'cb-add-folder-dialog',
    templateUrl: './add-folder-dialog.component.html',
    styleUrls: ['../add-dialog.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class AddFolderDialogComponent implements OnInit, OnDestroy {
    form: FormGroup;
    folderName: string;
    folderId: number;
    editMode: boolean;
    private componentDestroyed = new Subject();

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private provider: FoldersProvider,
        private dialogRef: MatDialogRef<AllSurveysListComponent>,
        @Inject(MAT_DIALOG_DATA) private data
    ) {}

    ngOnInit() {
        if (this.data && this.data.folder) {
            this.folderName = this.data.folder.name;
            this.folderId = this.data.folder.id;
            this.editMode = true;
        }
        this.form = this.fb.group({
            name: [this.folderName, Validators.required],
            id: [this.folderId]
        });
    }

    onSubmit() {
        const data = this.form.value;
        data.description = '';

        if (this.form.valid) {
            if (this.editMode && this.folderName !== data.name) {
                this.provider.updateFolder(data)
                    .pipe(
                        takeUntil(this.componentDestroyed)
                    )
                    .subscribe(s => {
                        this.dialogRef.close({ renamed: true, name: data.name });
                    });
            } else {
                this.provider.addFolder(data)
                    .pipe(
                        takeUntil(this.componentDestroyed)
                    )
                    .subscribe(s => {
                        this.dialogRef.close({ created: true, name: data.name });
                    });
            }
        }
    }

    disableSubmitButton() {
        return (
            !this.form.valid ||
            (this.editMode && this.folderName === this.form.value.name) ||
            this.form.value.name.trim() === ''
        );
    }
    public cancel() {
        this.dialogRef.close();
    }
    ngOnDestroy() {}
}
