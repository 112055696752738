<mat-expansion-panel class="cb-expansion-panel cb-form-expansion-panel">
    <mat-expansion-panel-header
        [collapsedHeight]="'48px'"
        [expandedHeight]="'48px'"
    >
        <mat-panel-title class="d-flex align-items-center">
            <span class="icon-collapse-wrap">
                <cb-svg-icon
                    class="icon icon-less"
                    [name]="'collapse-less'"
                ></cb-svg-icon>
                <cb-svg-icon
                    class="icon icon-more"
                    [name]="'collapse-more'"
                ></cb-svg-icon>
            </span>
            <h3 class="form-section-title">
                {{ 'SURVEY-EDITOR.SHARED.APPEARANCE.COLUMNS' | translate }}
            </h3>
        </mat-panel-title>
    </mat-expansion-panel-header>

    <div>
        <div
            class="matrix-col-row-fields-group header-fields-group d-flex align-items-center"
            *ngIf="displayColumns && displayColumns.length >= 2"
        >
            <div class="id-cell">#</div>
            <div class="cell type-cell">
                {{ 'SURVEY-EDITOR.SHARED.CHOICE.TYPE' | translate }}
            </div>
            <div class="cell text-cell">
                {{ 'SURVEY-EDITOR.SHARED.CHOICE.TEXT' | translate }}
            </div>
            <div class="cell alias-cell">
                {{ 'SURVEY-EDITOR.SHARED.CHOICE.ALIAS' | translate }}
            </div>
            <div class="cell actions-cell"></div>
        </div>
        <div cdkDropList [cdkDropListData]="displayColumns" (cdkDropListDropped)="onDrop($event)">
            <div
                cdkDrag
                *ngFor="let column of displayColumns; let i = index"
            >
                <div
                    class="draggable-item shadowed-section matrix-item"
                    *ngIf="column.column_type === defaultQuestionType"
                >
                    <span class="icon-move">
                        <cb-svg-icon
                            class="icon"
                            [name]="'move-page'"
                        ></cb-svg-icon>
                    </span>
                    <div
                        class="matrix-col-row-fields-group form-fields-group d-flex align-items-center"
                    >
                        <div class="cell id-cell">{{ i + 1 }}</div>
                        <div class="cell type-cell">
                            {{
                                'SURVEY-EDITOR.ITEMS.MATRIX.ROW-TEXTS'
                                    | translate
                            }}
                        </div>
                    </div>
                </div>
                <div
                    class="draggable-item shadowed-section matrix-item"
                    *ngIf="
                        column.prototype_item &&
                        column.column_type !== defaultQuestionType
                    "
                >
                    <span class="icon-move">
                        <cb-svg-icon
                            class="icon"
                            [name]="'move-page'"
                        ></cb-svg-icon>
                    </span>
                    <div
                        class="matrix-col-row-fields-group form-fields-group d-flex align-items-center"
                    >
                        <div class="cell id-cell">{{ i + 1 }}</div>
                        <div class="cell type-cell">
                            <cb-item-type
                                class="item-type"
                                [itemType]="column.prototype_item.item_type"
                            ></cb-item-type>
                        </div>
                        <div
                            class="cell text-cell"
                            [attr.innerHTML]="
                                column.prototype_item.question_text
                                    ? (column.prototype_item.question_text | keepHtml)
                                    : null
                            "
                        >
                            {{ 'SURVEY-EDITOR.SHARED.CHOICE.TEXT' | translate }}
                        </div>
                        <div
                            class="cell alias-cell"
                            [attr.innerHTML]="
                                column.prototype_item.alias
                                    ? (column.prototype_item.alias | keepHtml)
                                    : null
                            "
                        >
                            {{
                                'SURVEY-EDITOR.SHARED.CHOICE.ALIAS' | translate
                            }}
                        </div>
                        <div class="cell actions-cell">
                            <button
                                mat-icon-button
                                (click)="onEditColumnClick(i)"
                            >
                                <cb-svg-icon
                                    class="icon"
                                    [name]="'edit-default'"
                                ></cb-svg-icon>
                            </button>
                            <button
                                mat-icon-button
                                (click)="onRemoveColumnClick(i)"
                            >
                                <cb-svg-icon
                                    class="icon"
                                    [name]="'close'"
                                ></cb-svg-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <cb-inline-dialog
        [isAddingItemProcess]="matrixItemProcess"
        [component]="matrixItemComponent"
        [options]="matrixItemOptions"
        [page]="matrixItemPage"
        [item]="item"
    ></cb-inline-dialog>
    <div>
        <button
            mat-button
            class="cb-button-default button-add-column-matrix"
            [matMenuTriggerFor]="columnMenu"
        >
            <cb-svg-icon class="icon" [name]="'add-column'"></cb-svg-icon>
            {{ 'SURVEY-EDITOR.ITEMS.MATRIX.ADD-COLUMN' | translate }}
        </button>
    </div>
    <mat-menu
        #columnMenu="matMenu"
        [overlapTrigger]="false"
        class="action-button-menu-panel"
        xPosition="after"
    >
        <div class="menu-inner-content">
            <div class="menu-group-list" *ngFor="let types of matrixColTypes">
                <button
                    *ngFor="let type of types"
                    mat-button
                    (click)="onAddColumn(type)"
                >
                    <cb-item-type
                        [itemType]="type"
                        [isMatrix]="true"
                        [showTooltip]="true"
                    ></cb-item-type>
                </button>
            </div>
        </div>
    </mat-menu>
</mat-expansion-panel>
