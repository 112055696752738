<mat-expansion-panel class="cb-expansion-panel cb-form-expansion-panel">
    <mat-expansion-panel-header
        [collapsedHeight]="'48px'"
        [expandedHeight]="'48px'"
    >
        <mat-panel-title class="d-flex align-items-center">
            <span class="icon-collapse-wrap">
                <cb-svg-icon
                    class="icon icon-less"
                    [name]="'collapse-less'"
                ></cb-svg-icon>
                <cb-svg-icon
                    class="icon icon-more"
                    [name]="'collapse-more'"
                ></cb-svg-icon>
            </span>
            <h3 class="form-section-title">
                {{ 'SURVEY-EDITOR.ITEMS.CONTACT-FORM.FORM-FIELDS' | translate }}
            </h3>
        </mat-panel-title>
    </mat-expansion-panel-header>

    <div>
        <div class="contact-form-fields-group header-fields-group d-flex align-items-center" #formFieldList>
            <div class="cell id-cell">#</div>
            <div class="cell label-cell">
                {{ 'SURVEY-EDITOR.ITEMS.CONTACT-FORM.FIELD-LABEL' | translate }}
            </div>
            <div class="cell answer-format-cell">
                {{ 'SURVEY-EDITOR.ITEMS.CONTACT-FORM.FIELD-VALIDATION' | translate }}
            </div>
            <div class="cell actions-cell">&nbsp;</div>
        </div>
        <div cdkDropList [cdkDropListData]="fields" (cdkDropListDropped)="onDrop($event)">
            <div
                cdkDrag
                *ngFor="let formField of fields; let i = index"
            >
                <div class="draggable-item shadowed-section contact-form-field">
                    <span class="icon-move">
                        <cb-svg-icon
                            class="icon"
                            [name]="'move-page'"
                        ></cb-svg-icon>
                    </span>
                    <div class="contact-form-fields-group form-fields-group d-flex align-items-center">
                        <div class="cell id-cell">{{ i + 1 }}</div>
                        <div
                            class="cell label-cell"
                            [innerHTML]="formField.question.question_text | keepHtml"
                        >
                        </div>
                        <div class="cell answer-format-cell">
                            {{
                                'SURVEY-EDITOR.ITEMS.SINGLELINES.'+getAnswerFormatTextKey(formField) | translate
                            }}
                        </div>
                        <div class="cell actions-cell">
                            <button
                                mat-icon-button
                                (click)="onEditFormFieldClick(i)"
                            >
                                <cb-svg-icon
                                    class="icon"
                                    [name]="'edit-default'"
                                ></cb-svg-icon>
                            </button>
                            <button
                                mat-icon-button
                                (click)="onToggleFormFieldVisibility(i)"
                            >
                                <i class="material-icons icon"
                                    matTooltip="{{ 'SHARED.HIDE' | translate }}"
                                    *ngIf="formField.enabled">
                                        visibility
                                </i>
                                <i class="material-icons icon"
                                    matTooltip="{{ 'SHARED.SHOW' | translate }}"
                                    *ngIf="!formField.enabled">
                                        visibility_off
                                </i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <cb-inline-dialog
        [isAddingItemProcess]="isEditFormFieldProcess"
        [component]="fieldEditorComponent"
        [options]="editFieldOptions"
        [page]="contactFormItemPage"
        [item]="formFieldQuestion"
    ></cb-inline-dialog>
</mat-expansion-panel>
