<cb-singlelines-item-preview
    *ngIf="item.item_type === questionType.SINGLE_LINE_TEXT"
    [questionItem]="item"
></cb-singlelines-item-preview>
<cb-checkboxes-item-preview
    *ngIf="item.item_type === questionType.CHECKBOXES"
    [questionItem]="item"
></cb-checkboxes-item-preview>
<cb-radiobuttons-item-preview
    *ngIf="item.item_type === questionType.RADIOBUTTONS"
    [questionItem]="item"
></cb-radiobuttons-item-preview>
<cb-captcha-item-preview
    *ngIf="item.item_type === questionType.CAPTCHA"
    [questionItem]="item"
></cb-captcha-item-preview>
<cb-dropdownlist-item-preview
    *ngIf="item.item_type === questionType.DROPDOWNLIST"
    [questionItem]="item"
></cb-dropdownlist-item-preview>
<cb-custom-source-dropdownlist-item-preview
    *ngIf="item.item_type === questionType.CUSTOM_SOURCE_DROPDOWNLIST"
    [questionItem]="item"
></cb-custom-source-dropdownlist-item-preview>
<cb-multilines-item-preview
    *ngIf="item.item_type === questionType.MULTI_LINE_TEXT"
    [questionItem]="item"
></cb-multilines-item-preview>
<cb-rating-scale-item-preview
    *ngIf="item.item_type === questionType.RATINGSCALE"
    [questionItem]="item"
></cb-rating-scale-item-preview>
<cb-net-promoter-score-item-preview
    *ngIf="item.item_type === questionType.NET_PROMOTER_SCORE"
    [questionItem]="item"
></cb-net-promoter-score-item-preview>
<cb-rating-item-preview
    *ngIf="item.item_type === questionType.RATING"
    [questionItem]="item"
></cb-rating-item-preview>
<cb-slider-item-preview
    *ngIf="item.item_type === questionType.SLIDER"
    [questionItem]="item"
></cb-slider-item-preview>
<cb-message-item-preview
    *ngIf="item.item_type === displayType.MESSAGE"
    [displayItem]="item"
></cb-message-item-preview>
<cb-matrix-item-preview
    *ngIf="item.item_type === questionType.MATRIX"
    [questionItem]="item"
></cb-matrix-item-preview>
<cb-contact-form-item-preview
    *ngIf="item.item_type === questionType.CONTACT_FORM"
    [questionItem]="item"
></cb-contact-form-item-preview>
<cb-rankorder-item-preview
    *ngIf="item.item_type === questionType.RANKORDER"
    [questionItem]="item"
></cb-rankorder-item-preview>
<cb-max-diff-item-preview
    *ngIf="item.item_type === questionType.MAXDIFF"
    [questionItem]="item"
></cb-max-diff-item-preview>
<cb-signature-item-preview
    *ngIf="item.item_type === questionType.SIGNATURE"
    [questionItem]="item"
></cb-signature-item-preview>
<cb-file-upload-item-preview
    *ngIf="item.item_type === questionType.FILE_UPLOAD"
    [questionItem]="item"
></cb-file-upload-item-preview>
<cb-javascript-item-preview
    *ngIf="item.item_type === actionType.JAVASCRIPT"
    [actionItem]="item"
></cb-javascript-item-preview>
<cb-profile-property-item-preview
    *ngIf="item.item_type === actionType.PROFILE_PROPERTY"
    [actionItem]="item"
></cb-profile-property-item-preview>
<cb-email-alert-item-preview
    *ngIf="item.item_type === actionType.EMAIL_ALERT"
    [actionItem]="item"
></cb-email-alert-item-preview>
<cb-email-response-item-preview
    *ngIf="item.item_type === actionType.EMAIL_RESPONSE"
    [actionItem]="item"
></cb-email-response-item-preview>
<cb-hyperlink-item-preview
    *ngIf="item.item_type === actionType.REDIRECT"
    [actionItem]="item"
></cb-hyperlink-item-preview>
<cb-webhook-item-preview
    *ngIf="item.item_type === actionType.WEBHOOK"
    [actionItem]="item"
></cb-webhook-item-preview>
<cb-image-item-preview
    *ngIf="item.item_type === displayType.IMAGE"
    [displayItem]="item"
></cb-image-item-preview>
<cb-scoring-message-item-preview
    *ngIf="item.item_type === displayType.SCORING_MESSAGE"
    [displayItem]="item"
></cb-scoring-message-item-preview>
<cb-response-summary-item-preview
    *ngIf="item.item_type === reportType.SUMMARY"
    [reportItem]="item"
></cb-response-summary-item-preview>
<cb-image-choice-item-preview
    *ngIf="item.item_type === questionType.IMAGE_CHOICE"
    [questionItem]="item">
</cb-image-choice-item-preview>
<cb-drill-down-item-preview
    *ngIf="item.item_type === questionType.DRILL_DOWN"
    removeIfFeatureOff="drilldown_question"
    [questionItem]="item">
</cb-drill-down-item-preview>
