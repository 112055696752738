import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import {
    FormGroup,
    FormBuilder,
    FormControl,
    Validators
} from '@angular/forms';
import { PageExpressionsGroupsType, SurveysPagesActions } from '../../../../../../infrastructure/consts/surveys.consts';
import { SharedService } from '../../../../../../infrastructure/services';
import { SingleLineItem, SINGLE_LINE_TYPES } from '../../../../../../shared/models/survey-items/question-items/singleLineItem';
import * as _ from 'lodash';
import { SurveyEditorValidator } from '../../../../../../infrastructure/validators/survey-editor.validators';
import { Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../../../../shared/decorators/autoUnsubscribe.decorator';
import { takeUntil } from 'rxjs/operators';
import { ValidationObject } from '../../../../../../infrastructure/models';
import { SurveyQuestionOptions } from '../../../../../models/page-items.model';
import { ContactFormFieldSystemName } from '../../../../../../shared/models/survey-items/question-items/contactFormItem';
import { composeExpressionsCondition } from '../../../../../../infrastructure/helpers/surveys-expressions.helper';

@Component({
    selector: 'cb-contact-form-field-dialog',
    templateUrl: './contact-form-field-dialog.component.html',
    styleUrls: ['./contact-form-field-dialog.component.scss']
})
@AutoUnsubscribe()
export class ContactFormFieldDialogComponent implements OnInit, OnDestroy {
    @Input() options: SurveyQuestionOptions;

    questionItem: SingleLineItem;
    contactFormItemId: number;
    fieldSysName: string;
    form: FormGroup;
    answerType: string;
    supportedAnswerFormats: [string, string][];
    questionText: string;
    editorText = '';
    validationMessages: ValidationObject[] = [];
    isSaveButtonClicked = false;

    private branching_rules: any[];
    private componentDestroyed = new Subject();

    constructor(
        private fb: FormBuilder,
        private sharedService: SharedService
    ) {}

    ngOnInit() {
        this.questionItem = this.options.itemData;
        this.questionItem.surveyId = +this.options.surveyId;
        this.questionItem.page_id = this.options.pageId;
        this.fieldSysName = this.options.contactFormFieldSysName;
        this.contactFormItemId = this.options.parentQuestionId;
        const allFormats = Object.entries(SINGLE_LINE_TYPES);
        const supportedFormats = this.getSupportedAnswerFormats(this.fieldSysName);
        this.supportedAnswerFormats = _.filter(allFormats, f => supportedFormats.indexOf(f[1]) > -1);
        this.answerType = this.questionItem.answer_format || this.supportedAnswerFormats[0][0];
        this.createFormGroup();
        this.sharedService
            .getData(SurveysPagesActions.UPDATE_SUBQUESTION_TEXT)
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe((textUpdateEventData) => {
                this.questionText = textUpdateEventData?.data?.question_text;
            });
        this.sharedService
            .getData(PageExpressionsGroupsType.SUBQUESTION_EXPRESSIONS_GROUP)
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe((gr) => {
                this.branching_rules = gr.data.branching_rules;
            });
    }

    private getSupportedAnswerFormats(fieldSysName: string): string[] {
        if (fieldSysName === ContactFormFieldSystemName.Email) {
            return [
                SINGLE_LINE_TYPES.None,
                SINGLE_LINE_TYPES.Email,
                SINGLE_LINE_TYPES.Regex
            ];
        }

        if (fieldSysName === ContactFormFieldSystemName.Phone) {
            return [
                SINGLE_LINE_TYPES.None,
                SINGLE_LINE_TYPES.Phone,
                SINGLE_LINE_TYPES.Numeric,
                SINGLE_LINE_TYPES.Regex
            ];
        }

        if (fieldSysName === ContactFormFieldSystemName.PostalCode) {
            return [
                SINGLE_LINE_TYPES.None,
                SINGLE_LINE_TYPES.Postal,
                SINGLE_LINE_TYPES.AlphaNumeric,
                SINGLE_LINE_TYPES.Alpha,
                SINGLE_LINE_TYPES.Numeric,
                SINGLE_LINE_TYPES.Uppercase,
                SINGLE_LINE_TYPES.Lowercase,
                SINGLE_LINE_TYPES.Regex
            ];
        }

        if (fieldSysName === ContactFormFieldSystemName.City ||
            fieldSysName === ContactFormFieldSystemName.State ||
            fieldSysName === ContactFormFieldSystemName.Country) {
            return [
                SINGLE_LINE_TYPES.None,
                SINGLE_LINE_TYPES.Alpha,
                SINGLE_LINE_TYPES.Uppercase,
                SINGLE_LINE_TYPES.Lowercase,
                SINGLE_LINE_TYPES.Regex
            ];
        }

        return [
            SINGLE_LINE_TYPES.None,
            SINGLE_LINE_TYPES.AlphaNumeric,
            SINGLE_LINE_TYPES.Alpha,
            SINGLE_LINE_TYPES.Uppercase,
            SINGLE_LINE_TYPES.Lowercase,
            SINGLE_LINE_TYPES.Regex
        ];

    }

    private createFormGroup(): void {
        let isAllQuestionsRequired = false;
        if (!this.questionItem) {
            this.questionItem = <SingleLineItem>{};
        }
        if (!this.questionItem.id) {
            isAllQuestionsRequired = this.sharedService.surveyRespondentSettings.getValue()
                ? this.sharedService.surveyRespondentSettings.getValue().makeQuestionsRequired
                : false;
        }
        this.form = this.fb.group({
            alias: new FormControl(this.questionItem.alias),
            is_required: new FormControl(this.questionItem.is_required || isAllQuestionsRequired),
            is_soft_required: new FormControl(
                this.options.softRequiredEnabled ? (this.questionItem.is_soft_required ?? false) : false),
            default_text: new FormControl(this.questionItem.default_text || ''),
            html_class: new FormControl(this.questionItem.html_class),
            answer_format: new FormControl(
                this.questionItem.answer_format || this.supportedAnswerFormats[0][0]),
            max_length: new FormControl(this.questionItem.max_length),
            min_value: new FormControl(this.questionItem.min_value),
            max_value: new FormControl(this.questionItem.max_value),
            regex_pattern: new FormControl(this.questionItem.regex_pattern),
            regex_validation_message: new FormControl(this.questionItem.regex_validation_message),
            item_position: new FormControl(this.questionItem.item_position),
            question_text_position: new FormControl(this.questionItem.question_text_position),
            width: new FormControl(this.questionItem.width)
        });
    }

    public onNumericKeyUp(event: any, isDate = false) {
        return SurveyEditorValidator.validateNumericInput(event, isDate, this.answerType === 'Decimal');
    }

    public onMergeDefaultText(updatedText) {
        const field = this.form.controls['default_text'];
        const newValue = field.value ? field.value + ' ' + updatedText : updatedText;
        field.setValue(newValue);
    }

    public isNumericMinMax(name: string) {
        return (
            name === 'Decimal' ||
            name === 'Integer' ||
            name === 'Numeric' ||
            name === 'Money'
        );
    }

    public onAnswerFormatChanged($event) {
        this.answerType = $event.value;
        this.form.controls['min_value'].setValue(null);
        this.form.controls['max_value'].setValue(null);
        this.form.controls['max_length'].setValue(null);
        this.form.controls['regex_pattern'].setValue(null);
        this.form.controls['regex_validation_message'].setValue(null);

        if ($event.value === 'Regex') {
            this.form.controls['regex_pattern'].setValidators([Validators.required]);
            this.form.controls['regex_validation_message'].setValidators([Validators.required]);
        } else {
            this.form.controls['regex_pattern'].setValidators([]);
            this.form.controls['regex_validation_message'].setValidators([]);
        }
    }

    public onQuestionItemSaveClick() {
        const questionItem = this.createQuestionItem();
        if (this.isValid(questionItem)) {
            this.sharedService.share(SurveysPagesActions.UPDATE_CONTACT_FORM_FIELD, questionItem);
        }
    }

    private createQuestionItem(): SingleLineItem {
        const control = this.form.controls;
        const isNumericMinMax = this.isNumericMinMax(control['answer_format'].value);
        const itemData = new SingleLineItem();

        itemData.id = this.questionItem.id;
        itemData.question_text = this.questionText;
        itemData.subtext = '';
        itemData.item_type = this.questionItem.item_type;
        itemData.max_length = !isNumericMinMax ? control['max_length'].value : null;
        itemData.is_required = control['is_required'].value;
        itemData.is_soft_required = control['is_soft_required'].value;
        itemData.alias = control['alias'].value;
        itemData.default_text = control['default_text'].value && this.answerType === 'Decimal'
            ? control['default_text'].value.replace(',', '.')
            : control['default_text'].value;
        itemData.html_class = control['html_class'].value;
        itemData.answer_format = control['answer_format'].value;
        itemData.min_value = isNumericMinMax
            ? control['min_value'].value && this.answerType === 'Decimal' ?
              control['min_value'].value.replace(',', '.') : control['min_value'].value
            : null;
        itemData.max_value = isNumericMinMax
            ? control['max_value'].value && this.answerType === 'Decimal' ?
            control['max_value'].value.replace(',', '.') : control['max_value'].value
            : null;
        itemData.question_text_position = control['question_text_position'].value;
        itemData.item_position = control['item_position'].value;
        itemData.width = control['width'].value;
        itemData.regex_pattern = control['regex_pattern'].value;
        itemData.regex_validation_message = control['regex_validation_message'].value;

        if (this.branching_rules) {
            itemData.include_condition = composeExpressionsCondition(
                this.branching_rules,
                true
            );
        }

        return itemData;
    }

    private isValid(itemData) {
        const errors = SurveyEditorValidator.validate(itemData);
        this.validationMessages = _.uniqBy(errors, 'validationMessage');
        return errors.length === 0;
    }

    public cancel() {
        this.sharedService.share(SurveysPagesActions.CANCEL_CONTACT_FORM_FIELD, null);
    }

    ngOnDestroy() {}
}
