<div class="cb-url d-flex align-items-center">
    <div class="url">
        {{ host && host.length ? host + '/' : urlLink }}
    </div>
    <div class="actions d-flex">
        <a target="_blank" [href]="urlLink + (attr ? attr : '')">
            <cb-button icon="fi_external-link" type="stroked"></cb-button>
        </a>
        <cb-button icon="fi_copy" type="stroked"
        ngxClipboard
        [cbContent]="urlLink + (attr ? attr : '')"
        (cbOnSuccess)="urlCopied()"
        matTooltip="{{ 'SHARED.COPY-CLIPBOARD' | translate }}">
            <a target="_blank" [href]="urlLink + (attr ? attr : '')">
                &nbsp;
            </a>
        </cb-button>
    </div>
</div>
