import { HyperlinkItem } from '../../../../../shared/models/survey-items/action-items/hyperlinkItem';
import { OnInit, Component, Input, OnDestroy } from '@angular/core';
import {
    SurveyActionType,
    SurveysPagesActions
} from '../../../../../infrastructure/consts/surveys.consts';
import { SharedService } from '../../../../../infrastructure/services';
import { JavascriptItem } from '../../../../../shared/models/survey-items/action-items/javascriptItem';
import { EmailAlertItem } from '../../../../../shared/models/survey-items/action-items/emailAlertItem';
import { EmailResponseItem } from '../../../../../shared/models/survey-items/action-items/emailResponseItem';
import { WebhookItem } from '../../../../../shared/models/survey-items/action-items/webhookItem';
import { composeExpressionsCondition } from '../../../../../infrastructure/helpers/surveys-expressions.helper';
import { SurveyQuestionOptions } from '../../../../models/page-items.model';
import { ProfilePropertyItem } from '../../../../../shared/models/survey-items/action-items/profilePropertyItem';
import { SurveyEditorValidator } from '../../../../../infrastructure/validators/survey-editor.validators';
import { PageExpressionsGroupsType } from '../../../../../infrastructure/consts/surveys.consts';
import { Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../../../shared/decorators/autoUnsubscribe.decorator';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'cb-action-item-dialog',
    templateUrl: './action-item-dialog.component.html',
    styleUrls: ['./action-item-dialog.component.scss']
})
@AutoUnsubscribe()
export class ActionItemDialogComponent implements OnInit, OnDestroy {
    @Input() options: SurveyQuestionOptions;
    itemType: string;
    itemData: any = {};
    editMode: boolean;
    branching_rules = [];
    isSaveButtonClicked = false;
    validationMessages: string[] = [];
    isSurveyTemplate: boolean;
    private componentDestroyed = new Subject();

    public actionType = SurveyActionType;

    constructor(private sharedService: SharedService) {}

    ngOnInit() {
        this.isSurveyTemplate = this.options.isSurveyTemplate;
        this.shareServiceSubscriptions();
        this.setOptions();
    }

    private setOptions() {
        if (this.options) {
            this.editMode = !!(
                this.options.itemData && this.options.itemData.id
            );
            this.itemType = this.options.itemType;
            if (this.editMode) {
                this.itemData = this.options.itemData;
            }

            this.itemData.page_id = this.options.pageId;
            this.itemData.surveyId = this.options.surveyId;
        }
    }

    private createActionItem() {
        switch (this.itemType) {
            case this.actionType.JAVASCRIPT:
                const javascriptItem = new JavascriptItem();
                this.initItem(javascriptItem);
                return javascriptItem;
            case this.actionType.EMAIL_ALERT:
                const emailAlertItem = new EmailAlertItem();
                this.initItem(emailAlertItem);
                return emailAlertItem;
            case this.actionType.EMAIL_RESPONSE:
                const emailResponseItem = new EmailResponseItem();
                this.initItem(emailResponseItem);
                return emailResponseItem;
            case this.actionType.WEBHOOK:
                const webhookItem = new WebhookItem();
                this.initItem(webhookItem);
                return webhookItem;
            case this.actionType.REDIRECT:
                const redirectItem = new HyperlinkItem();
                this.initItem(redirectItem);
                return redirectItem;
            case this.actionType.PROFILE_PROPERTY:
                const profileItem = new ProfilePropertyItem();
                this.initItem(profileItem);
                return profileItem;
        }
    }

    private initItem(itemData: any): void {
        this.getQuestionSettings(itemData);
        itemData.include_condition = composeExpressionsCondition(
            this.branching_rules,
            true
        );
        itemData.page_id = this.options.pageId;
        itemData.item_type = this.itemType;
    }

    private shareServiceSubscriptions(): void {
        this.sharedService.getData('actionItemSettings')
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(question => {
                this.itemData = question.data;
            });
        this.sharedService.getData(PageExpressionsGroupsType.EXPRESSIONS_GROUP)
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(gr => {
                this.branching_rules = gr.data.branching_rules;
            });
    }

    private getQuestionSettings(questionItem: any): void {
        if (this.itemData) {
            Object.keys(this.itemData).forEach(key => {
                questionItem[key] = this.itemData[key];
            });
        }
    }

    private isValid(itemData) {
        const errors = SurveyEditorValidator.validate(itemData);
        this.validationMessages =
            errors.length > 0 ? errors.map(x => x.validationMessage) : [];
        return errors.length === 0;
    }

    onActionItemSave(): void {
        const itemData = this.createActionItem();
        if (!this.isValid(itemData)) {
            return;
        }
        this.isSaveButtonClicked = true;
        if (this.editMode) {
            itemData.id = this.options.itemData.id;
        } else {
            itemData.position = this.options.numberOfQuestions + 1;
        }

        const messageName = this.editMode
            ? SurveysPagesActions.UPDATE_SURVEY_ITEM
            : SurveysPagesActions.ADD_SURVEY_ITEM;
        this.sharedService.share(messageName, itemData);
    }

    public cancel() {
        this.sharedService.share(SurveysPagesActions.CANCEL_SURVEY_ITEM, null);
    }

    ngOnDestroy() {}
}
