import { SurveyExpressionsSourceType } from '../consts/surveys.consts';
import * as _ from 'lodash';
import { convertDateToNonTimezoneString } from './date.helper';

const groupsJoinLogicalOperator = 'OR';
const answeredOperator = 'Answered';
const notAnsweredOperator = 'NotAnswered';

export function defineKeySourceName(expression) {
    const type = expression.left_operand
        ? expression.left_operand.operand_type
        : expression.source;
    switch (type) {
        case SurveyExpressionsSourceType.QUESTION:
            return 'item_id';
        case SurveyExpressionsSourceType.RESPONSE_PROPERTY:
            return 'response_key';
        case SurveyExpressionsSourceType.USER_ATTRIBUTE:
            return 'profile_key';
        default:
            break;
    }
}

export function defineKeyValueName(expression) {
    const _expression = expression.right_operand
        ? expression.right_operand.choice_id
        : expression.is_value_type_choices;
    if (_expression) {
        return 'choice_id';
    } else {
        return 'value';
    }
}

function checkEmptyFirstExpression(branching_rules: any[]) {
    if (
        branching_rules.length &&
        branching_rules[0].groups.length &&
        branching_rules[0].groups[0].expressions.length
    ) {
        return branching_rules[0].groups[0].expressions[0].left_operand
            .operand_type === SurveyExpressionsSourceType.DEFAULT
            ? []
            : branching_rules;
    }
    return [];
}

export function checkForEmptyExpressions(branching_rules: any[]) {
    branching_rules.forEach(br => {
        // if (br.groups && br.groups.length) {
        //     br.groups = br.groups.filter(g => g.expressions && g.expressions.length);
        // }
        if (br.condition && br.condition.groups && br.condition.groups.length) {
            br.condition.groups.forEach(group => {
                group.expressions = group.expressions.filter(
                    expr =>
                        expr.operator !== SurveyExpressionsSourceType.DEFAULT
                );
            });
            br.condition.groups = br.condition.groups.filter(
                g => g.expressions && g.expressions.length
            );
        }
    });
}

export function composeExpressionsCondition(
    branching_rules: any[],
    isQuestionItem = false
) {
    branching_rules.forEach(rule => {
        const { groups } = rule;
        groups.forEach(group => {
            group.expressions.forEach(expression => {
                const sourceName = defineKeySourceName(expression);
                const valueKeyName = defineKeyValueName(expression);
                expression.left_operand = {
                    [sourceName]: expression.question,
                    operand_type: expression.source
                };
                if (
                    expression.operator === answeredOperator ||
                    expression.operator === notAnsweredOperator
                ) {
                    expression.right_operand = null;
                    return;
                }
                if (
                    typeof expression.question === 'string' &&
                    expression.question.indexOf('CurrentDate') > -1
                ) {
                    expression.right_operand = {
                        [valueKeyName]: convertDateToNonTimezoneString(
                            expression.value
                        ),
                        operand_type: expression.operand_type
                    };
                } else {
                    if (expression.value && expression.operand_type) {
                        expression.right_operand = {
                            [valueKeyName]: expression.value,
                            operand_type: expression.operand_type
                        };
                    }
                    else{
                        expression.right_operand = null;
                    }
                }
                if (expression.is_value_type_choices) {
                    expression.right_operand = {
                        ...expression.right_operand,
                        item_id: expression.question
                    };
                }
            });
        });
        rule.condition = {
            groups: groups,
            logical_operator: groupsJoinLogicalOperator
        };
    });
    branching_rules = checkEmptyFirstExpression(
        branching_rules.filter(x => !_.isEmpty(x.condition.groups))
    );
    checkForEmptyExpressions(branching_rules);
    return isQuestionItem
        ? _.isEmpty(branching_rules)
            ? { groups: [], logical_operator: groupsJoinLogicalOperator }
            : branching_rules[0].condition
        : branching_rules;
}
