<div class="cb-new-question-dialog-content dialog-content">
    <ng-container>
        <cb-matrix-item 
            [matrixItem]="matrixItemData"
            >
        </cb-matrix-item> 
    </ng-container>
    <div class="invalid">{{ validationMessage }}</div>
    <div *ngFor="let message of validationMessages" class="invalid">
        <strong>{{ message.validationMessage }}</strong>
    </div>
    <div class="buttons-container large-button-container">
        <button mat-button type="button" class="btn-cancel" (click)="cancel()">
            {{ 'SHARED.CANCEL' | translate }}
        </button>
        <button
            mat-raised-button
            type="submit"
            class="btn-submit"
            (click)="onMatrixItemSaveClick()"
            [disabled]="isSaveButtonClicked"
        >
            {{ 'SHARED.SAVE' | translate }}
        </button>
    </div>
</div>
