export function isHTML(str) {
    const a = document.createElement('div');
    a.innerHTML = str;
    for (let c = a.childNodes, i = c.length; i--; ) {
      if (c[i].nodeType === 1) {
          return true;
        }
    }
    return false;
}
