<form [formGroup]="form" autocomplete="off" *ngIf="!isLoading">
    <div class="form-section">
        <cb-question-item-title></cb-question-item-title>
        <div class="cb-change-type-section">
            <cb-item-type [itemType]="fileUpload"></cb-item-type>
        </div>
        <cb-question-and-subtext
            [questionItem]="questionItem"
        ></cb-question-and-subtext>
    </div>
    <cb-behavior-options [parentForm]="form" [softRequiredEnabled]="questionItem.softRequiredEnabled">
        <div class="form-fields-container-outer">
            <div class="form-fields-container-inner">
                <mat-form-field class="cb-form-field">
                    <input
                        matInput
                        type="number"
                        min="1"
                        placeholder="{{
                            'SURVEY-EDITOR.SHARED.MAX-SIZE' | translate
                        }}"
                        formControlName="max_size"
                    />
                </mat-form-field>
            </div>
            <cb-help-icon class="alias-help" [tooltip]="'SURVEY-EDITOR.SHARED.TOOLTIPS.MAX-SIZE' | translate"></cb-help-icon>
        </div>
        <div class="form-fields-container-outer">
            <div class="form-fields-container-inner">
                <mat-form-field class="cb-form-field">
                    <input
                        matInput
                        type="number"
                        min="1"
                        placeholder="{{
                            'SURVEY-EDITOR.SHARED.EXPIRATION-IN-HOURS' | translate
                        }}"
                        formControlName="expiration_in_hours"
                    />
                </mat-form-field>
            </div>
            <cb-help-icon class="alias-help" [tooltip]="'SURVEY-EDITOR.SHARED.TOOLTIPS.EXPIRATION-IN-HOURS' | translate"></cb-help-icon>
        </div>            
        <div *ngIf="maxSizeErrorMessage()">
            <p class="error-message">{{maxSizeErrorMessage()}}</p>
        </div>
    </cb-behavior-options>
    <div class="form-section border-top">
        <cb-question-collapsed-panel
            title="{{ 'SURVEY-EDITOR.SHARED.FILE-TYPES' | translate }}"
            expanded="true"
        >
            <div class="form-filetypes-container">
                <div
                    class="form-fields-group"
                    *ngFor="let type of allowedFileTypes "
                >
                    <mat-checkbox
                        class="cb-form-field"
                        [value]="type.extension"
                        (change)="onTypeSelected(type.extension)"
                        [checked]="isChecked(type.extension)"
                        >{{ type.extension }}</mat-checkbox
                    >
                </div>
            </div>
        </cb-question-collapsed-panel>
    </div>
    <div class="form-section">
        <cb-question-collapsed-panel
            title="{{
                'SURVEY-EDITOR.SHARED.APPEARANCE.APPEARANCE' | translate
            }}"
        >
            <div class="form-group-position">
                <cb-label-position
                    class="position-control-group"
                    [selectedOption]="textPosition"
                    (selected)="labelPositionSelected($event)"
                ></cb-label-position>
            </div>
        </cb-question-collapsed-panel>
    </div>
    <div class="form-section">
        <cb-question-collapsed-panel
            title="{{ 'SURVEY-EDITOR.SHARED.CONDITIONS' | translate }}"
            [tooltip]="'SURVEY-EDITOR.SHARED.TOOLTIPS.CONDITIONS' | translate"
        >
            <div class="">
                <cb-groups-expressions-element
                    [source]="questionItem"
                ></cb-groups-expressions-element>
            </div>
        </cb-question-collapsed-panel>
    </div>
</form>
