<cb-spinner [show]="!page.items"></cb-spinner>
<div class="cb-page-edit-toolbar" *ngIf="page.items">
    <button
        mat-button
        *ngIf="page.page_type !== surveyPageType.Completion"
        class="cb-button-default"
        [matMenuTriggerFor]="questionMenu"
        (click)="onOpenSurveyQuestionsDialog('questionItems')"
    >
        <cb-svg-icon class="icon" [name]="'add-question'"></cb-svg-icon>
        {{ 'SURVEY-ITEM-LIST.ADD-SURVEY-QUESTION' | translate }}
    </button>
    <button
        mat-button
        class="cb-button-default"
        [matMenuTriggerFor]="questionMenu"
        (click)="onOpenSurveyQuestionsDialog('displayItems')"
    >
        <cb-svg-icon class="icon" [name]="'add-image'"></cb-svg-icon>
        {{ 'SURVEY-ITEM-LIST.ADD-DISPLAY-ITEM' | translate }}
    </button>
    <button
        mat-button
        class="cb-button-default"
        [matMenuTriggerFor]="questionMenu"
        (click)="onOpenSurveyQuestionsDialog('reportItems')"
    >
        <cb-svg-icon class="icon" [name]="'add-piechart'"></cb-svg-icon>
        {{ 'SURVEY-ITEM-LIST.ADD-REPORT-ITEM' | translate }}
    </button>
    <button
        mat-button
        class="cb-button-default"
        [matMenuTriggerFor]="questionMenu"
        (click)="onOpenSurveyQuestionsDialog('actionItems')"
    >
        <cb-svg-icon class="icon" [name]="'add-action'"></cb-svg-icon>
        {{ 'SURVEY-ITEM-LIST.ADD-ACTION-ITEM' | translate }}
    </button>
    <button
        *ngIf="!isSurveyTemplate"
        mat-button
        class="cb-button-default"
        (click)="openFromTemplateDialog()"
    >
        <cb-svg-icon class="icon" [name]="'library'"></cb-svg-icon>
        {{ 'SURVEY-ITEM-LIST.ADD-FROM-TEMPLATE' | translate }}
    </button> 
</div>

<mat-menu
    #questionMenu="matMenu"
    [overlapTrigger]="false"
    class="action-button-menu-panel"
    xPosition="after"
>
    <cb-menu-dialog
        [itemType]="itemType"
        [page]="page"
        [surveyId]="surveyId"
        [pageTitles]="pageTitles"
        [allowJavascript]="allowJavascript"
        [allowFileUpload]="allowFileUpload"
        [allowWebhookItem]="allowWebhookItem"
        [showInvisibleCaptchaQuestion]="showInvisibleCaptchaQuestion"
        [showVisibleCaptchaQuestion]="showVisibleCaptchaQuestion"
        [isSurveyTemplate]="isSurveyTemplate" 
        [softRequiredEnabled]="softRequiredEnabled"
    ></cb-menu-dialog>
</mat-menu>
