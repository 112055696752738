import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { SharedService } from '../../../../../../infrastructure/services';
import { Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../../../../shared/decorators/autoUnsubscribe.decorator';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'cb-matrix-column-options',
    templateUrl: './matrix-column-options.component.html',
    styleUrls: ['./matrix-column-options.component.scss']
})
@AutoUnsubscribe()
export class MatrixColumnOptionsComponent implements OnInit, OnDestroy {
    @Input() data: any;
    private componentDestroyed = new Subject();

    form: FormGroup;
    constructor(
        private fb: FormBuilder,
        private sharedService: SharedService
    ) {}

    ngOnInit() {
        this.form = this.fb.group({
            unique_answer: new FormControl(
                this.data ? this.data.unique_answer : false
            ),
            width: new FormControl(this.data ? this.data.width : '')
        });

        this.form.valueChanges
            .pipe(
                takeUntil(this.componentDestroyed)
            )
            .subscribe(s => {
                    if (this.data) {
                        this.data.width = s.width;
                    }
                    this.sharedService.share('columnOptions', s);
                }
            );
    }

    ngOnDestroy() {}
}
