import { first } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';
import { LanguageItemModel } from '../../../../../../infrastructure/models/survey-settings.model';
import { SurveysSettingProvider } from '../../../../../../infrastructure/providers/surveys-setting.provider';

@Component({
    selector: 'cb-question-item-title',
    templateUrl: './question-item-title.component.html',
    styleUrls: ['./question-item-title.component.scss']
})
export class QuestionItemTitleComponent implements OnInit {
    @Input() title = 'SURVEY-EDITOR.SHARED.QUESTION';
    selectedLanguage: LanguageItemModel;

    constructor(
        private surveysSettingProvider: SurveysSettingProvider
    ) {}

    ngOnInit(): void {
        this.subscribeOnSurveyLanguageSettings();
    }

    subscribeOnSurveyLanguageSettings() {
        this.surveysSettingProvider
            .getCurrentSurveySelectedLanguage()
            .pipe(first())
            .subscribe(data => {
                this.selectedLanguage = data;
            });
    }
}
