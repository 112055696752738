<div class="cb-new-question-dialog-content dialog-content">
    <button
        class="btn-enabled"
        mat-icon-button
        *ngIf="questionItemData"
        (click)="questionItemData.enabled = !questionItemData.enabled"
    >
        <i class="material-icons icon"
           matTooltip="{{ 'SHARED.HIDE' | translate }}"
           *ngIf="questionItemData.enabled">
            visibility
        </i>
        <i class="material-icons icon"
           matTooltip="{{ 'SHARED.SHOW' | translate }}"
           *ngIf="!questionItemData.enabled">
            visibility_off
        </i>
    </button>
    <!--<h2>{{ editMode ? 'Edit' : 'Add new'}} question - {{itemType}}</h2>-->
    <ng-container>
        <cb-singlelines-item
            *ngIf="itemType === questionType.SINGLE_LINE_TEXT"
            [questionItem]="questionItemData"
            [isMatrix]="isMatrix"
        ></cb-singlelines-item>
        <cb-multilines-item
            *ngIf="itemType === questionType.MULTI_LINE_TEXT"
            [questionItem]="questionItemData"
        ></cb-multilines-item>
        <cb-checkboxes-item
            *ngIf="itemType === questionType.CHECKBOXES"
            [questionItem]="questionItemData"
            [isMatrix]="isMatrix"
            [isSurveyTemplate] = "isSurveyTemplate"
            [resetChoicesEvent] = "resetChoicesSubject.asObservable()"
        ></cb-checkboxes-item>
        <cb-radiobuttons-item
            *ngIf="itemType === questionType.RADIOBUTTONS"
            [questionItem]="questionItemData"
            [isMatrix]="isMatrix"
            [isSurveyTemplate] = "isSurveyTemplate"
            [resetChoicesEvent] = "resetChoicesSubject.asObservable()"
        ></cb-radiobuttons-item>
        <cb-image-choice-item
            *ngIf="itemType === questionType.IMAGE_CHOICE"
            [questionItem]="questionItemData"
            [resetChoicesEvent] = "resetChoicesSubject.asObservable()"
        ></cb-image-choice-item>
        <cb-dropdownlist-item
            *ngIf="itemType === questionType.DROPDOWNLIST"
            [questionItem]="questionItemData"
            [isMatrix]="isMatrix"
            [isSurveyTemplate] = "isSurveyTemplate"
            [resetChoicesEvent] = "resetChoicesSubject.asObservable()"
        ></cb-dropdownlist-item>
        <cb-custom-source-dropdownlist-item
            *ngIf="itemType === questionType.CUSTOM_SOURCE_DROPDOWNLIST"
            [questionItem]="questionItemData"
            [isMatrix]="isMatrix"
        ></cb-custom-source-dropdownlist-item>
        <cb-rating-scale-item
            *ngIf="itemType === questionType.RATINGSCALE"
            [questionItem]="questionItemData"
            [isMatrix]="isMatrix"
            [isSurveyTemplate] = "isSurveyTemplate"
            [resetChoicesEvent] = "resetChoicesSubject.asObservable()"
        ></cb-rating-scale-item>
        <cb-net-promoter-score
            *ngIf="itemType === questionType.NET_PROMOTER_SCORE"
            [questionItem]="questionItemData"
        ></cb-net-promoter-score>
        <cb-slider-item
            *ngIf="itemType === questionType.SLIDER"
            [questionItem]="questionItemData"
            [isMatrix]="isMatrix"
            [isSurveyTemplate] = "isSurveyTemplate"
            [resetChoicesEvent] = "resetChoicesSubject.asObservable()"
        ></cb-slider-item>
        <cb-rating-item
            *ngIf="itemType === questionType.RATING"
            [questionItem]="questionItemData"
            [resetChoicesEvent] = "resetChoicesSubject.asObservable()"
        ></cb-rating-item>
        <cb-message-item
            *ngIf="itemType === displayType.MESSAGE"
            [questionItem]="questionItemData"
        ></cb-message-item>
        <cb-image-item
            *ngIf="itemType === displayType.IMAGE"
            [displayItem]="questionItemData"
            (onImageUpload)="onQuestionItemUpload($event)"
        ></cb-image-item>
        <cb-rank-order-item
            *ngIf="itemType === questionType.RANKORDER"
            [questionItem]="questionItemData"
            [resetChoicesEvent] = "resetChoicesSubject.asObservable()"
        ></cb-rank-order-item>
        <cb-max-diff-item
            *ngIf="itemType === questionType.MAXDIFF"
            [questionItem]="questionItemData"
            [resetChoicesEvent] = "resetChoicesSubject.asObservable()"
        ></cb-max-diff-item>
        <cb-signature-item
            *ngIf="itemType === questionType.SIGNATURE"
            [questionItem]="questionItemData"
        ></cb-signature-item>
        <cb-file-upload-item
            *ngIf="itemType === questionType.FILE_UPLOAD"
            [questionItem]="questionItemData"
        ></cb-file-upload-item>
        <cb-contact-form-item
            *ngIf="itemType === questionType.CONTACT_FORM"
            [contactFormItem]="questionItemData"
        ></cb-contact-form-item>
        <cb-sum-total-item
            *ngIf="itemType === questionType.SUM_TOTAL"
            [questionItem]="questionItemData"
        ></cb-sum-total-item>
        <cb-captcha-item
            *ngIf="itemType === questionType.CAPTCHA"
            [questionItem]="questionItemData"
        ></cb-captcha-item>
        <cb-scoring-message-item
            *ngIf="itemType === displayType.SCORING_MESSAGE"
            [questionItem]="questionItemData"
        ></cb-scoring-message-item>
        <cb-response-summary-item
            *ngIf="itemType === reportType.SUMMARY"
            [questionItem]="questionItemData"
        ></cb-response-summary-item>
        <cb-drill-down-item
            *ngIf="itemType === questionType.DRILL_DOWN"
            removeIfFeatureOff="drilldown_question"
            [questionItem]="questionItemData"
        ></cb-drill-down-item>
        <div *ngIf="isMatrix">
            <cb-matrix-column-options
                [data]="columnOptions"
            ></cb-matrix-column-options>
        </div>
    </ng-container>
    <div *ngFor="let message of validationMessages" class="invalid">
        <strong>{{ message.validationMessage }}</strong>
    </div>
    <div class="buttons-container large-button-container">
        <button mat-button type="button" class="btn-cancel" (click)="cancel()">
            {{ 'SHARED.CANCEL' | translate }}
        </button>
        <button
            mat-raised-button
            type="submit"
            class="btn-submit"
            (click)="onQuestionItemSaveClick()"
            [disabled]="isSaveButtonClicked || isUploading"
        >
            {{ 'SHARED.SAVE' | translate }}
        </button>
    </div>
</div>
