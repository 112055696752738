import { ActionReducer, combineReducers } from '@ngrx/store';

import * as general from '../../../infrastructure/store/reducers';
import * as user from './users.state';
import { usersReducer } from './users.reducer';
import * as survey from './surveys.state';
import * as currentSurvey from './current-survey.state';
import { surveysReducer } from './surveys.reducer';
import { currentSurveyReducer } from './current-survey.reducer';

export interface AppState extends general.AppState {
    users: user.State;
    surveys: survey.State;
    currentSurvey: currentSurvey.State;
}

const rootReducers: any = {
    users: usersReducer,
    surveys: surveysReducer,
    currentSurvey: currentSurveyReducer
};

// WORKAROUND: the workaround helps to merge
// general reduceres with application specific reducers
// it's needed for the success of AoT compilation
export let reducers = null;
function initReducers() {
    reducers = { ...general.reducers, ...rootReducers };
}
initReducers();
