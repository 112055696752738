import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AppState } from './reducers';
import { surveys } from './actions';
import { PagedList, SurveyListItem } from '../../infrastructure/models';
import { FavoriteChange, SurveyListGetData } from '../models';
import {
    getSurveyList,
    getFavoriteSurveys,
    getSurveysLoadingStatus,
    getSelectedSurveyIds,
    getParameters,
    getFavoriteParameters

} from './selectors';

@Injectable()
export class SurveysStore {
    constructor(private store: Store<AppState>) {}

    get isLoading(): Observable<boolean> {
        return this.store.select(getSurveysLoadingStatus);
    }
    get surveys(): Observable<PagedList<SurveyListItem>> {
        return this.store.select(getSurveyList);
    }
    get favoriteSurveys(): Observable<PagedList<SurveyListItem>> {
        return this.store.select(getFavoriteSurveys);
    }
    get selectedSurveyIds(): Observable<number[]> {
        return this.store.select(getSelectedSurveyIds);
    }
    get parameters(): Observable<SurveyListGetData> {
        return this.store.select(getParameters);
    }
    get favoriteParameters(): Observable<SurveyListGetData> {
        return this.store.select(getFavoriteParameters);
    }

    loadSurveys(data?: SurveyListGetData): void {
        this.store.dispatch(new surveys.ReloadSurveyListAction(data));
    }
    loadMoreSurveys(): void {
        this.store.dispatch(new surveys.MoreSurveyListAction());
    }
    loadFavoriteSurveys(data: SurveyListGetData=null): void {
        this.store.dispatch(new surveys.ReloadSurveyFavoritesAction(data));
    }
    surveyChangeSelection(ids: number[]): void {
        this.store.dispatch(new surveys.SelectSurveyAction(ids));
    }
    surveyChangeFavorite(surveyId: number, newFavState: boolean): void {
        this.store.dispatch(new surveys.ChangeFavoriteAction(<FavoriteChange>{surveyId, isFavorite: newFavState}));
    }
}
