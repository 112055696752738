<cb-chip *ngIf="status === 'Published'" [text]="'SURVEYS-LIST.ACTIVE' | translate" icon="fi_check" color="success"></cb-chip>
<cb-chip *ngIf="status === 'Draft'" [text]="'SURVEYS-LIST.DRAFT' | translate" icon="fi_edit" color="accent"></cb-chip>
<cb-chip *ngIf="status === 'Archived'" [text]="'SURVEYS-LIST.ARCHIVED' | translate" icon="fi_archive"></cb-chip>
<div *ngIf="status === 'Scheduled'" class="status-block sb-scheduled">
    <i class="fas fa-calendar-alt"></i>
    <span class="cb-status-text">{{
        'SURVEYS-LIST.SCHEDULED' | translate
    }}</span>
</div>
<div *ngIf="status === 'Sent'" class="status-block sb-sent">
    <i class="fas fa-paper-plane"></i>
    <span class="cb-status-text">{{ 'SURVEYS-LIST.SENT' | translate }}</span>
</div>
