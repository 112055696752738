import { Component, Input, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { SurveyQuestionType } from '../../../../../infrastructure/consts/surveys.consts';
import { SharedService } from '../../../../../infrastructure/services';
import { CustomSourceDropdownListItem } from '../../../../../shared/models/survey-items/question-items/customSourceDropdownListItem';
import { TranslateService } from '@ngx-translate/core';
import { CustomSourceDropdownlistProvider } from '../../../../../infrastructure/providers';
import { Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../../../shared/decorators/autoUnsubscribe.decorator';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'cb-custom-source-dropdownlist-item',
    templateUrl: './custom-source-dropdownlist-item.component.html',
    styleUrls: ['./custom-source-dropdownlist-item.component.scss']
})
@AutoUnsubscribe()
export class CustomSourceDropDownListItemComponent implements OnInit, OnDestroy {
    @Input() questionItem: CustomSourceDropdownListItem;
    @Input() isMatrix: boolean;
    form: FormGroup;
    dropdownlist = SurveyQuestionType.CUSTOM_SOURCE_DROPDOWNLIST;
    itemPosition: string;
    textPosition: string;
    valuePreviews = [];
    private componentDestroyed = new Subject();

    constructor(
        private fb: FormBuilder,
        private sharedService: SharedService,
        private translate: TranslateService,
        private cdr: ChangeDetectorRef,
        private customSourceDropdownlistProvider: CustomSourceDropdownlistProvider
    ) {
    }

    ngOnInit() {
        this.createFormGroup();
        this.createQuestionItemObject();
    }

    itemPositionSelected(value): void {
        this.itemPosition = value;
        this.form.controls['item_position'].setValue(value);
    }

    labelPositionSelected(value): void {
        this.textPosition = value;
        this.form.controls['question_text_position'].setValue(value);
    }

    private createFormGroup(): void {
        let isAllQuestionsRequired = false;
        let other;
        this.translate
            .get('SURVEY-EDITOR.SHARED.OTHER')
            .subscribe((text: string) => {
                other = text;
            });
        if (!this.questionItem) {
            this.questionItem = <CustomSourceDropdownListItem>{};
         }
        if (!this.questionItem.id) {
            isAllQuestionsRequired = this.sharedService.surveyRespondentSettings.getValue() ?
            this.sharedService.surveyRespondentSettings.getValue().makeQuestionsRequired : false;
        }
        this.form = this.fb.group({
            choice_list_url: new FormControl(this.questionItem.choice_list_url, [Validators.required]),
            value_field: new FormControl(this.questionItem.value_field),
            // behavior
            alias: new FormControl(this.questionItem.alias),
            html_class: new FormControl(this.questionItem.html_class),
            is_required: new FormControl(this.questionItem.is_required || isAllQuestionsRequired),
            is_soft_required: new FormControl(this.questionItem.softRequiredEnabled ? !!this.questionItem.is_soft_required : false),
            allow_other: new FormControl(!!this.questionItem.allow_other),
            other_text: new FormControl(this.questionItem.other_text),

            // appearance
            question_text_position: new FormControl(
                this.questionItem.question_text_position
            ),
            item_position: new FormControl(this.questionItem.item_position),
            show_number_labels: new FormControl(
                !!this.questionItem.show_number_labels
            )
        });

        this.itemPosition = this.questionItem.item_position;
        this.textPosition = this.questionItem.question_text_position;
    }

    private createQuestionItemObject(): void {
        this.updateQuestionItemObject();
        this.form.valueChanges
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(() => {
                this.updateQuestionItemObject();
            });
    }

    private updateQuestionItemObject(): void {
        const control = this.form.controls;
        const questionItem = new CustomSourceDropdownListItem();
        questionItem.item_type = SurveyQuestionType.CUSTOM_SOURCE_DROPDOWNLIST;
        questionItem.choice_list_url = control['choice_list_url'].value;
        questionItem.value_field = control['value_field'].value;
        questionItem.alias = control['alias'].value;
        questionItem.html_class = control['html_class'].value;
        questionItem.allow_other = control['allow_other'].value;
        questionItem.other_text = control['other_text'].value;
        questionItem.is_required = control['is_required'].value;
        questionItem.is_soft_required = control['is_soft_required'].value;
        questionItem.question_text_position = control['question_text_position'].value;
        questionItem.item_position = control['item_position'].value;
        questionItem.show_number_labels = control['show_number_labels'].value;
        questionItem.randomize = false;
        this.sharedService.share('questionItemSettings', questionItem);
    }

    public checkEnteredUrl() {
        const url = this.form.controls['choice_list_url'].value;
        if (url) {
            const valueField = this.form.controls['value_field'].value;
            this.customSourceDropdownlistProvider.getChoices(url, valueField).subscribe(
                (items: any) => {
                    this.valuePreviews = items;
                    this.cdr.detectChanges();
                }
            );
        }

    }

    onTextMerge(updatedText, fieldName) {
        const field = this.form.controls[fieldName];
        field.setValue(`${field.value || ''}${updatedText}`);
    }

    ngOnDestroy() {}
}
