<form [formGroup]="form">
    <ngs-code-editor [theme]="theme" style="height:300px;" [codeModel]="scriptModel" (valueChanged)="onChange($event)"></ngs-code-editor>
    <h3>
        {{ 'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.CONDITIONS' | translate }}
        <cb-help-icon [tooltip]="'SURVEY-EDITOR.SHARED.TOOLTIPS.CONDITIONS' | translate"></cb-help-icon>
    </h3>
    <cb-groups-expressions-element
        [source]="actionItem"
    ></cb-groups-expressions-element>
</form>
