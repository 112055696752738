import { Input, Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { SurveyQuestionType } from '../../../../../infrastructure/consts/surveys.consts';
import { SharedService } from '../../../../../infrastructure/services';
import { ContactFormField, ContactFormFieldSystemName, ContactFormItem } from '../../../../../shared/models/survey-items/question-items/contactFormItem';
import { SurveyEditorValidator } from '../../../../../infrastructure/validators/survey-editor.validators';
import { Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../../../shared/decorators/autoUnsubscribe.decorator';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { SingleLineItem } from '../../../../../shared/models/survey-items/question-items/singleLineItem';

@Component({
    selector: 'cb-contact-form-item',
    templateUrl: './contact-form-item.component.html',
    styleUrls: ['./contact-form-item.component.scss']
})
@AutoUnsubscribe()
export class ContactFormItemComponent implements OnInit, OnDestroy {
    @Input() contactFormItem: ContactFormItem;

    form: FormGroup;
    itemPosition: string;
    textPosition: string;
    contactFormItemType = SurveyQuestionType.CONTACT_FORM;
    private contactFormFields: any[] = [];
    private componentDestroyed = new Subject();

    constructor(
        private fb: FormBuilder,
        private sharedService: SharedService,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this.createFormGroup();
        this.updateQuestionItemObject();
        this.form.controls['is_required'].valueChanges
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(isRequired => {
                this.setFieldsRequired(isRequired);
            });
        this.form.valueChanges
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(s => {
                this.updateQuestionItemObject();
            });
    }

    private createFormGroup(): void {
        if (!this.contactFormItem) {
            this.contactFormItem = <ContactFormItem>{};
        }

        let isAllQuestionsRequired = false;
        if (!this.contactFormItem.id) {
            isAllQuestionsRequired = this.sharedService.surveyRespondentSettings.getValue()
                ? this.sharedService.surveyRespondentSettings.getValue().makeQuestionsRequired
                : false;
            this.initContactFormFields(isAllQuestionsRequired);
        }

        this.form = this.fb.group({
            is_required: new FormControl(this.contactFormItem.is_required || isAllQuestionsRequired),
            alias: new FormControl(this.contactFormItem.alias),
            html_class: new FormControl(this.contactFormItem.html_class),
            item_position: new FormControl(this.contactFormItem.item_position),
            question_text_position: new FormControl(
                this.contactFormItem.question_text_position
            ),
            width: new FormControl(this.contactFormItem.width)
        });

        this.itemPosition = this.contactFormItem.item_position;
        this.textPosition = this.contactFormItem.question_text_position;
    }

    private initContactFormFields(requireFields: boolean): void {
        const textKeyPrefix = 'SURVEY-EDITOR.ITEMS.CONTACT-FORM.FIELD-NAMES.';
        const formFields = [
            {
                system_name: ContactFormFieldSystemName.FirstName,
                text_key: textKeyPrefix + 'FIRST-NAME',
                answer_format: 'None'
            },
            {
                system_name: ContactFormFieldSystemName.LastName,
                text_key: textKeyPrefix + 'LAST-NAME',
                answer_format: 'None'
            },
            {
                system_name: ContactFormFieldSystemName.Organization,
                text_key: textKeyPrefix + 'ORGANIZATION',
                answer_format: 'None'
            },
            {
                system_name: ContactFormFieldSystemName.Address1,
                text_key: textKeyPrefix + 'ADDRESS1',
                answer_format: 'None'
            },
            {
                system_name: ContactFormFieldSystemName.Address2,
                text_key: textKeyPrefix + 'ADDRESS2',
                answer_format: 'None'
            },
            {
                system_name: ContactFormFieldSystemName.City,
                text_key: textKeyPrefix + 'CITY',
                answer_format: 'None'
            },
            {
                system_name: ContactFormFieldSystemName.State,
                text_key: textKeyPrefix + 'STATE',
                answer_format: 'None'
            },
            {
                system_name: ContactFormFieldSystemName.PostalCode,
                text_key: textKeyPrefix + 'POSTAL-CODE',
                answer_format: 'Postal'
            },
            {
                system_name: ContactFormFieldSystemName.Country,
                text_key: textKeyPrefix + 'COUNTRY',
                answer_format: 'None'
            },
            {
                system_name: ContactFormFieldSystemName.Email,
                text_key: textKeyPrefix + 'EMAIL',
                answer_format: 'Email'
            },
            {
                system_name: ContactFormFieldSystemName.Phone,
                text_key: textKeyPrefix + 'PHONE',
                answer_format: 'Phone'
            }
        ];

        const textKeys = formFields.map(f => f.text_key);

        this.translate
            .get(textKeys)
            .subscribe((translations: any) => {
                this.contactFormItem.fields = formFields
                    .map((f, i) => this.createContactFormField(
                        i + 1, f.system_name, translations[f.text_key], f.answer_format, requireFields));
            });
    }

    private createContactFormField(
        position: number,
        sysName: ContactFormFieldSystemName,
        fieldDisplayName: string, 
        answerFormat: string,
        isRequired: boolean) : ContactFormField {

        return <ContactFormField> {
            position: position,
            system_name: sysName,
            enabled: true,
            question: <SingleLineItem>{
                item_type: SurveyQuestionType.SINGLE_LINE_TEXT,
                question_text: fieldDisplayName,
                answer_format: answerFormat,
                is_required: isRequired,
                enabled: true
            }
        }
    }


    private updateQuestionItemObject(): void {
        const control = this.form.controls;
        const questionItem = new ContactFormItem();
        questionItem.item_type = SurveyQuestionType.CONTACT_FORM;
        questionItem.is_required = control['is_required'].value;
        questionItem.alias = control['alias'].value;
        questionItem.html_class = control['html_class'].value;
        questionItem.question_text_position = control['question_text_position'].value;
        questionItem.item_position = control['item_position'].value;
        questionItem.width = control['width'].value;
        questionItem.fields = this.contactFormFields;

        this.sharedService.share('questionItemSettings', questionItem);
    }

    public itemPositionSelected(position): void {
        this.itemPosition = position;
        this.form.controls['item_position'].setValue(position);
    }

    public labelPositionSelected(position): void {
        this.textPosition = position;
        this.form.controls['question_text_position'].setValue(position);
    }

    public onNumericKeyUp(event: any) {
        return SurveyEditorValidator.validateNumericInput(event);
    }

    public setFieldsRequired(isRequired: boolean) {
        this.contactFormFields
            .forEach((f: ContactFormField) => f.question.is_required = isRequired);
    }

    public onFormFieldsUpdated(updatedFields: ContactFormField[]) {
        this.contactFormFields = updatedFields;

        const allFieldsRequired = !this.contactFormFields
            .find((f: ContactFormField) => !f.question.is_required);
        const requiredControlValue = this.form.controls['is_required'].value;
        if (allFieldsRequired != requiredControlValue) {
            this.form.controls['is_required'].setValue(allFieldsRequired, { emitEvent: false });
        }

        this.updateQuestionItemObject();
    }

    ngOnDestroy() {}
}
