<form [formGroup]="form">
    <h3>{{ 'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.MESSAGE-FORMAT' | translate }}</h3>
    <mat-radio-group
        class="action-item-controls"
        formControlName="format"
    >
        <mat-radio-button value="Html"
            >Html</mat-radio-button
        >
        <mat-radio-button value="Text">{{
            'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.TEXT' | translate
        }}</mat-radio-button>
    </mat-radio-group>
    <br />
    <br />
    <mat-form-field *ngIf="messageFormat === 'Text'">
        <textarea
            matInput
            placeholder="{{
                'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.MESSAGE' | translate
            }}"
            formControlName="body"
        ></textarea>
    </mat-form-field>
    <div *ngIf="messageFormat === 'Html'">
        <cb-tiny-rich-editor
            formControlName="body"
            #messageBodyEditor
        ></cb-tiny-rich-editor>
        <cb-merge-text-list
            [surveyId]="actionItem.surveyId"
            [pageId]="actionItem.page_id"
            [returnCode]="true"
            (updateText)="onBodyMerge($event)"
        >
        </cb-merge-text-list>
    </div>
    <br />

    <mat-checkbox
        formControlName="send_once"
        >{{
            'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.SEND-ONCE' | translate
        }}</mat-checkbox
    >
    <br />
    <div>
        <mat-form-field>
            <input
                matInput
                placeholder="{{
                    'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.FROM-EMAIL' | translate
                }}"
                formControlName="from"
            />
        </mat-form-field>
        <cb-merge-text-list
            [surveyId]="actionItem.surveyId"
            [pageId]="actionItem.page_id"
            [text]="editorText"
            (updateText)="onMergeText($event, 'from')"
        >
        </cb-merge-text-list>
    </div>
    <div>
        <mat-form-field>
            <input
                matInput
                placeholder="{{
                    'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.TO-EMAIL' | translate
                }}"
                formControlName="to"
            />
        </mat-form-field>
        <cb-merge-text-list
            [surveyId]="actionItem.surveyId"
            [pageId]="actionItem.page_id"
            [text]="editorText"
            (updateText)="onMergeText($event, 'to')"
        >
        </cb-merge-text-list>
    </div>
    <div>
        <mat-form-field>
            <input
                matInput
                placeholder="{{
                    'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.SUBJECT' | translate
                }}"
                formControlName="subject"
            />
        </mat-form-field>
        <cb-merge-text-list
            [surveyId]="actionItem.surveyId"
            [pageId]="actionItem.page_id"
            [text]="editorText"
            (updateText)="onMergeText($event, 'subject')"
        >
        </cb-merge-text-list>
    </div>
    <div>
        <mat-form-field>
            <input
                matInput
                placeholder="{{
                    'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.BCC' | translate
                }}"
                formControlName="bcc"
            />
        </mat-form-field>
        <cb-merge-text-list
            [surveyId]="actionItem.surveyId"
            [pageId]="actionItem.page_id"
            [text]="editorText"
            (updateText)="onMergeText($event, 'bcc')"
        >
        </cb-merge-text-list>
    </div>
    <h3>{{ 'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.BEHAVIOUR' | translate }}</h3>
    <div class="action-item-controls">
        <mat-checkbox
            name="include_response_details"
            formControlName="include_response_details"
            >{{
                'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.INCLUDE-RESPONSE-DETAILS'
                    | translate
            }}</mat-checkbox
        >
        <mat-checkbox
            name="include_message_items"
            formControlName="include_message_items"
            >{{
                'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.INCLUDE-MESSAGE' | translate
            }}</mat-checkbox
        >
        <mat-checkbox
            name="show_page_numbers"
            formControlName="show_page_numbers"
            >{{
                'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.SHOW-PAGE-NUMBERS' | translate
            }}</mat-checkbox
        >
        <mat-checkbox
            name="show_question_numbers"
            formControlName="show_question_numbers"
            >{{
                'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.SHOW-QUESTION-NUMBERS'
                    | translate
            }}</mat-checkbox
        >
        <mat-checkbox
            name="show_hidden_items"
            formControlName="show_hidden_items"
            >{{
                'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.SHOW-HIDDEN-ITEMS' | translate
            }}</mat-checkbox
        >
        <mat-checkbox
            name="show_points"
            formControlName="show_points"
            >{{
                'SURVEY-EDITOR.ITEMS.RESPONSE-SUMMARY.SHOW-POINTS'
                    | translate
                }}</mat-checkbox
        >
    </div>
    <cb-question-collapsed-panel
        title="{{ 'SURVEY-EDITOR.SHARED.CONDITIONS' | translate }}"
        [tooltip]="'SURVEY-EDITOR.SHARED.TOOLTIPS.CONDITIONS' | translate"
    >
        <cb-groups-expressions-element
            [source]="actionItem"
        ></cb-groups-expressions-element>
    </cb-question-collapsed-panel>
</form>
