<form [formGroup]="form">
    <ng-container formArrayName="properties">
        <div class="cb-change-type-section">
            <cb-item-type [itemType]="profile"></cb-item-type>
        </div>
        <h3>{{ 'SHARED.EDIT' | translate }}</h3>
        <strong>{{
            'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.CONDITIONS' | translate
        }}</strong>
        <br />
        <mat-list *ngIf="profileProperties && questionsList">
            <mat-list-item
                *ngFor="let item of items; let i = index"
                [formGroupName]="i"
            >
                <span>{{
                    getQuestionText(item.controls['source_item_id'].value)
                }}</span>
                &emsp;
                <span>{{ item.controls['property_name'].value }}</span> &emsp;
                <button mat-icon-button (click)="onRemoveOptionClick(i)">
                    <cb-svg-icon class="icon" [name]="'close'"></cb-svg-icon>
                </button>
            </mat-list-item>
        </mat-list>
        <mat-divider></mat-divider>
    </ng-container>
    <strong>{{
        'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.NEW-PROPERTY' | translate
    }}</strong>
    <br />
    <mat-form-field>
        <mat-select
            placeholder="{{
                'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.SOURCE-QUESTION' | translate
            }}"
            [(value)]="selectedQuestion"
            #question
        >
            <mat-option
                *ngFor="let question of questionsList"
                [value]="question.id"
                >{{ question.text }}</mat-option
            >
        </mat-select>
    </mat-form-field>
    <br />
    <mat-form-field>
        <mat-select
            placeholder="{{
                'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.USER-PROFILE' | translate
            }}"
            [(value)]="selectedProperty"
            #userProperty
        >
            <mat-option
                *ngFor="let item of profileProperties"
                [value]="item.name"
                >{{ item.name }}</mat-option
            >
        </mat-select>
    </mat-form-field>
    <br />
    <button
        mat-raised-button
        (click)="onAddPropertyClick(question.value, userProperty.value)"
        [disabled]="!question.value || !userProperty.value"
    >
        {{ 'SHARED.ADD' | translate }}
    </button>
    <br />
    <div class="form-section">
        <h3 class="form-section-title">
            {{ 'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.CONDITIONS' | translate }}
            <cb-help-icon [tooltip]="'SURVEY-EDITOR.SHARED.TOOLTIPS.CONDITIONS' | translate"></cb-help-icon>
        </h3>
        <cb-groups-expressions-element
            [source]="actionItem"
        ></cb-groups-expressions-element>
    </div>
</form>
