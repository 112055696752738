import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { CustomSourceDropdownlistProvider } from '../../../../../infrastructure/providers';
import { SurveyQuestionType } from '../../../../../infrastructure/consts/surveys.consts';
import { SharedService } from '../../../../../infrastructure/services';
import { AutoUnsubscribe } from '../../../../../shared/decorators/autoUnsubscribe.decorator';
import { DrillDownItem, DrillDownLevel } from '../../../../../shared/models/survey-items/question-items/drillDownItem';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'cb-drill-down-item',
    templateUrl: './drill-down-item.component.html',
    styleUrls: ['./drill-down-item.component.scss']
})
@AutoUnsubscribe()
export class DrillDownItemComponent implements OnInit, OnDestroy {
    @Input() questionItem: DrillDownItem;
    @Input() isMatrix: boolean;

    public message: string;
    form: FormGroup;
    itemPosition: string;
    textPosition: string;
    drillDownItemType = SurveyQuestionType.DRILL_DOWN;
    levels: any;
    editedIndex = 0;
    testedValues = [];
    valuePreviews = [];
    maxLevel = 5;
    private componentDestroyed = new Subject();

    constructor(
        private fb: FormBuilder,
        private sharedService: SharedService,
        private translate: TranslateService,
        private cdr: ChangeDetectorRef,
        private customSourceDropdownlistProvider: CustomSourceDropdownlistProvider,
    ) {}

    ngOnInit() {
        this.createFormGroup();
        this.createQuestionItemObject();
    }


    private createFormGroup(): void {
        let isAllQuestionsRequired = false;
        let other;
        this.translate
            .get('SURVEY-EDITOR.SHARED.OTHER')
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe((text: string) => {
                other = text;
            });
        if (!this.questionItem) {
            this.questionItem = <DrillDownItem>{};
        }
        if (!this.questionItem.id) {
            isAllQuestionsRequired = this.sharedService.surveyRespondentSettings.getValue() ?
                this.sharedService.surveyRespondentSettings.getValue().makeQuestionsRequired : false;
        }
        this.form = this.fb.group({
            choice_list_url: new FormControl(this.questionItem.choice_list_url, [Validators.required]),
            levels: this.fb.array([]),
            // behavior
            alias: new FormControl(this.questionItem.alias),
            html_class: new FormControl(this.questionItem.html_class),
            is_required: new FormControl(this.questionItem.is_required || isAllQuestionsRequired),
            is_soft_required: new FormControl(this.questionItem.softRequiredEnabled ? !!this.questionItem.is_soft_required : false),

            // appearance
            question_text_position: new FormControl(
                this.questionItem.question_text_position
            ),
            item_position: new FormControl(this.questionItem.item_position)
        });
        this.initLevels();
        this.itemPosition = this.questionItem.item_position;
        this.textPosition = this.questionItem.question_text_position;
        this.form.get('levels').valueChanges
        .pipe(debounceTime(300), takeUntil(this.componentDestroyed))
        .subscribe((value: DrillDownLevel[]) => {
            let current = this.testedValues.map(i=> JSON.parse(i));
            for (const val of value.sort((a, b)=> a.level-b.level)) {
                if (val.next_level_property) {
                    current = current.map(i=> i[val.next_level_property]).filter(i=> Array.isArray(i) ? i.length >0 : false)[0];
                }
            }
            if (current) {
                this.valuePreviews = current.map(i=> JSON.stringify(i));
            }
            else{
                this.valuePreviews = [];
            }
          });
    }
    private createQuestionItemObject(): void {
        this.updateQuestionItemObject();
        this.form.valueChanges
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(() => {
                this.updateQuestionItemObject();
            });
    }

    private updateQuestionItemObject(): void {
        const control = this.form.controls;
        const questionItem = new DrillDownItem();
        questionItem.item_type = SurveyQuestionType.CUSTOM_SOURCE_DROPDOWNLIST;
        questionItem.choice_list_url = control['choice_list_url'].value;
        questionItem.alias = control['alias'].value;
        questionItem.html_class = control['html_class'].value;
        questionItem.is_required = control['is_required'].value;
        questionItem.is_soft_required = control['is_soft_required'].value;
        questionItem.question_text_position = control['question_text_position'].value;
        questionItem.item_position = control['item_position'].value;
        questionItem.levels = control['levels'].value
        this.sharedService.share('questionItemSettings', questionItem);
    }
    onTextMerge(updatedText, fieldName) {
        const field = this.form.controls[fieldName];
        field.setValue(`${field.value || ''}${updatedText}`);
    }
    public checkEnteredUrl() {
        const url = this.form.controls['choice_list_url'].value;
        if (url) {
            //const valueField = this.form.controls['value_field'].value;
            this.customSourceDropdownlistProvider.getChoices(url, "", i => JSON.stringify(i))
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(
                (items: any) => {
                    this.testedValues = items;
                    this.valuePreviews = items;
                    this.cdr.detectChanges();
                }
            );
        }

    }
    private initLevels() {
        this.sortLevels();
        this.levels = this.form.get('levels')['controls'];

        if (!this.levels.length) {
            this.addLevel();
        }
    }
    public addLevel(): void {
        if (this.reachLevelLimit()) {
            return;
        }
        const index = (<FormArray>this.form.get('levels')).length;
        const control = new FormGroup({
            name_property: new FormControl(null, Validators.required),
            next_level_property: new FormControl(null),
            level: new FormControl(index),
        });
        (<FormArray>this.form.get('levels')).push(control);
        this.normalizeLevelPosition();
        this.editedIndex = (<FormArray>this.form.get('levels')).length - 1;
    }
    private sortLevels(): void {
        if (this.questionItem.levels) {
            this.questionItem.levels.sort(function(a, b) {
                return a.level - b.level;
            });
            this.questionItem.levels.forEach(element => {
                const control = new FormGroup({
                    name_property: new FormControl(element.name_property, Validators.required),
                    next_level_property: new FormControl(element.next_level_property),
                    level: new FormControl(element.level),
                });
                (<FormArray>this.form.get('levels')).push(control);
            });
        }
    }
    private normalizeLevelPosition() {
        let i = 0;
        this.levels.forEach(c => {
            c['controls'].level.setValue(i);
            i++;
        });
    }
    public removeLevel(): void {
        if (this.isMinimalLevel()) {
            return;
        }
        var last = this.levels.length;
        const options = <FormArray>this.form.get('levels');
        options.removeAt(last - 1);
        this.normalizeLevelPosition();
    }
    reachLevelLimit(){
        return (<FormArray>this.form.get('levels')).length >= 5;
    }
    isMinimalLevel(){
        return (<FormArray>this.form.get('levels')).length <= 1;
    }
    ngOnDestroy() { }

}

