<form [formGroup]="form" autocomplete="off">
    <div class="form-section">
        <h3 class="form-section-title">
            {{ 'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.LINK-OPTION' | translate }}
        </h3>
        <mat-radio-group
            formControlName="link_option_value"
            (change)="onLinkOptionChanged($event)"
        >
            <mat-radio-button value="link_to_url">{{
                'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.LINK-TO-URL' | translate
            }}</mat-radio-button>
            <mat-radio-button value="link_to_restart">{{
                'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.LINK-TO-RESTART' | translate
            }}</mat-radio-button>
            <mat-radio-button value="redirect_to_url">{{
                'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.REDIRECT-TO-URL' | translate
            }}</mat-radio-button>
            <mat-radio-button value="restart_survey">{{
                'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.RESTART-SURVEY' | translate
            }}</mat-radio-button>
        </mat-radio-group>
    </div>
    <div class="form-section">
        <h3 class="form-section-title">
            {{ 'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.LINK-OPTION' | translate }}
        </h3>
        <mat-radio-group formControlName="window_option_value">
            <mat-radio-button [value]="false">{{
                'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.SAME-WINDOW' | translate
            }}</mat-radio-button>
            <mat-radio-button [value]="true">{{
                'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.NEW-WINDOW' | translate
            }}</mat-radio-button>
        </mat-radio-group>
    </div>
    <div class="form-section">
        <h3 class="form-section-title">
            {{ 'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.OPTIONS' | translate }}
        </h3>
        <div>
            <mat-form-field *ngIf="showRedirectInput()">
                <input
                    matInput
                    type="number"
                    formControlName="auto_redirect_delay"
                    placeholder="{{
                        'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.REDIRECT-DELAY'
                            | translate
                    }}"
                />
            </mat-form-field>
            <mat-form-field>
                <input
                    matInput
                    formControlName="url"
                    placeholder="{{
                        'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.LINK' | translate
                    }}"
                />
            </mat-form-field>

            <cb-merge-text-list
                [surveyId]="actionItem.surveyId"
                [pageId]="actionItem.page_id"
                [text]="actionItem.url"
                [cursorPos]="redirect_cursor"
                (updateText)="setFormVontrolValue($event, 'url')"
            >
            </cb-merge-text-list>
            <mat-form-field>
                <input
                    matInput
                    formControlName="text"
                    placeholder="{{
                        'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.HYPERLINK' | translate
                    }}"
                />
            </mat-form-field>
            <cb-merge-text-list
                [surveyId]="actionItem.surveyId"
                [pageId]="actionItem.page_id"
                [text]="actionItem.text"
                [cursorPos]="redirect_cursor"
                (updateText)="setFormVontrolValue($event, 'text')"
            >
            </cb-merge-text-list>
        </div>
    </div>
    <div class="form-section">
        <h3 class="form-section-title">
            {{ 'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.APPEARANCE' | translate }}
        </h3>
        <div class="">
            <mat-form-field>
                <input matInput type="text" 
                        formControlName="font_color"
                        placeholder="{{
                            'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.FONT-COLOR' | translate
                        }}" />
            </mat-form-field>
            <input
                [(colorPicker)]="color"
                [style.background]="color"
                (colorPickerChange)="setFormVontrolValue($event, 'font_color')"
            />
            <mat-form-field>
                <input
                    matInput
                    formControlName="font_size"
                    placeholder="{{
                        'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.FONT-SIZE' | translate
                    }}"
                />
            </mat-form-field>
        </div>
        <div class="form-group-position">
            <cb-item-position
                class="position-control-group"
                [selectedOption]="itemPosition"
                (selected)="itemPositionSelected($event)"
            ></cb-item-position>
        </div>
    </div>
    <div class="form-section">
        <h3 class="form-section-title">
            {{ 'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.CONDITIONS' | translate }}
            <cb-help-icon [tooltip]="'SURVEY-EDITOR.SHARED.TOOLTIPS.CONDITIONS' | translate"></cb-help-icon>
        </h3>
        <cb-groups-expressions-element
            [source]="actionItem"
        ></cb-groups-expressions-element>
    </div>
</form>
