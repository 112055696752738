import { OnInit, Component, Input, OnDestroy } from '@angular/core';
import { SharedService } from '../../../../../infrastructure/services';
import {
    SurveyQuestionType,
    SurveysPagesActions
} from '../../../../../infrastructure/consts/surveys.consts';
import { MatrixItem } from '../../../../../shared/models/survey-items/question-items/matrix';
import * as _ from 'lodash';
import { composeExpressionsCondition } from '../../../../../infrastructure/helpers/surveys-expressions.helper';
import { ValidationObject } from '../../../../../infrastructure/models';
import { SurveyEditorValidator } from '../../../../../infrastructure/validators/survey-editor.validators';
import { Subscription } from 'rxjs';
import { PageExpressionsGroupsType } from '../../../../../infrastructure/consts/surveys.consts';
import { RatingScaleItem } from '../../../../..//shared/models/survey-items/question-items/ratingScaleItem';

@Component({
    selector: 'cb-matrix-item-dialog',
    templateUrl: './matrix-item-dialog.component.html',
    styleUrls: ['./matrix-item-dialog.component.scss']
})
export class MatrixItemDialogComponent implements OnInit, OnDestroy {
    @Input() options: any;
    public matrixItemData: any = {};
    public matrixItemSettings: any = {};
    public columns: any[];
    public rows: any[];
    public editMode: boolean;
    public defaultQuestionType = 'RowTexts';
    public validationMessage = null;
    public branching_rules = [];
    public isSaveButtonClicked = false;
    public validationMessages: ValidationObject[] = [];
    subscriptions: { [key: string]: Subscription } = {};
    isSurveyTemplate: boolean;
    constructor(private sharedService: SharedService) {}

    ngOnInit() {
        this.initSubscriptions();
        this.setOptions();
    }

    private setOptions() {
        if (this.options) {
            this.isSurveyTemplate = this.options.isSurveyTemplate;
            this.editMode = !!(
                this.options.itemData && this.options.itemData.id
            );
            if (this.editMode) {
                this.matrixItemData = this.options.itemData;
                this.columns = this.options.itemData.columns;
            }

            this.matrixItemData.surveyId = this.options.surveyId;
            this.matrixItemData.page_id = this.options.pageId;
            this.matrixItemData.softRequiredEnabled = this.options.softRequiredEnabled;
            this.matrixItemData.item_type = SurveyQuestionType.MATRIX;
        }
    }

    subscribeTo(subscriptionChannel: string, callback: Function) {
        this.subscriptions[subscriptionChannel] = this.sharedService
            .getData(subscriptionChannel)
            .subscribe((data) => {
                callback(data);
            });
    }

    private initSubscriptions(): void {
        this.subscribeTo('matrixItem', item => {
            this.matrixItemData = _.assign(this.matrixItemData, item.data);
        });
        this.subscribeTo('questionItemSettings', settings => {
            if (settings.data.item_type === SurveyQuestionType.MATRIX) {
                this.matrixItemSettings = settings;
            }
        });
        this.subscribeTo('matrixColumns', matrixColumns => {
            this.columns = matrixColumns.data;
            this.removeValidationMessage();
        });

        this.subscribeTo('matrixRows', rows => {
            this.rows = rows.data.options;
            this.removeValidationMessage();
        });
        this.subscribeTo(PageExpressionsGroupsType.EXPRESSIONS_GROUP, gr => {
            if (gr.data.id === this.matrixItemData.id) {
                this.branching_rules = gr.data.branching_rules;
            }
        });
    }

    private createMatrixItem() {
        const questionItem = new MatrixItem();
        this.getMatrixSettings(questionItem);

        questionItem.include_condition = composeExpressionsCondition(
            this.branching_rules,
            true
        );
        this.clearColumnsData();
        questionItem.columns = this.columns;
        questionItem.rows = this.getRows();
        questionItem.page_id = this.options.pageId;
        questionItem.item_type = SurveyQuestionType.MATRIX;
        questionItem.question_text = this.matrixItemData.question_text;
        questionItem.subtext = this.matrixItemData.subtext;
        return questionItem;
    }

    private clearColumnsData() {
        this.columns.forEach(item => {
            if (item.column_type === 'Question' &&  item.prototype_item.item_type === 'RadioButtonScale') {
                delete (<RatingScaleItem>item.prototype_item).end_value;
                delete (<RatingScaleItem>item.prototype_item).start_value;
                if ((<RatingScaleItem>item.prototype_item).enable_not_applicable) {
                    item.prototype_item.choices = this.getUserChoices(item.prototype_item.choices);
                }
            }
        });
    }

    private getUserChoices(choices): any[] {
        const filteredChoices = _.filter(
            choices,
            c => !c.is_other
        );
        return filteredChoices;
    }

    private getRows() {
        return this.rows ? this.rows.filter(c => c.text) : [];
    }

    private getColumns() {
        return this.columns
            ? this.columns.filter(
                  c => c.column_type !== this.defaultQuestionType
              )
            : [];
    }

    private isColumnOrRowListEmpty() {
        if (_.isEmpty(this.getColumns()) || _.isEmpty(this.getRows())) {
            this.validationMessage =
                'You should choose at least 1 column and 1 row';
            return true;
        }
    }

    private getMatrixSettings(questionItem: MatrixItem) {
        if (this.matrixItemSettings && this.matrixItemSettings.data) {
            Object.keys(this.matrixItemSettings.data).forEach(key => {
                questionItem[key] = this.matrixItemSettings.data[key];
            });
        }
    }

    onMatrixItemSaveClick() {
        const matrix = this.createMatrixItem();
        if (this.isColumnOrRowListEmpty()) {
            return;
        }
        if (!this.isValid(matrix)) {
            return;
        }
        this.isSaveButtonClicked = true;
        if (this.editMode) {
            matrix.id = this.options.itemData.id;
        } else {
            matrix.position = this.options.numberOfQuestions + 1;
        }

        const messageName = this.editMode
            ? SurveysPagesActions.UPDATE_SURVEY_ITEM
            : SurveysPagesActions.ADD_SURVEY_ITEM;
        this.sharedService.share(messageName, matrix);
    }

    public cancel() {
        this.sharedService.share(SurveysPagesActions.CANCEL_SURVEY_ITEM, null);
    }

    private removeValidationMessage() {
        if (
            this.validationMessage &&
            this.columns.length > 0 &&
            this.rows.length > 0
        ) {
            this.validationMessage = null;
        }
    }

    private isValid(itemData) {
        const errors = SurveyEditorValidator.validate(itemData);
        this.validationMessages = _.uniqBy(errors, 'validationMessage');
        return errors.length === 0;
    }

    ngOnDestroy(): void {
        _.forEach(this.subscriptions, subscr => subscr.unsubscribe());
    }
}
