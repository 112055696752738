<div class="cb-section-divider form-section-divider"></div>
<cb-question-collapsed-panel
    title="{{ 'SURVEY-EDITOR.ITEMS.MATRIX.COLUMN-OPTIONS' | translate }}"
>
    <form [formGroup]="form">
        <div class="form-fields-group">
            <div class="cb-column-width-control cb-form-field width-auto">
                <span>{{
                    'SURVEY-EDITOR.ITEMS.MATRIX.COLUMN-WIDTH' | translate
                }}</span>
                <mat-form-field floatLabel="never" class="column-width-input">
                    <input
                        matInput
                        autocomplete="off"
                        formControlName="width"
                        type="number"
                    />
                </mat-form-field>
                <span>px</span>
            </div>
            <mat-checkbox
                class="cb-form-field width-auto"
                formControlName="unique_answer"
            >
                {{ 'SURVEY-EDITOR.ITEMS.MATRIX.UNIQUE-ANSWER' | translate }}
            </mat-checkbox>
        </div>
    </form>
</cb-question-collapsed-panel>
