<form [formGroup]="form" autocomplete="off">
    <ng-container formArrayName="messages">
        <div class="form-section">
            <cb-question-item-title
                title="{{ 'SURVEY-EDITOR.SHARED.MESSAGES' | translate }}"
            ></cb-question-item-title>
            <mat-list>
                <mat-list-item
                    class="scoring-message-item"
                    *ngFor="let item of items; let i = index"
                    [formGroupName]="i"
                >
                    <span>{{ item.controls['low_score'].value }}&ensp;-&ensp;</span>
                    <span>{{ item.controls['high_score'].value }}&emsp;</span>
                    <span [innerHTML]="item.controls['text'].value | keepHtml"></span>
                    <button mat-icon-button (click)="onRemoveOptionClick(i)">
                        <cb-svg-icon
                            class="icon"
                            [name]="'close'"
                        ></cb-svg-icon>
                    </button>
                </mat-list-item>
            </mat-list>
            <mat-divider *ngIf="items['controls']?.length"></mat-divider>
            <div>
                <mat-form-field class="cb-form-field">
                    <input
                        matInput
                        #minScore
                        type="number"
                        autocomplete="off"
                        placeholder="{{
                            'SURVEY-EDITOR.ITEMS.SCORING_MESSAGE.MIN_SCORE'
                                | translate
                        }}"
                    />
                </mat-form-field>
                <br />
                <mat-form-field class="cb-form-field">
                    <input
                        matInput
                        #maxScore
                        type="number"
                        autocomplete="off"
                        placeholder="{{
                            'SURVEY-EDITOR.ITEMS.SCORING_MESSAGE.MAX_SCORE'
                                | translate
                        }}"
                    />
                </mat-form-field>
                <br />
                <div>
                    <cb-tiny-rich-editor
                        placeholder="{{
                            'SURVEY-EDITOR.ITEMS.SCORING_MESSAGE.MESSAGE' | translate
                        }}"
                        [(ngModel)]="scoringMessageHtml"
                        [ngModelOptions]="{ standalone: true }"
                        #messageEditor
                    ></cb-tiny-rich-editor>
                    <cb-merge-text-list
                        [surveyId]="questionItem.surveyId"
                        [pageId]="questionItem.page_id"
                        [returnCode]="true"
                        (updateText)="onMergeToMessageControl($event)"
                    >
                    </cb-merge-text-list>
                </div>

                <button
                    *ngIf="minScore.value && maxScore.value && scoringMessageHtml"
                    (click)="
                        onAddMessageClick(
                            minScore.value,
                            maxScore.value,
                            scoringMessageHtml
                        )
                    "
                >
                    {{ 'SURVEY-EDITOR.SHARED.ADD-MESSAGE' | translate }}
                </button>
            </div>
        </div>
    </ng-container>
    <cb-behavior-options [parentForm]="form"  [softRequiredEnabled]="false"  [hideRequired]="true" [expanded]="true">
        <mat-form-field>
            <mat-select
                placeholder="{{
                    'SURVEY-EDITOR.SHARED.SCORE-SCOPE' | translate
                }}"
                formControlName="score_scope_page_id"
            >
                <mat-option value="survey">{{
                    'SURVEY-EDITOR.SHARED.SURVEY' | translate
                }}</mat-option>
                <mat-option
                    *ngFor="let item of questionItem.pageTitles; let i = index"
                    [value]="item.pageId"
                    >{{ item.title !== '' ? item.title : ('SHARED.PAGE' | translate) + " " + (i + 1) }}</mat-option
                >
            </mat-select>
        </mat-form-field>
    </cb-behavior-options>
    <div class="form-section border-top">
        <h3 class="form-section-title">
            {{ 'SURVEY-EDITOR.SHARED.APPEARANCE.APPEARANCE' | translate }}
        </h3>
        <mat-form-field>
            <input matInput type="text"
                    formControlName="font_color"
                    placeholder="{{
                        'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.FONT-COLOR' | translate
                    }}" />
        </mat-form-field>
        <br />
        <input
            [(colorPicker)]="color"
            [style.background]="color"
            (colorPickerChange)="setFormVontrolValue($event, 'font_color')"
        />
        <br />
        <mat-form-field>
            <input
                matInput
                formControlName="font_size"
                placeholder="{{ 'STYLED.FONT-SIZE' | translate }}"
            />
        </mat-form-field>
        <br />
        <div class="form-group-position">
            <cb-item-position
                class="position-control-group"
                [selectedOption]="itemPosition"
                (selected)="itemPositionSelected($event)"
            ></cb-item-position>
        </div>
        <br />
    </div>
    <div class="form-section">
        <h3 class="form-section-title">
            {{ 'SURVEY-EDITOR.SHARED.CONDITIONS' | translate }}
            <cb-help-icon [tooltip]="'SURVEY-EDITOR.SHARED.TOOLTIPS.CONDITIONS' | translate"></cb-help-icon>
        </h3>
        <cb-groups-expressions-element
            [source]="questionItem"
        ></cb-groups-expressions-element>
    </div>
</form>
