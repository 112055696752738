<form [formGroup]="form" autocomplete="off">
    <div class="form-section">
        <cb-question-item-title
            title="SURVEY-EDITOR.SHARED.OPTIONS"
        ></cb-question-item-title>
        <mat-form-field>
            <mat-select
                placeholder="{{
                    'SURVEY-EDITOR.ITEMS.RESPONSE-SUMMARY.DISPLAY' | translate
                }}"
                formControlName="display"
                [value]="getDisplayType()"
                #displayType
            >
                <mat-option value="displayInline">{{
                    'SURVEY-EDITOR.ITEMS.RESPONSE-SUMMARY.DISPLAY-RESPONSE'
                        | translate
                }}</mat-option>
                <mat-option value="link">{{
                    'SURVEY-EDITOR.ITEMS.RESPONSE-SUMMARY.LINK-TO-VIEW'
                        | translate
                }}</mat-option>
            </mat-select>
        </mat-form-field>
        <br />
        <mat-form-field
            *ngIf="displayType.value === linkDisplayTypeValue"
            class="cb-form-field"
        >
            <input
                matInput
                placeholder="{{
                    'SURVEY-EDITOR.ITEMS.RESPONSE-SUMMARY.LINK-TEXT' | translate
                }}"
                formControlName="link_text"
            />
        </mat-form-field>
        <br />
        <mat-checkbox
            class="cb-form-field"
            formControlName="include_response_details"
            >{{
                'SURVEY-EDITOR.ITEMS.RESPONSE-SUMMARY.INCLUDE-RESPONSE-DETAILS'
                    | translate
            }}</mat-checkbox
        >
        <br />
        <mat-checkbox
            class="cb-form-field"
            formControlName="show_page_numbers"
            >{{
                'SURVEY-EDITOR.ITEMS.RESPONSE-SUMMARY.SHOW-PAGE-NUMBERS'
                    | translate
            }}</mat-checkbox
        >
        <br />
        <mat-checkbox
            class="cb-form-field"
            formControlName="show_question_numbers"
            >{{
                'SURVEY-EDITOR.ITEMS.RESPONSE-SUMMARY.SHOW-QUESTION-NUMBERS'
                    | translate
            }}</mat-checkbox
        >
        <br />
        <mat-checkbox
            class="cb-form-field"
            formControlName="include_message_items"
            >{{
                'SURVEY-EDITOR.ITEMS.RESPONSE-SUMMARY.INCLUDE-MESSAGE'
                    | translate
            }}</mat-checkbox
        >
        <br/>
        <mat-checkbox
            class="cb-form-field"
            formControlName="show_hidden_items"
        >{{
            'SURVEY-EDITOR.ITEMS.RESPONSE-SUMMARY.SHOW-HIDDEN-ITEMS'
                | translate
            }}</mat-checkbox
        >
        <br/>
        <mat-checkbox
            class="cb-form-field"
            formControlName="show_print_button"
        >{{
            'SURVEY-EDITOR.ITEMS.RESPONSE-SUMMARY.SHOW-PRINT-BUTTON'
                | translate
            }}</mat-checkbox
        >
        <br/>
        <mat-checkbox
            class="cb-form-field"
            formControlName="show_points"
        >{{
            'SURVEY-EDITOR.ITEMS.RESPONSE-SUMMARY.SHOW-POINTS'
                | translate
            }}</mat-checkbox
        >
        <br/>        
    </div>
    <div class="form-section">
        <h3 class="form-section-title">
            {{ 'SURVEY-EDITOR.SHARED.APPEARANCE.APPEARANCE' | translate }}
        </h3>
        <div class="form-group-position">
            <cb-item-position
                class="position-control-group"
                [selectedOption]="itemPosition"
                (selected)="itemPositionSelected($event)"
            ></cb-item-position>
        </div>
        <br />
        <mat-form-field class="cb-form-field">
            <input
                matInput
                maxlength="3"
                (keypress)="onNumericKeyUp($event)"
                formControlName="width"
                placeholder="{{
                    'SURVEY-EDITOR.ITEMS.RESPONSE-SUMMARY.WIDTH' | translate
                }}"
            />
        </mat-form-field>
        <br />
        <mat-form-field class="cb-form-field">
            <input
                matInput
                maxlength="3"
                (keypress)="onNumericKeyUp($event)"
                formControlName="height"
                placeholder="{{
                    'SURVEY-EDITOR.ITEMS.RESPONSE-SUMMARY.HEIGHT' | translate
                }}"
            />
        </mat-form-field>
    </div>
    <div class="form-section">
        <h3 class="form-section-title">
            {{ 'SURVEY-EDITOR.SHARED.CONDITIONS' | translate }}
            <cb-help-icon [tooltip]="'SURVEY-EDITOR.SHARED.TOOLTIPS.CONDITIONS' | translate"></cb-help-icon>
        </h3>
        <cb-groups-expressions-element
            [source]="questionItem"
        ></cb-groups-expressions-element>
    </div>
</form>
