<mat-dialog-content>
    <h3>{{ 'SURVEY-EDITOR.SHARED.CONDITIONS' | translate }}</h3>
    <cb-groups-expressions-element
        [source]="row"
    ></cb-groups-expressions-element>
</mat-dialog-content>
<div *ngFor="let message of validationMessages" class="invalid">
    <strong>{{ message.validationMessage }}</strong>
</div>
<mat-dialog-actions class="cb-right">
    <button mat-button type="button" mat-dialog-close>
        {{ 'SHARED.CANCEL' | translate }}
    </button>
    <button mat-button type="button" (click)="onSave()">
        {{ 'SURVEY-EDITOR.SHARED.ADD-CONDITION' | translate }}
    </button>
</mat-dialog-actions>
