import { Input, OnDestroy, OnInit, Directive } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';

@Directive()
export abstract class MultipleChoicesItemComponent implements OnInit, OnDestroy {
    @Input() resetChoicesEvent: Observable<void>;
    @Input() isSurveyTemplate: boolean;

    public resetChoicesSubject: Subject<void> = new Subject<void>();
    
    private resetChoicesSubscription: Subscription;

    protected constructor() {
    }

    ngOnInit() {
        if (this.resetChoicesEvent) {
            this.resetChoicesSubscription = this.resetChoicesEvent.subscribe(() => this.resetChoices());
        }
    }

    private resetChoices() {
        this.resetChoicesSubject.next();
    }

    ngOnDestroy() {
        if (this.resetChoicesSubscription) {
            this.resetChoicesSubscription.unsubscribe();
        }
    }
}
