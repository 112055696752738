import { Action } from '@ngrx/store';

import { PagedList, PagingInfo, SurveyListItem } from '../../../infrastructure/models';
import { Survey, SurveyListGetData, FavoriteChange } from '../../models';

export const RELOAD_SURVEY_LIST = '[Survey] Reload Survey List';
export const LOAD_MORE_SURVEY_LIST = '[Survey] More Survey List';
export const RELOAD_FAVORITE_SURVEYS_LIST = '[Survey] Reload Survey Favorites';
export const RELOAD_SURVEY_LIST_SUCCESS = '[Survey] Reload Survey List Success';
export const LOAD_MORE_SURVEY_LIST_SUCCESS = '[Survey] More Survey List Success';
export const RELOAD_FAVORITE_SURVEYS_LIST_SUCCESS = '[Survey] Reload Survey Favorites Success';
export const SURVEY_CHANGE_FAVORITE = '[Survey] Survey Change Favorite';
export const SURVEY_FAILED = '[Survey] Survey Failed';
export const SELECT_SURVEY = '[Survey] Select Survey';

/**
 export const ADD_SURVEY = '[Survey] Add Survey';
export const ADD_SURVEY_SUCCESS = '[Survey] Add Survey Success';
export const GET_SURVEYS_LIST = '[Survey] Get Surveys List';
xport const GET_SURVEYS_LIST_SUCCESS = '[Survey] Get Surveys List Success';
export const SURVEY_FAILED = '[Survey] Survey Failed';
export const SELECT_SURVEY = '[Survey] Select Survey';
 */

export class ReloadSurveyListAction implements Action {
    readonly type = RELOAD_SURVEY_LIST;

    constructor(public payload: SurveyListGetData) {}
}

export class MoreSurveyListAction implements Action {
    readonly type = LOAD_MORE_SURVEY_LIST;

    constructor() {}
}

export class ReloadSurveyFavoritesAction implements Action {
    readonly type = RELOAD_FAVORITE_SURVEYS_LIST;

    constructor(public payload: SurveyListGetData) {}
}

export class ReloadSurveyListSuccessAction implements Action {
    readonly type = RELOAD_SURVEY_LIST_SUCCESS;

    constructor(public payload: PagedList<SurveyListItem>) {}
}

export class MoreSurveyListSuccessAction implements Action {
    readonly type = LOAD_MORE_SURVEY_LIST_SUCCESS;

    constructor(public payload: PagedList<SurveyListItem>) {}
}

export class ReloadSurveyFavoritesSuccessAction implements Action {
    readonly type = RELOAD_FAVORITE_SURVEYS_LIST_SUCCESS;

    constructor(public payload: PagedList<SurveyListItem>) {}
}

export class SelectSurveyAction implements Action {
    readonly type = SELECT_SURVEY;

    constructor(public payload: number[]) {}
}

export class SurveyFailedAction implements Action {
    readonly type = SURVEY_FAILED;

    constructor() {}
}

export class ChangeFavoriteAction implements Action {
    readonly type = SURVEY_CHANGE_FAVORITE;

    constructor(public payload: FavoriteChange) {}
}

export type Actions =
    | ReloadSurveyListAction
    | MoreSurveyListAction
    | ReloadSurveyFavoritesAction
    | ReloadSurveyListSuccessAction
    | MoreSurveyListSuccessAction
    | ReloadSurveyFavoritesSuccessAction
    | SelectSurveyAction
    | SurveyFailedAction
    | ChangeFavoriteAction;
