import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { SurveyQuestionOptions } from '../../../app-admin/models/page-items.model';
import { SurveyPage } from '../../../app-admin/models';
import { SurveysPagesActions } from '../../../infrastructure/consts/surveys.consts';
import { SharedService } from '../../../infrastructure/services';

@Component({
    selector: 'cb-inline-dialog',
    templateUrl: './inline-dialog.component.html',
    styleUrls: ['./inline-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InlineDialogComponent {
    @Input() component: any;
    @Input() page: SurveyPage;
    @Input() item: any;
    @Input() options: SurveyQuestionOptions;
    @Input() isAddingItemProcess: boolean;
    @Input() softRequiredEnabled: boolean;

    constructor(private sharedService: SharedService) {}

    public isEditModeActive(): boolean {
        if (!this.options) {
            return false;
        }
        const activeItemID = this.options.itemData
            ? this.options.itemData.id
            : 0;
        const activePageID = this.options.pageId;
        return (
            this.isAddingItemProcess &&
            activePageID === this.page.id &&
            activeItemID === this.item.id
        );
    }

    public outsideClick() {
        if (this.options && this.options.matrix) {
            this.sharedService.share(
                SurveysPagesActions.CANCEL_MATRIX_ITEM,
                {}
            );
        } else if (this.options && this.options.contactFormFieldSysName) {
            this.sharedService.share(
                SurveysPagesActions.CANCEL_CONTACT_FORM_FIELD,
                {}
            );
        } else {
            this.sharedService.share(
                SurveysPagesActions.CANCEL_SURVEY_ITEM,
                {}
            );
        }
    }
}
