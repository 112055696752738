import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SharedService } from '../../../../../infrastructure/services';
import { SurveyQuestionType } from '../../../../../infrastructure/consts/surveys.consts';
import { CheckboxesItem } from '../../../../../shared/models/survey-items/question-items/checkboxesItem';
import { SurveyEditorValidator } from '../../../../../infrastructure/validators/survey-editor.validators';
import { TranslateService } from '@ngx-translate/core';
import { isHTML } from '../../../../../infrastructure/helpers/is-html.helper';
import { Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../../../shared/decorators/autoUnsubscribe.decorator';
import { takeUntil } from 'rxjs/operators';
import { MultipleChoicesItemComponent } from '../multiple-choices-item.component';

@Component({
    selector: 'cb-checkboxes-item',
    templateUrl: './checkboxes-item.component.html',
    styleUrls: ['./checkboxes-item.component.scss']
})
@AutoUnsubscribe()
export class CheckboxesItemComponent extends MultipleChoicesItemComponent implements OnInit, OnDestroy {
    @Input() questionItem: CheckboxesItem;
    @Input() isMatrix: boolean;

    form: FormGroup;
    checkBoxes = SurveyQuestionType.CHECKBOXES;
    itemPosition: string;
    textPosition: string;
    private componentDestroyed = new Subject();
    otherValue = '';
    editOther: boolean;

    constructor(
        private fb: FormBuilder,
        private sharedService: SharedService,
        private translate: TranslateService,
        private cdr: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.createFormGroup();
        this.createQuestionItemObject();
    }

    itemPositionSelected(value): void {
        this.itemPosition = value;
        this.form.controls['item_position'].setValue(value);
    }

    labelPositionSelected(value): void {
        this.textPosition = value;
        this.form.controls['question_text_position'].setValue(value);
    }

    private createFormGroup(): void {
        let isAllQuestionsRequired = false;
        let other;
        this.translate
            .get('SURVEY-EDITOR.SHARED.OTHER')
            .subscribe((text: string) => {
                other = text;
            });
        let noneValue;
        this.translate
            .get('SURVEY-EDITOR.SHARED.NONE-VALUE')
            .subscribe((text: string) => {
                noneValue = text;
            });

        if (!this.questionItem) {
            this.questionItem = <CheckboxesItem>{};
        }
        if (!this.questionItem.id) {
            isAllQuestionsRequired = this.sharedService.surveyRespondentSettings.getValue() ?
            this.sharedService.surveyRespondentSettings.getValue().makeQuestionsRequired : false;
        }
        this.form = this.fb.group({
            alias: new FormControl(this.questionItem.alias),
            is_required: new FormControl(this.questionItem.is_required || isAllQuestionsRequired),
            is_soft_required: new FormControl(this.questionItem.softRequiredEnabled ? !!this.questionItem.is_soft_required : false),
            item_position: new FormControl(this.questionItem.item_position),
            question_text_position: new FormControl(
                this.questionItem.question_text_position
            ),
            html_class: new FormControl(this.questionItem.html_class),
            width: new FormControl(this.questionItem.width),
            answer_format: new FormControl(this.questionItem.answer_format),
            select_min_limit: new FormControl(
                this.questionItem.select_min_limit ? this.questionItem.select_min_limit :
                isAllQuestionsRequired ? 1 : null
            ),
            select_max_limit: new FormControl(
                this.questionItem.select_max_limit
            ),
            randomize: new FormControl(!!this.questionItem.randomize),
            allow_other: new FormControl(!!this.questionItem.allow_other),
            other_value: new FormControl(
                this.questionItem.other_value || other
            ),
            none_value: new FormControl(
                this.questionItem.none_value || noneValue
            ),
            allow_none_of_above: new FormControl(
                !!this.questionItem.allow_none_of_above
            ),
            show_number_labels: new FormControl(
                !!this.questionItem.show_number_labels
            ),
            layout: new FormControl(
                this.questionItem.layout ? this.questionItem.layout : 'Vertical'
            ),
            columns: new FormControl(this.questionItem.columns || 1)
        });
        this.otherValue = this.questionItem.other_value;
        this.form['controls'][
            'other_value'
        ].valueChanges.subscribe(
            text => {
                this.otherValue = text;
                this.cdr.detectChanges();
            }
        );
        this.itemPosition = this.questionItem.item_position;
        this.textPosition = this.questionItem.question_text_position;
    }

    // todo this is to avoid the defaults not getting sent to the shared service, should find a better way of doing this.
    private populateDefaults() {
        this.form.controls['layout'].setValue(
            this.questionItem.layout ? this.questionItem.layout : 'Vertical'
        );
    }

    private createQuestionItemObject(): void {
        this.updateQuestionItemObject();
        this.form.valueChanges
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(() => {
                this.updateQuestionItemObject();
            });
    }

    private updateQuestionItemObject() {
        const control = this.form.controls;
        const questionItem = new CheckboxesItem();
        questionItem.item_type = SurveyQuestionType.CHECKBOXES;
        questionItem.alias = control['alias'].value;
        questionItem.html_class = control['html_class'].value;
        questionItem.select_min_limit = control['select_min_limit'].value;
        questionItem.select_max_limit = control['select_max_limit'].value;
        questionItem.randomize = control['randomize'].value;
        questionItem.allow_other = control['allow_other'].value;
        questionItem.other_value = control['other_value'].value;
        questionItem.none_value = control['none_value'].value;
        questionItem.allow_none_of_above = control['allow_none_of_above'].value;
        questionItem.question_text_position = control['question_text_position'].value;
        questionItem.item_position = control['item_position'].value;
        questionItem.layout = control['layout'].value;
        questionItem.columns = control['columns'].value;
        questionItem.show_number_labels = control['show_number_labels'].value;
        questionItem.is_required = control['is_required'].value;
        questionItem.is_soft_required = control['is_soft_required'].value;
        questionItem.width = control['width'].value;
        this.sharedService.share('questionItemSettings', questionItem);
    }

    public onNumericKeyUp(event: any) {
        return SurveyEditorValidator.validateNumericInput(event);
    }

    public onMinSelectChange(event) {
        if (+event.target.value > 0) {
            this.form.controls['is_required'].setValue(true);
        } else {
            this.form.controls['is_required'].setValue(false);
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    editItem(ref) {
        if (isHTML(this.otherValue)) {
            ref.onOpenHtmlEditorClick();
        } else {
            this.editOther = true;
        }
    }
}
