<div 
    class="d-flex align-items-center" 
    *ngIf="itemType"
    matTooltip="
            {{
                getTooltipTextKey(itemType) | translate
            }}"
>
    <cb-svg-icon
        class="icon"
        name="{{ getSvgIconName(itemType) }}"
    ></cb-svg-icon>
    <span class="menu-item-text">{{
        questionView[itemType].title | translate
    }}</span>
</div>
