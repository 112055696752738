import {
    Component,
    ChangeDetectionStrategy,
    Input
} from '@angular/core';
import {
SurveyQuestionType,
SurveyDisplayType,
SurveyActionType,
SurveyReportType
} from '../../../../infrastructure/consts/surveys.consts';

@Component({
    selector: 'cb-survey-item-preview',
    templateUrl: './survey-item-preview.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class SurveyItemPreviewComponent {
    @Input() item;
    public actionType = SurveyActionType;
    public questionType = SurveyQuestionType;
    public reportType = SurveyReportType;
    public displayType = SurveyDisplayType;
}