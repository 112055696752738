import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { SharedService } from '../../../../../infrastructure/services';
import { EmailResponseItem } from '../../../../../shared/models/survey-items/action-items/emailResponseItem';
import { StyleTemplate } from '../../../../../infrastructure/models/style-template.model';
import { StyleTemplatesProvider } from '../../../../../infrastructure/providers/style-templates.provider';
import { TinyRichEditorComponent } from '../../../../../shared/components/rich-editor/tiny-rich-editor.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../../../shared/decorators/autoUnsubscribe.decorator';


@Component({
    selector: 'cb-email-response-item',
    templateUrl: './email-response-item.component.html',
    styleUrls: ['./email-response-item.component.scss']
})
@AutoUnsubscribe()
export class EmailResponseItemComponent implements OnInit, OnDestroy {
    @Input() actionItem: EmailResponseItem;
    @ViewChild('messageBodyEditor') private messageBodyEditor: TinyRichEditorComponent;

    form: FormGroup;
    messageFormat: string;
    editorText = '';
    editorCursorPos: number;
    styleTemplateList;
    private componentDestroyed = new Subject();

    constructor(
        private fb: FormBuilder,
        private sharedService: SharedService,
        private styleProvider: StyleTemplatesProvider
    ) {}

    ngOnInit() {
        this.messageFormat = 'Html';
        this.createForm();
        this.createActionItemObject();
    }

    onMergeText(mergeCode, control) {
        const field = this.form.controls[control];
        const newValue = field.value ? field.value + ' ' + mergeCode : mergeCode;
        field.setValue(newValue);
    }

    onBodyMerge(mergeCode: string) {
        this.messageBodyEditor.insertMergeCode(mergeCode);
    }

    private createForm() {
        if (!this.actionItem) {
            this.actionItem = <EmailResponseItem>{};
        }

        this.form = this.fb.group({
            from: new FormControl(this.actionItem.from),
            to: new FormControl(this.actionItem.to),
            bcc: new FormControl(this.actionItem.bcc),
            subject: new FormControl(this.actionItem.subject),
            body: new FormControl(this.actionItem.body),
            format: new FormControl(this.actionItem.format),
            send_once: new FormControl(!!this.actionItem.send_once),
            style_template_id: new FormControl(this.actionItem.style_template_id),
            include_response_details: new FormControl(!!this.actionItem.include_response_details),
            include_message_items: new FormControl(!!this.actionItem.include_message_items),
            show_page_numbers: new FormControl(!!this.actionItem.show_page_numbers),
            show_question_numbers: new FormControl(!!this.actionItem.show_question_numbers),
            show_hidden_items: new FormControl(!!this.actionItem.show_hidden_items),
            show_points: new FormControl(!!this.actionItem.show_points)
        });
    }

    private createActionItemObject(): void {
        const control = this.form.controls;
        this.form.valueChanges
            .pipe(
                takeUntil(this.componentDestroyed)
            )
            .subscribe(s => {
            this.actionItem = { ...this.actionItem, ...s };
            this.sharedService.share('actionItemSettings', this.actionItem);
        });
    }

    ngOnDestroy() {}
}
