<form [formGroup]="form" autocomplete="off">
    <div class="form-section">
        <cb-question-item-title></cb-question-item-title>
        <div class="cb-change-type-section">
            <cb-item-type [itemType]="drillDownItemType"></cb-item-type>
        </div>
        <cb-question-and-subtext [questionItem]="questionItem"></cb-question-and-subtext>
    </div>
    <div class="form-section">
        <cb-question-collapsed-panel title="{{
                'SURVEY-EDITOR.SHARED.CHOICES' | translate
            }}">
            <div class="url-wrapper">
                <mat-form-field>
                    <input matInput placeholder="{{
                        'SURVEY-EDITOR.ITEMS.CUSTOM-DROPDOWN.CHOICE-LIST-URL'
                            | translate
                    }}" autocomplete="off" formControlName="choice_list_url" />
                </mat-form-field>

                <div class="url-btns">
                    <cb-merge-text-list [surveyId]="questionItem.surveyId" [pageId]="questionItem.page_id"
                        [returnCode]="true" (updateText)="onTextMerge($event, 'choice_list_url')">
                    </cb-merge-text-list>

                    <button class="test-btn" mat-raised-button (click)="checkEnteredUrl()">
                        {{'SURVEY-EDITOR.ITEMS.CUSTOM-DROPDOWN.TEST-URL' | translate}}
                    </button>
                </div>
            </div>
            <ng-container *ngIf="testedValues.length">
                <ng-container formArrayName="levels" >
                    <div class="url-wrapper" *ngFor="let level of levels; let i = index"
                    [formGroupName]="i">
                        <mat-form-field>
                            <input matInput placeholder="{{
                                'SURVEY-EDITOR.ITEMS.DRILLDOWN.SHOW-PROP'
                                    | translate
                            }}" autocomplete="off" formControlName="name_property" />
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput placeholder="{{
                                'SURVEY-EDITOR.ITEMS.DRILLDOWN.LEVEL-PROP'
                                    | translate
                            }}" autocomplete="off" formControlName="next_level_property" />
                        </mat-form-field>
                    </div>
                </ng-container>
                <a
                    class="cb-button-link"
                    color="primary"
                    type="button"
                    (click)="addLevel()"
                    >{{ 'SURVEY-EDITOR.ITEMS.DRILLDOWN.ADD-LEVEL' | translate }}
                </a>
                <a
                    class="cb-button-link"
                    color="primary"
                    type="button"
                    (click)="removeLevel()"
                    >{{ 'SURVEY-EDITOR.ITEMS.DRILLDOWN.REM-LEVEL' | translate }}
                </a>
            </ng-container>
            <p *ngIf="valuePreviews.length">
                {{'SURVEY-EDITOR.ITEMS.CUSTOM-DROPDOWN.TESTED-DATA' | translate}}
            </p>
            <ul class="value-list" *ngIf="valuePreviews.length">
                <li *ngFor="let value of valuePreviews">
                    {{value}}
                </li>
            </ul>
        </cb-question-collapsed-panel>

    </div>

    <cb-behavior-options [parentForm]="form" [softRequiredEnabled]="questionItem.softRequiredEnabled">

    </cb-behavior-options>

    <div class="form-section border-top" *ngIf="!isMatrix">
        <cb-question-collapsed-panel title="{{
                'SURVEY-EDITOR.SHARED.APPEARANCE.APPEARANCE' | translate
            }}">
            <div class="form-group-position">
                <cb-label-position class="position-control-group" [selectedOption]="textPosition"
                    (selected)="labelPositionSelected($event)"></cb-label-position>
                <cb-item-position class="position-control-group" [selectedOption]="itemPosition"
                    (selected)="itemPositionSelected($event)"></cb-item-position>
            </div>
        </cb-question-collapsed-panel>
    </div>

    <div class="form-section" *ngIf="!isMatrix">
        <cb-question-collapsed-panel title="{{ 'SURVEY-EDITOR.SHARED.CONDITIONS' | translate }}"
            [tooltip]="'SURVEY-EDITOR.SHARED.TOOLTIPS.CONDITIONS' | translate">
            <cb-groups-expressions-element [source]="questionItem"></cb-groups-expressions-element>
        </cb-question-collapsed-panel>
    </div>

</form>