<form [formGroup]="form" autocomplete="off">
    <div class="form-section">
        <cb-question-item-title></cb-question-item-title>
        <div class="cb-change-type-section">
            <cb-item-type [itemType]="singleLineText"></cb-item-type>
        </div>
        <cb-question-and-subtext
            [questionItem]="questionItem"
            [isMatrix]="isMatrix"
        ></cb-question-and-subtext>
    </div>
    <cb-behavior-options [parentForm]="form" [softRequiredEnabled]="questionItem.softRequiredEnabled" [expanded]="true">
        <div class="form-fields-group">
            <mat-form-field class="cb-form-field">
                <mat-select
                    placeholder="{{
                        'SURVEY-EDITOR.SHARED.ANSWER-FORMAT' | translate
                    }}"
                    formControlName="answer_format"
                    (selectionChange)="onAnswerFormatChanged($event)"
                >

                    <mat-option *ngFor="let type of types" [value]="type[0]">{{
                        'SURVEY-EDITOR.ITEMS.SINGLELINES.'+type[1] | translate
                    }}</mat-option>

                    </mat-select> 
                </mat-form-field>
                <div
                    class="cb-form-field"
                    *ngIf="isNumericMinMax(answerType)"
                >
                    <mat-form-field>
                        <input
                            matInput
                            autocomplete="off"
                            (keypress)="onNumericKeyUp($event)"
                            formControlName="min_value"
                            placeholder="{{
                                'SURVEY-EDITOR.ITEMS.SINGLELINES.MIN-VALUE'
                                    | translate
                            }}"
                        />
                    </mat-form-field>
                    <mat-form-field>
                        <input
                            matInput
                            autocomplete="off"
                            (keypress)="onNumericKeyUp($event)"
                            formControlName="max_value"
                            placeholder="{{
                                'SURVEY-EDITOR.ITEMS.SINGLELINES.MAX-VALUE'
                                    | translate
                            }}"
                        />
                    </mat-form-field>
                </div>
                <div
                    class="cb-form-field"
                    *ngIf="answerType === 'Regex'"
                >
                    <mat-form-field>
                        <input
                            matInput
                            autocomplete="off"
                            type="string"
                            formControlName="regex_pattern"
                            placeholder="{{
                                'SURVEY-EDITOR.ITEMS.SINGLELINES.PATTERN'
                                    | translate
                            }}"
                        />
                    </mat-form-field>
                </div>
                <div
                    class="cb-form-field"
                    *ngIf="answerType === 'Regex'"
                >   
                    <mat-form-field>
                        <input
                            matInput
                            autocomplete="off"
                            type="string"
                            formControlName="regex_validation_message"
                            placeholder="{{
                                'SURVEY-EDITOR.ITEMS.SINGLELINES.VALIDATION-MESSAGE'
                                    | translate
                            }}"
                        />
                    </mat-form-field>
                </div>
                <div
                    class="cb-form-field"
                    *ngIf="
                        answerType === 'Date_USA' ||
                        answerType === 'Date_ROTW'
                    "
                >
                <cb-date-time-picker
                    formControlName="min_value"
                    placeholder="{{
                        'SURVEY-EDITOR.ITEMS.SINGLELINES.MIN-VALUE'
                            | translate
                    }}"
                    [datepickerMode]="DatepickerMode.DATE"
                ></cb-date-time-picker>
                <cb-date-time-picker
                    formControlName="max_value"
                    placeholder="{{
                        'SURVEY-EDITOR.ITEMS.SINGLELINES.MAX-VALUE'
                            | translate
                    }}"
                    [datepickerMode]="DatepickerMode.DATE"
                    [minDate]="form.get('min_value').value"
                ></cb-date-time-picker>
            </div>
            <mat-form-field
                class="cb-form-field"
                *ngIf="
                    !isNumericMinMax(answerType) &&
                    answerType !== 'Date_USA' &&
                    answerType !== 'Date_ROTW'
                "
            >
                <input
                    matInput
                    type="number"
                    formControlName="max_length"
                    placeholder="{{
                        'SURVEY-EDITOR.ITEMS.SINGLELINES.MAX-LENGTH'
                            | translate
                    }}"
                />
            </mat-form-field>
        </div>
        <div class="form-fields-group">
            <cb-date-time-picker
                class="cb-form-field"
                formControlName="default_text"
                placeholder="{{ setDefaultTextToPlaceholder ? 
                    defaultTextPlaceholder :
                    'SURVEY-EDITOR.SHARED.DEFAULT-TEXT' | translate
                }}"
                [datepickerMode]="DatepickerMode.DATE"
                *ngIf="
                    answerType === 'Date_USA' ||
                    answerType === 'Date_ROTW'
                "
            ></cb-date-time-picker>
            <mat-form-field
                class="cb-form-field"
                *ngIf="
                    answerType !== 'Date_USA' &&
                    answerType !== 'Date_ROTW'
                "
            >
                <input
                    matInput
                    formControlName="default_text"
                    placeholder="{{
                        'SURVEY-EDITOR.SHARED.DEFAULT-TEXT' | translate
                    }}"
                />
            </mat-form-field>
            <cb-merge-text-list
                [surveyId]="questionItem.surveyId"
                [pageId]="questionItem.page_id"
                [text]="editorText"
                (updateText)="onMergeDefaultText($event)"
            >
            </cb-merge-text-list>
        </div>
        <div class="form-fields-group" *ngIf="isText()">
            <div class="form-fields-container-outer">
                <div class="form-fields-container-inner">
                    <mat-checkbox
                    class="cb-form-field"
                    formControlName="enable_sentiment_analysis"
                    *ngIf="answerType === 'None'"
                    >
                        {{ 'SURVEY-EDITOR.SHARED.ENABLE-SENTIMENT-ANALYSIS' | translate }}
                    </mat-checkbox>
                </div>
                <cb-help-icon [tooltip]="'SURVEY-EDITOR.SHARED.TOOLTIPS.SENTIMENT-ANALYSIS' | translate"></cb-help-icon>
            </div>
        </div>
    </cb-behavior-options>

    <div class="form-section border-top" *ngIf="!isMatrix">
        <cb-question-collapsed-panel
            title="{{ 'SURVEY-EDITOR.SHARED.AUTOCOMPLETE' | translate }}"
        >
            <mat-form-field>
                <mat-select
                    placeholder="{{
                        'SURVEY-EDITOR.ITEMS.SINGLELINES.SELECT-LIST'
                            | translate
                    }}"
                    [value]="listName"
                    (selectionChange)="onAutocompleteChange(autocomplete.value)"
                    #autocomplete
                >
                    <mat-option value="no">{{
                        'SURVEY-EDITOR.ITEMS.SINGLELINES.NO-AUTOCOMPLETE'
                            | translate
                    }}</mat-option>
                    <mat-option value="new_list">{{
                        'SURVEY-EDITOR.ITEMS.SINGLELINES.ADD-NEW-LIST'
                            | translate
                    }}</mat-option>
                    <mat-option
                        *ngFor="let l of autocompleteLists"
                        value="{{ l.id }}"
                        >{{ l.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div>
                <mat-form-field
                    *ngIf="autocomplete.value === 'new_list' || editMode"
                >
                    <input
                        matInput
                        autocomplete="off"
                        formControlName="autocomplete_list_name"
                        placeholder="{{
                            'SURVEY-EDITOR.ITEMS.SINGLELINES.LIST-NAME'
                                | translate
                        }}"
                    />
                </mat-form-field>
                <br />
                <mat-form-field class="autocomplete-list full-width" *ngIf="autocomplete.value !== 'no'">
                    <textarea
                        matInput
                        [readonly]="isAutoCompleteListDefault"
                        formControlName="autocomplete_list"
                        [(ngModel)]="list"
                        placeholder="{{
                            'SURVEY-EDITOR.ITEMS.SINGLELINES.ENTER-ONE'
                                | translate
                        }}"
                    ></textarea>
                </mat-form-field>
            </div>
        </cb-question-collapsed-panel>
    </div>
    <div class="form-section" *ngIf="!isMatrix">
        <cb-question-collapsed-panel
            title=" {{
                'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.APPEARANCE' | translate
            }}"
        >
            <div class="form-group-position">
                <cb-item-position
                    class="position-control-group"
                    [selectedOption]="itemPosition"
                    (selected)="itemPositionSelected($event)"
                ></cb-item-position>
                <cb-label-position
                    class="position-control-group"
                    [selectedOption]="textPosition"
                    (selected)="labelPositionSelected($event)"
                ></cb-label-position>
            </div>
            <mat-form-field>
                <input
                    matInput
                    type="number"
                    formControlName="width"
                    (keypress)="onNumericKeyUp($event)"
                    placeholder="{{
                        'SURVEY-EDITOR.ITEMS.SINGLELINES.WIDTH' | translate
                    }}"
                />
            </mat-form-field>
        </cb-question-collapsed-panel>
    </div>
    <div class="form-section" *ngIf="!isMatrix">
        <cb-question-collapsed-panel
            title="{{ 'SURVEY-EDITOR.SHARED.CONDITIONS' | translate }}"
            [tooltip]="'SURVEY-EDITOR.SHARED.TOOLTIPS.CONDITIONS' | translate"
        >
            <cb-groups-expressions-element
                [source]="questionItem"
            ></cb-groups-expressions-element>
        </cb-question-collapsed-panel>
    </div>
</form>
