<form [formGroup]="form" autocomplete="off">
    <div class="form-section">
        <h3 class="form-section-title">
            {{ 'SURVEY-EDITOR.SHARED.QUESTION' | translate }}
        </h3>
        <div class="cb-change-type-section">
            <cb-item-type [itemType]="matrix"></cb-item-type>
        </div>
        <cb-question-and-subtext
            [questionItem]="matrixItem"
        ></cb-question-and-subtext>
    </div>
    <div class="form-section">
        <cb-matrix-rows-item
            [options]="matrixItem.rows"
            [questionType]="matrixItem"
            [expanded]="true"
        ></cb-matrix-rows-item>
    </div>

    <div class="form-section">
        <cb-matrix-columns-item
            [columns]="matrixItem.columns"
            [surveyId]="matrixItem.surveyId"
            [pageId]="matrixItem.page_id"
            [softRequiredEnabled]="matrixItem.softRequiredEnabled"
        >
        </cb-matrix-columns-item>
    </div>

    <cb-behavior-options [parentForm]="form" [softRequiredEnabled]="false"  [hideRequired]="true">
    </cb-behavior-options>

    <div class="form-section border-top">
        <cb-question-collapsed-panel
            title="{{
                'SURVEY-EDITOR.SHARED.APPEARANCE.APPEARANCE' | translate
            }}"
        >
            <div class="form-fields-group">
                <mat-form-field class="cb-form-field">
                    <mat-select
                        placeholder="{{
                            'SURVEY-EDITOR.ITEMS.MATRIX.GRID-LINES' | translate
                        }}"
                        formControlName="grid_lines"
                    >
                        <mat-option value="None">{{
                            'SURVEY-EDITOR.ITEMS.MATRIX.NONE' | translate
                        }}</mat-option>
                        <mat-option value="Both">{{
                            'SURVEY-EDITOR.ITEMS.MATRIX.BOTH' | translate
                        }}</mat-option>
                        <mat-option value="Vertical">{{
                            'SURVEY-EDITOR.ITEMS.MATRIX.VERTICAL' | translate
                        }}</mat-option>
                        <mat-option value="Horizontal">{{
                            'SURVEY-EDITOR.ITEMS.MATRIX.HORIZONTAL' | translate
                        }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="cb-form-field">
                    <span>{{
                        'SURVEY-EDITOR.ITEMS.MATRIX.TABLE-WIDTH' | translate
                    }}</span>
                    <mat-form-field
                        floatLabel="never"
                        class="column-width-input"
                    >
                        <input
                            matInput
                            autocomplete="off"
                            (keypress)="onNumericKeyUp($event)"
                            formControlName="width"
                        />
                    </mat-form-field>
                    <span>px</span>
                </div>
            </div>
            <div class="form-group-position">
                <cb-item-position
                    class="position-control-group"
                    [selectedOption]="itemPosition"
                    (selected)="itemPositionSelected($event)"
                >
                </cb-item-position>
                <cb-label-position
                    class="position-control-group"
                    [selectedOption]="textPosition"
                    (selected)="labelPositionSelected($event)"
                >
                </cb-label-position>
                <cb-position-element
                    class="position-control-group"
                    [title]="
                        'SURVEY-EDITOR.ITEMS.MATRIX.ROW-TEXT-POSITION'
                            | translate
                    "
                    [options]="options"
                    [selectedOption]="rowTextPosition"
                    (selected)="rowTextPositionSelected($event)"
                >
                </cb-position-element>
            </div>
        </cb-question-collapsed-panel>
    </div>

    <div class="form-section">
        <cb-question-collapsed-panel
            title="{{ 'SURVEY-EDITOR.SHARED.CONDITIONS' | translate }}"
            [tooltip]="'SURVEY-EDITOR.SHARED.TOOLTIPS.CONDITIONS' | translate"
        >
            <cb-groups-expressions-element
                [source]="matrixItem"
            ></cb-groups-expressions-element>
        </cb-question-collapsed-panel>
    </div> 
</form>
