<cb-spinner [show]="isBlockLoading" [blockLayout]="true"></cb-spinner>
<mat-toolbar color="basic" *ngIf="!isLoading">
    <div class="survey-toolbar">
        <span 
            matTooltip="{{ 'SURVEY-SETTINGS.TAB-NAMES.TOOLTIPS.PREVIEW' | translate }}"
        >
            <a
                [routerLink]="['surveys', survey.id, 'preview']"
                routerLinkActive="active"
            >
                <mat-icon svgIcon="fi_eye"></mat-icon>
                {{ 'SURVEY-SETTINGS.TAB-NAMES.PREVIEW' | translate }}
            </a>
        </span>
        <span 
            matTooltip="{{ 'SURVEY-SETTINGS.TAB-NAMES.TOOLTIPS.EDIT' | translate }}"
        >
            <a
                [routerLink]="['surveys', survey.id, 'editor']"
                routerLinkActive="active"
            >
            <mat-icon svgIcon="fi_edit_3"></mat-icon>
                {{ 'SURVEY-SETTINGS.TAB-NAMES.EDIT' | translate }}
            </a>
        </span>
        <span
            routerLinkActive="active"
            matTooltip="{{ 'SURVEY-SETTINGS.TAB-NAMES.TOOLTIPS.SHARE' | translate }}"
        >
            <mat-menu
                #shareMenu="matMenu"
                [overlapTrigger]="false"
                xPosition="before"
            >
                <a
                    mat-menu-item
                    [routerLink]="['surveys', survey.id, 'share']"
                    routerLinkActive="active"
                >
                    {{ 'SURVEY-SETTINGS.TAB-NAMES.SHARE-VIA-LINK' | translate }}
                </a>
                <a
                    mat-menu-item
                    [routerLink]="['surveys', survey.id, 'invitations']"
                    routerLinkActive="active"
                >
                    {{ 'SURVEY-SETTINGS.TAB-NAMES.SHARE-CAMPAIGNS' | translate }}
                </a>
            </mat-menu>

            <cb-icon-with-caret
                [name]="'fi_share_2'"
                [menuTrigger]="shareMenu"
            >
                <a routerLinkActive="active">{{
                    'SURVEY-SETTINGS.TAB-NAMES.SHARE' | translate
                }}</a>
            </cb-icon-with-caret>
        </span>
        <span 
            matTooltip="{{ 'SURVEY-SETTINGS.TAB-NAMES.TOOLTIPS.RESPONSES' | translate }}"
        >
            <a
                [routerLink]="['surveys', survey.id, 'responses']"
                routerLinkActive="active"
            >
            <mat-icon svgIcon="fi_message_square"></mat-icon>
                {{ 'SURVEY-SETTINGS.TAB-NAMES.RESPONSES' | translate }}
            </a>
        </span>
        <span
            *ngIf="showReportTab"
            routerLinkActive="active"
            matTooltip="{{ 'SURVEY-SETTINGS.TAB-NAMES.TOOLTIPS.REPORT' | translate }}"
        >
            <mat-menu
                #reportMenu="matMenu"
                [overlapTrigger]="false"
                xPosition="before"
            >
                <a
                    mat-menu-item
                    [routerLink]="['surveys', survey.id, 'report', 'overview']"
                    routerLinkActive="active"
                >
                    {{
                        'SURVEY-SETTINGS.TAB-NAMES.REPORT-VIEW' | translate
                    }}
                </a>
                <a
                    mat-menu-item
                    [routerLink]="['surveys', survey.id, 'report', 'edit']"
                    routerLinkActive="active"
                >
                    {{
                        'SURVEY-SETTINGS.TAB-NAMES.REPORT-EDIT' | translate
                    }}
                </a>
                <a
                    mat-menu-item
                    [routerLink]="['surveys', survey.id, 'report', 'settings']"
                    routerLinkActive="active"
                >
                    {{
                        'SURVEY-SETTINGS.TAB-NAMES.REPORT-SETTINGS'
                            | translate
                    }}
                </a>
                <a
                    mat-menu-item
                    [routerLink]="['surveys', survey.id, 'report', 'styles']"
                    routerLinkActive="active"
                >
                    {{ 'SURVEY-SETTINGS.TAB-NAMES.STYLE' | translate }}
                </a>
                <a
                    mat-menu-item
                    [routerLink]="['surveys', survey.id, 'report', 'share']"
                    routerLinkActive="active"
                >
                    {{
                        'SURVEY-SETTINGS.TAB-NAMES.REPORT-SHARE' | translate
                    }}
                </a>
            </mat-menu>

            <cb-icon-with-caret
                [menuTrigger]="reportMenu"
                [name]="'fi_pie_chart'"
            >
                <a>
                    {{ 'SURVEY-SETTINGS.TAB-NAMES.REPORT' | translate }}
                </a>
            </cb-icon-with-caret>
        </span>
        <span
            routerLinkActive="active"
            matTooltip="{{ 'SURVEY-SETTINGS.TAB-NAMES.TOOLTIPS.SETTINGS' | translate }}"
        >
            <mat-menu
                #settingsMenu="matMenu"
                [overlapTrigger]="false"
                xPosition="before"
            >
                <a
                    mat-menu-item
                    [routerLink]="['surveys', survey.id, 'settings-respondent']"
                    routerLinkActive="active"
                >
                    {{
                        'SURVEY-SETTINGS.TAB-NAMES.RESPONDENT-PERMISSION'
                            | translate
                    }}
                </a>
                <a
                    mat-menu-item
                    [routerLink]="['surveys', survey.id, 'settings-limits']"
                    routerLinkActive="active"
                >
                    {{
                        'SURVEY-SETTINGS.TAB-NAMES.RESPONSE-LIMITS'
                            | translate
                    }}
                </a>
                <a
                    mat-menu-item
                    [routerLink]="['surveys', survey.id, 'settings-appearance']"
                    routerLinkActive="active"
                >
                    {{ 'SURVEY-SETTINGS.TAB-NAMES.APPEARANCE' | translate }}
                </a>
                <a
                    mat-menu-item
                    [routerLink]="['surveys', survey.id, 'theme']"
                    routerLinkActive="active"
                >
                    {{ 'SURVEY-SETTINGS.TAB-NAMES.STYLE' | translate }}
                </a>
                <a
                    *ngIf="allowMultiLanguage"
                    mat-menu-item
                    [routerLink]="['surveys', survey.id, 'settings-language']"
                    routerLinkActive="active"
                >
                    {{ 'SURVEY-SETTINGS.TAB-NAMES.LANGUAGE' | translate }}
                </a>
                <a
                    mat-menu-item
                    [routerLink]="['surveys', survey.id, 'settings-text']"
                    routerLinkActive="active"
                >
                    {{ 'SURVEY-SETTINGS.TAB-NAMES.STANDARD' | translate }}
                </a>
                <a
                    mat-menu-item
                    [routerLink]="['surveys', survey.id, 'settings-admin']"
                    routerLinkActive="active"
                >
                    {{
                        'SURVEY-SETTINGS.TAB-NAMES.ADMIN-PERMISSION'
                            | translate
                    }}
                </a>
                <a
                    mat-menu-item
                    [routerLink]="['surveys', survey.id, 'settings-advanced']"
                    routerLinkActive="active"
                >
                    {{ 'SURVEY-SETTINGS.TAB-NAMES.ADVANCED' | translate }}
                </a>
            </mat-menu>

            <cb-icon-with-caret
                [name]="'fi_settings'"
                [menuTrigger]="settingsMenu"
            >
                <a>{{
                    'SURVEY-SETTINGS.TAB-NAMES.SETTINGS' | translate
                }}</a>
            </cb-icon-with-caret>
        </span>
    </div>
</mat-toolbar>
