<div class="menu-inner-content" *ngIf="itemType === 'actionItems'">
    <div class="menu-group-list">
        <ng-container *ngFor="let item of actionTypes">
            <button
                *ngIf="checkOnHideItem(item)"
                mat-button
                (click)="onCreateActionItemButtonClick(item)"
            >
                <cb-item-type [itemType]="item" [showTooltip]="true" ></cb-item-type>
            </button>
        </ng-container>
    </div>
</div>

<div class="menu-inner-content" *ngIf="itemType === 'reportItems'">
    <div class="menu-group-list">
        <button
            mat-button
            (click)="onCreateDisplayOrReportItemButtonClick(reportType.SUMMARY)"
        >
            <cb-item-type [itemType]="reportType.SUMMARY" [showTooltip]="true"></cb-item-type>
        </button>
    </div>
</div>

<div *ngIf="itemType === 'displayItems'">
    <div class="menu-group-list">
        <button
            *ngFor="let item of displayTypes"
            mat-button
            (click)="onCreateDisplayOrReportItemButtonClick(item)"
        >
            <cb-item-type [itemType]="item" [showTooltip]="true"></cb-item-type>
        </button>
    </div>
</div>

<div class="menu-inner-content wide-menu" *ngIf="itemType === 'questionItems'">
    <div class="menu-group-list" *ngFor="let items of questionTypes">
        <ng-container *ngFor="let item of items">
            <button
                *ngIf="checkOnHideItem(item)"
                mat-button
                (click)="onCreateQuestionItemButtonClick(item)"
            >
                <cb-item-type [itemType]="item" [showTooltip]="true"></cb-item-type>
            </button>
        </ng-container>
    </div>
</div>
