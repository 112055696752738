<cb-main-dialog [title]="
        (isFromFile ? 'NEW-SURVEY-DIALOG.NEW_SURVEY_FROM_FILE' :
        isSurveyTemplate ? 'NEW-SURVEY-DIALOG.NEW_SURVEY_TEMPLATE' :
        'NEW-SURVEY-DIALOG.NEW_SURVEY') | translate
    " [mainButton]="'SHARED.CREATE' | translate" [cancelButton]="'SHARED.CANCEL' | translate" (onMain)="onSubmit()"
    (onCancel)="cancel()" [disabled]="disableSubmitButton()">
    <cb-spinner [blockLayout]="true" [show]="true" *ngIf="isLoading"></cb-spinner>
    <form class="dialog-form-block" [formGroup]="form" (ngSubmit)="onSubmit()" novalidate *ngIf="!isLoading">
        <div class="side" *ngIf="isFromFile">
            <cb-button (onClick)="fileInputRef.click()" color="primary" type="flat" [text]="'NEW-SURVEY-DIALOG.IMPORT-FILE' | translate"></cb-button>
            <input hidden accept=".json" type="file" (change)="fileChanged($event.target.files)" #fileInputRef />
            <span *ngIf="selectedFile">{{selectedFile.name|elipsis: 38 }}</span>
        </div>
        <div>
            <label>{{ 'NEW-SURVEY-DIALOG.SURVEY_TITLE' | translate }}</label>
            <mat-form-field class="dialog-form no-label no-top" appearance="outline">
                <input matInput placeholder="{{(isSurveyTemplate ? 'NEW-SURVEY-DIALOG.SURVEY_TEMPLATE_TITLE' :
                                                     'NEW-SURVEY-DIALOG.SURVEY_TITLE') | translate }}"
                    (keyup)="setCustomUrl($event)" formControlName="name" maxlength="255" />
                <mat-error>
                    {{
                    (isSurveyTemplate ? 'NEW-SURVEY-DIALOG.VALIDATION-MESSAGE.SURVEY_TEMPLATE_TITLE'
                    : 'NEW-SURVEY-DIALOG.VALIDATION-MESSAGE.SURVEY_TITLE')
                    | translate
                    }}
                </mat-error>
            </mat-form-field>
        </div>
        <div *ngIf="isSurveyTemplate">
            <label>{{ 'NEW-SURVEY-DIALOG.SURVEY_TEMPLATE_DESCRIPTION' | translate }}</label>
            <mat-form-field class="dialog-form no-label no-top" appearance="outline">
                <input matInput placeholder="{{ 'NEW-SURVEY-DIALOG.SURVEY_TEMPLATE_DESCRIPTION' | translate }}"
                    formControlName="description" maxlength="255" />
            </mat-form-field>
        </div>
        <div *ngIf="isShowCustomUrl && !isSurveyTemplate">
            <label>{{ 'NEW-SURVEY-DIALOG.CUSTOM_URL' | translate }}</label>
            <mat-form-field class="dialog-form no-label no-top" appearance="outline">
                <input matInput placeholder="{{ 'NEW-SURVEY-DIALOG.CUSTOM_URL' | translate }}"
                    (keyup)="setCustomUrl($event)" formControlName="custom_url" />
            </mat-form-field>
        </div>
        <div *ngIf="!isSurveyTemplate">
            <label>{{ 'NEW-SURVEY-DIALOG.SELECT_FOLDER' | translate }}</label>
            <mat-form-field class="dialog-form no-label no-top" appearance="outline">
                <mat-select placeholder="{{
                        'NEW-SURVEY-DIALOG.SELECT_FOLDER' | translate
                    }}" formControlName="survey_folder_id">
                    <mat-option [value]="-1">{{
                        'NEW-SURVEY-DIALOG.NO_FOLDER' | translate
                        }}</mat-option>
                    <mat-option *ngFor="let folder of folders" [value]="folder.id">
                        {{ folder.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="surveyTemplates && !isFromFile && !isSurveyTemplate">
            <label>{{ 'NEW-SURVEY-DIALOG.SELECT_TEMPLATE' | translate }}</label>
            <mat-form-field class="dialog-form no-label no-top" appearance="outline">
                <mat-select placeholder="{{
                        'NEW-SURVEY-DIALOG.SELECT_TEMPLATE' | translate
                    }}" formControlName="survey_template_id">
                    <mat-option [value]="-1">{{
                        'NEW-SURVEY-DIALOG.NO_TEMPLATE' | translate
                        }}</mat-option>
                    <mat-option *ngFor="let template of surveyTemplates.items" [value]="template.id">
                        {{ template.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </form>
</cb-main-dialog>