<form [formGroup]="form" autocomplete="off">
    <div class="form-section">
        <h3 class="form-section-title">
            {{ 'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.WEBHOOK-OPTIONS' | translate }}
        </h3>
        <div>
            <div class="field-with-merge-code-selector">
                <mat-form-field floatLabel="never">
                    <input
                        matInput
                        formControlName="url"
                        placeholder="{{
                            'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.WEBHOOK-URL' | translate
                        }}"
                    />
                </mat-form-field>

                <cb-merge-text-list
                    [surveyId]="actionItem.surveyId"
                    [pageId]="actionItem.page_id"
                    [returnCode]="true"
                    (updateText)="onMergeText($event, 'url')"
                >
                </cb-merge-text-list>
            </div>
            <div>
                <mat-checkbox formControlName="send_once">
                    {{ 'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.SEND-ONCE' | translate }}
                </mat-checkbox>
            </div>
            <div>
                <mat-checkbox formControlName="include_alias">
                    {{ 'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.WEBHOOK-INCLUDE-ALIAS' | translate }}
                </mat-checkbox>
            </div>
            <div class="webhook-container">
                <mat-checkbox formControlName="is_flat">
                    {{ 'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.WEBHOOK-IS-FLAT' | translate }}
                </mat-checkbox>
                <cb-help-icon
                    [tooltip]="'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.WEBHOOK-IS-FLAT-TOOLTIP' | translate">
                </cb-help-icon>
            </div>
            <div *ngIf="actionItem.is_flat" class="error-message">
                <mat-error>
                    {{ 'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.WEBHOOK-IS-FLAT-NOTIFICATION' | translate }}
                </mat-error>
            </div>
        </div>
        <ng-container formArrayName="request_headers">
            <h4>{{ 'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.WEBHOOK-REQUEST-HEADERS' | translate }}</h4>
            <div class="request-headers-table">
                <div class="request-headers-table-header" *ngIf="request_headers.length">
                    <div class="cell header-name-cell" >
                        {{ 'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.WEBHOOK-REQUEST-HEADER-NAME' | translate }}
                    </div>
                    <div class="cell header-value-cell">
                        {{ 'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.WEBHOOK-REQUEST-HEADER-VALUE' | translate }}
                    </div>
                    <div class="cell actions-cell">&nbsp;</div>
                </div>
                <div
                    class="request-headers-table-row"
                    *ngFor="let header of request_headers; let i = index"
                    [formGroupName]="i"
                >
                    <mat-form-field
                        class="cell header-name-cell"
                    >
                        <input
                            matInput
                            formControlName="name"
                        />
                    </mat-form-field>
                    <mat-form-field
                        class="cell header-value-cell"
                    >
                        <input
                            matInput
                            formControlName="value"
                        />
                    </mat-form-field>

                    <div class="cell actions-cell">
                        <button
                            mat-icon-button
                            (click)="removeRequestHeader(i)"
                        >
                            <cb-svg-icon
                                class="icon"
                                [name]="'close'"
                            ></cb-svg-icon>
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>
        <a
            class="cb-button-link"
            color="primary"
            type="button"
            (click)="addRequestHeader()"
            >{{ 'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.WEBHOOK-ADD-REQUEST-HEADER' | translate }}</a
        >
    </div>
    <div class="form-section">
        <h3 class="form-section-title">
            {{ 'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.CONDITIONS' | translate }}
            <cb-help-icon [tooltip]="'SURVEY-EDITOR.SHARED.TOOLTIPS.CONDITIONS' | translate"></cb-help-icon>
        </h3>
        <cb-groups-expressions-element
            [source]="actionItem"
        ></cb-groups-expressions-element>
    </div>
</form>
