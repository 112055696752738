import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { IconsRegistry } from '../consts/icons.const';

@Injectable({
  providedIn: 'root'
})
export class IconLoaderService {

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {}
  loadSvgIcons(): void {
    Object.keys(IconsRegistry).forEach(iconName => {
      this.matIconRegistry.addSvgIcon(
        iconName,
        this.domSanitizer.bypassSecurityTrustResourceUrl(IconsRegistry[iconName])
      );
    });
  }

}
