import { QuestionItem } from './questionItem';

export class MatrixSumTotalItem extends QuestionItem {
    answer_format: string;
    min_value: any;
    max_value: any;
    default_text: string;
    operator: string;
    total_value: number;
    answer_text?: string;
}
