import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { WebhookItem } from '../../../../../shared/models/survey-items/action-items/webhookItem';
import {
    FormGroup,
    FormBuilder,
    FormControl, FormArray
} from '@angular/forms';
import { SharedService } from '../../../../../infrastructure/services';
import { Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../../../shared/decorators/autoUnsubscribe.decorator';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'cb-webhook-item',
    templateUrl: './webhook-item.component.html',
    styleUrls: ['./webhook-item.component.scss']
})
@AutoUnsubscribe()
export class WebhookItemComponent implements OnInit, OnDestroy {
    @Input() actionItem: WebhookItem;

    form: FormGroup;
    request_headers: any;

    private componentDestroyed = new Subject();

    constructor(
        private fb: FormBuilder,
        private sharedService: SharedService
    ) {
    }

    ngOnInit() {
        this.createForm();
        this.createActionItemObject();
    }

    private createForm() {
        if (!this.actionItem) {
            this.actionItem = <WebhookItem>{
                url: '',
                send_once: false,
                include_alias: true,
                is_flat: false,
                request_headers: []
            };
        }

        if (this.actionItem.include_alias == null) {
            this.actionItem.include_alias = true;
        }

        if (!this.actionItem.request_headers) {
            this.actionItem.request_headers = [];
        }

        this.form = this.fb.group({
            url: new FormControl(this.actionItem.url),
            send_once: new FormControl(!!this.actionItem.send_once),
            include_alias: new FormControl(this.actionItem.include_alias),
            is_flat: new FormControl(!!this.actionItem.is_flat),
            request_headers: this.fb.array([])
        });

        const requestHeadersControl = (<FormArray>this.form.get('request_headers'));
        this.actionItem.request_headers.forEach(header => {
            requestHeadersControl.push(new FormGroup({
                name: new FormControl(header.name),
                value: new FormControl(header.value)
            }));
        });

        this.request_headers = this.form.get('request_headers')['controls'];
    }

    private createActionItemObject(): void {
        const control = this.form.controls;
        this.form.valueChanges
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(s => {
                const actionItem = {
                    ...this.actionItem,
                    ...(<WebhookItem>{
                        url: control['url'].value,
                        send_once: control['send_once'].value,
                        include_alias: control['include_alias'].value,
                        is_flat: control['is_flat'].value,
                        request_headers: control['request_headers'].value
                    })
                };
                this.sharedService.share('actionItemSettings', actionItem);
            });
    }

    onMergeText(mergeCode, controlName: string) {
        const field = this.form.controls[controlName];
        const newValue = field.value ? field.value + ' ' + mergeCode : mergeCode;
        field.setValue(newValue);
    }

    public addRequestHeader(): void {
        const control = new FormGroup({
            name: new FormControl(null),
            value: new FormControl(null)
        });
        (<FormArray>this.form.get('request_headers')).push(control);
    }

    public removeRequestHeader(index): void {
        (<FormArray>this.form.get('request_headers')).removeAt(index);
    }

    ngOnDestroy() {}
}
