import { Observable, forkJoin, Subject } from 'rxjs';
import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { SharedService } from '../../../../../infrastructure/services';
import { ProfilePropertyItem } from '../../../../../shared/models/survey-items/action-items/profilePropertyItem';
import { SurveyActionType } from '../../../../../infrastructure/consts/surveys.consts';
import {
    LoginProvider,
    SurveysSettingProvider
} from '../../../../providers';
import { stripHtml } from '../../../../../infrastructure/helpers/strip-html.helper';
import { ProfileProperty } from '../../../../../infrastructure/models/profile-property.model';
import { AutoUnsubscribe } from '../../../../../shared/decorators/autoUnsubscribe.decorator';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'cb-profile-property-item',
    templateUrl: './profile-property-item.component.html',
    styleUrls: ['./profile-property-item.component.scss']
})
@AutoUnsubscribe()
export class ProfilePropertyItemComponent implements OnInit, OnDestroy {
    @Input() actionItem: ProfilePropertyItem;
    public profile = SurveyActionType.PROFILE_PROPERTY;
    public profileProperties: ProfileProperty[];
    public questionsList: any[];
    public selectedProperty: string;
    public selectedQuestion: string;
    public items: any;
    form: FormGroup;
    private componentDestroyed = new Subject();

    constructor(
        private fb: FormBuilder,
        private sharedService: SharedService,
        private loginProvider: LoginProvider,
        private settingsProvider: SurveysSettingProvider,
        private _cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        forkJoin([
            this.loginProvider.getUserProfileProperties(),
            this.settingsProvider.getSurveyQuestions(
                this.actionItem.surveyId,
                this.actionItem.page_id
            )
        ]).subscribe(responseList => {
            this.profileProperties = responseList[0];

            this.selectedProperty = responseList[0][0].name;
            if (responseList[1].length) {
                this.selectedQuestion = responseList[1][0].id;
                this.questionsList = responseList[1].map(p => ({
                    id: p.id,
                    text: stripHtml(p.text)
                }));
            }
            this._cdr.detectChanges();
        });
        this.createForm();
        this.createActionItemObject();
        this.items = this.form.get('properties')['controls'];
    }

    private createForm() {
        if (!this.actionItem) {
            this.actionItem = <ProfilePropertyItem>{};
        }

        this.form = this.fb.group({
            properties: this.fb.array([])
        });
        if (this.actionItem.properties) {
            this.actionItem.properties.forEach(prop => {
                this.addProperty(prop.source_item_id, prop.property_name);
            });
        }
    }

    private createActionItemObject(): void {
        const control = this.form.controls;
        this.form.valueChanges
            .pipe(
                takeUntil(this.componentDestroyed)
            )
            .subscribe(s => {
                const actionItem = {
                    ...this.actionItem,
                    ...(<ProfilePropertyItem>{
                        properties: control['properties'].value
                    })
                };
                this.sharedService.share('actionItemSettings', actionItem);
            });
    }

    public onAddPropertyClick(itemId, profileName) {
        this.addProperty(itemId, profileName);
    }

    public addProperty(sourceItemId: number, userProperty: string): void {
        const control = new FormGroup({
            source_item_id: new FormControl(sourceItemId),
            property_name: new FormControl(userProperty)
        });
        (<FormArray>this.form.get('properties')).push(control);
    }

    public onRemoveOptionClick(index) {
        const options = <FormArray>this.form.get('properties');
        options.removeAt(index);
    }

    public getQuestionText(itemId) {
        return this.questionsList.find(x => x.id === itemId)
            ? this.questionsList.find(x => x.id === itemId).text
            : null;
    }

    ngOnDestroy() {}
}
