<div class="cb-new-question-dialog-content dialog-content">
    <h2>{{ 'SURVEY-EDITOR.SHARED.ACTION-ITEM' | translate }}</h2>
    <ng-container>
        <cb-javascript-item
            *ngIf="itemType === actionType.JAVASCRIPT"
            [actionItem]="itemData"
        ></cb-javascript-item>
        <cb-email-alert-item
            *ngIf="itemType === actionType.EMAIL_ALERT"
            [actionItem]="itemData"
        ></cb-email-alert-item>
        <cb-email-response-item
            *ngIf="itemType === actionType.EMAIL_RESPONSE"
            [actionItem]="itemData"
        ></cb-email-response-item>
        <cb-hyperlink-item 
            *ngIf="itemType === actionType.REDIRECT"
            [actionItem]="itemData"
        ></cb-hyperlink-item>
        <cb-profile-property-item
            *ngIf="itemType === actionType.PROFILE_PROPERTY"
            [actionItem]="itemData"
        ></cb-profile-property-item>
        <cb-webhook-item
            *ngIf="itemType === actionType.WEBHOOK"
            [actionItem]="itemData"
        ></cb-webhook-item>
    </ng-container>
    <div *ngFor="let message of validationMessages" class="invalid">
        <strong>{{ message }}</strong>
    </div>
    <div class="buttons-container large-button-container">
        <button mat-button type="button" class="btn-cancel" (click)="cancel()">
            {{ 'SHARED.CANCEL' | translate }}
        </button>
        <button
            mat-raised-button
            type="submit"
            class="btn-submit"
            (click)="onActionItemSave()"
            [disabled]="isSaveButtonClicked"
        >
            {{ 'SHARED.SAVE' | translate }}
        </button>
    </div>
</div>
