import { Row } from '../../../../../shared/models/survey-items/question-items/matrix';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SharedService } from '../../../../../infrastructure/services';
import { composeExpressionsCondition } from '../../../../../infrastructure/helpers/surveys-expressions.helper';
import { ValidationObject } from '../../../../../infrastructure/models';
import { SurveyEditorValidator } from '../../../../../infrastructure/validators/survey-editor.validators';
import * as _ from 'lodash';
import { PageExpressionsGroupsType } from '../../../../../infrastructure/consts/surveys.consts';
import { Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../../../shared/decorators/autoUnsubscribe.decorator';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'cb-matrix-row-expression-dialog',
    templateUrl: './matrix-row-expression.component.html',
    styleUrls: ['./matrix-row-expression.component.scss']
})
@AutoUnsubscribe()
export class MatrixRowExpressionComponent implements OnInit, OnDestroy {
    row: Row;
    branching_rules: any[];
    public validationMessages: ValidationObject[] = [];
    private componentDestroyed = new Subject();

    constructor(
        @Inject(MAT_DIALOG_DATA) private dialogData,
        private dialogRef: MatDialogRef<MatrixRowExpressionComponent>,
        private sharedService: SharedService
    ) {}

    ngOnInit() {
        this.row = this.dialogData.row;
        this.sharedService.getData(PageExpressionsGroupsType.EXPRESSIONS_GROUP)
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(gr => {
                if (gr.data.id === this.row.id) {
                    this.branching_rules = gr.data.branching_rules;
                }
            });
    }

    onSave() {
        if (this.isValid(this.branching_rules)) {
            this.dialogRef.close(
                composeExpressionsCondition(this.branching_rules, true)
            );
        }
    }

    private isValid(itemData: any[]) {
        const errors: ValidationObject[] = [];
        for (let i = 0; i < itemData.length; i++) {
            const errs = SurveyEditorValidator.validate({
                include_condition: itemData[i]
            });
            if (errs && errs.length) {
                errs.forEach(er => {
                    errors.push(er);
                });
            }
        }
        this.validationMessages = _.uniqBy(errors, 'validationMessage');
        return errors.length === 0;
    }

    ngOnDestroy() {}
}
