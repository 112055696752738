<cb-main-dialog [title]="'COPY-SURVEY-DIALOG.COPY_SURVEY' | translate" [mainButton]="'SHARED.COPY' | translate"
[cancelButton]="'SHARED.CANCEL' | translate" [disabled]="disableSubmitButton()"
(onMain)="onSubmit()" (onCancel)="close()">
    <cb-spinner *ngIf="isLoading" [show]="true"></cb-spinner>
    <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate *ngIf="!isLoading">
        <div>
            <label>{{'COPY-SURVEY-DIALOG.SURVEY_TITLE' | translate}}</label>
            <mat-form-field class="no-label no-top dialog-form" appearance="outline">
                <input matInput placeholder="{{
                    'COPY-SURVEY-DIALOG.SURVEY_TITLE' | translate
                }}" (keyup)="setCustomUrl($event)" formControlName="name" maxlength="255" />
                <mat-error>
                    {{
                    'COPY-SURVEY-DIALOG.VALIDATION-MESSAGE.SURVEY_TITLE'
                    | translate
                    }}
                </mat-error>
            </mat-form-field>
        </div>
        <div>
            <label>{{'COPY-SURVEY-DIALOG.CUSTOM_URL' | translate}}</label>
            <mat-form-field class="no-label no-top dialog-form" appearance="outline" *ngIf="isShowCustomUrl">
                <input matInput placeholder="{{ 'COPY-SURVEY-DIALOG.CUSTOM_URL' | translate }}"
                    (keyup)="setCustomUrl($event)" formControlName="custom_url" />
            </mat-form-field>
        </div>
        <div>
            <label>{{'COPY-SURVEY-DIALOG.SELECT_FOLDER' | translate}}</label>
            <mat-form-field class="no-label no-top dialog-form" appearance="outline">
                <mat-select placeholder="{{
                    'COPY-SURVEY-DIALOG.SELECT_FOLDER' | translate
                }}" formControlName="folder_id">
                    <mat-option [value]="''">{{
                        'COPY-SURVEY-DIALOG.NO_FOLDER' | translate
                        }}</mat-option>
                    <mat-option *ngFor="let folder of folders" [value]="folder.id">
                        {{ folder.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </form>
</cb-main-dialog>
