import { Component, Input } from '@angular/core';

@Component({
    selector: 'cb-survey-item-status',
    templateUrl: './survey-item-status.component.html',
    styleUrls: ['./survey-item-status.component.scss']
})
export class SurveyItemStatusComponent {
    @Input() status: string;
    constructor() {}
}
