<mat-expansion-panel
    class="cb-expansion-panel cb-form-expansion-panel"
    [expanded]="expanded"
>
    <mat-expansion-panel-header
        [collapsedHeight]="'48px'"
        [expandedHeight]="'48px'"
    >
        <mat-panel-title class="d-flex align-items-center">
            <span class="icon-collapse-wrap">
                <cb-svg-icon
                    class="icon icon-less"
                    [name]="'collapse-less'"
                ></cb-svg-icon>
                <cb-svg-icon
                    class="icon icon-more"
                    [name]="'collapse-more'"
                ></cb-svg-icon>
            </span>
            <h3 class="form-section-title">
                {{ 'SURVEY-EDITOR.SHARED.APPEARANCE.ROWS' | translate }}
            </h3>
        </mat-panel-title>
    </mat-expansion-panel-header>
    <form [formGroup]="form">
        <ng-container formArrayName="options">
            <div
                *ngIf="items && items.length >= 1"
                class="matrix-col-row-fields-group header-fields-group d-flex align-items-center"
            >
                <div class="id-cell">#</div>
                <div class="cell type-cell">
                    {{ 'SURVEY-EDITOR.SHARED.CHOICE.TYPE' | translate }}
                </div>
                <div class="cell branching-cell"></div>
                <div class="cell text-cell">
                    {{ 'SURVEY-EDITOR.SHARED.CHOICE.TEXT' | translate }}
                </div>
                <div class="cell alias-cell">
                    {{ 'SURVEY-EDITOR.SHARED.CHOICE.ALIAS' | translate }}
                </div>
                <div class="cell actions-cell"></div>
            </div>
            <div cdkDropList [cdkDropListData]="items" (cdkDropListDropped)="onDrop($event)">
                <div cdkDrag *ngFor="let item of items; let i = index">
                    <div
                        class="draggable-item shadowed-section matrix-item"
                        [formGroupName]="i"
                    >
                        <span class="icon-move">
                            <cb-svg-icon
                                class="icon"
                                [name]="'move-page'"
                            ></cb-svg-icon>
                        </span>
                        <div
                            class="matrix-col-row-fields-group form-fields-group d-flex align-items-center"
                        >
                            <div class="cell id-cell">{{ i }}</div>
                            <div class="cell type-cell">
                                <mat-select
                                    placeholder="{{
                                        'SURVEY-EDITOR.ITEMS.MATRIX.TYPE'
                                            | translate
                                    }}"
                                    formControlName="row_type"
                                    #rowType
                                >
                                    <mat-option value="Normal">{{
                                        'SURVEY-EDITOR.ITEMS.MATRIX.NORMAL'
                                            | translate
                                    }}</mat-option>
                                    <mat-option value="Other">{{
                                        'SURVEY-EDITOR.ITEMS.MATRIX.OTHER'
                                            | translate
                                    }}</mat-option>
                                    <mat-option value="Subheading">{{
                                        'SURVEY-EDITOR.ITEMS.MATRIX.HEADER'
                                            | translate
                                    }}</mat-option>
                                </mat-select>
                            </div>
                            <div class="cell branching-cell">
                                <button
                                    (click)="onAddExpressions(i)"
                                    mat-menu-item
                                    class="cb-del-icon-spacing"
                                    matTooltip="{{
                                        'SURVEY-EDITOR.ITEMS.MATRIX.ROW-CONDITION-TOOLTIP' | translate
                                    }}"
                                >
                                    <span class="icon-wrap">
                                        <cb-svg-icon
                                            class="icon svg-icon"
                                            [name]="'page-logic'"
                                        ></cb-svg-icon>
                                    </span>
                                </button>
                            </div>
                            <div class="cell text-cell">
                                <mat-form-field class="inpit-with-merge">
                                    <input
                                        matInput
                                        placeholder="{{
                                            'SURVEY-EDITOR.ITEMS.MATRIX.ROW'
                                                | translate
                                        }} {{ i + 1 }} {{
                                            'SURVEY-EDITOR.ITEMS.MATRIX.TEXT'
                                                | translate
                                        }}"
                                        formControlName="text"
                                        (keydown.enter)="onEnter()"
                                        #optionsElement
                                    />
                                    <mat-error>{{
                                        'SURVEY-EDITOR.SHARED.CHOICE.TYPE-OPTION'
                                            | translate
                                    }}</mat-error>
                                </mat-form-field>
                                <cb-merge-text-list
                                    class="row-merge-codes"
                                    [surveyId]="questionType.surveyId"
                                    [pageId]="questionType.page_id"
                                    [text]=""
                                    (updateText)="onMergeDefaultText($event, i)"
                                >
                                </cb-merge-text-list>
                            </div>
                            <cb-html-options
                                [index]="i"
                                [form]="form"
                                [isMatrix]="true"
                                *ngIf="!isRowTypeOther(i)"
                            ></cb-html-options>
                            <div class="cell alias-cell">
                                <mat-form-field class="flex-2">
                                    <input
                                        matInput
                                        placeholder="{{
                                            'SURVEY-EDITOR.SHARED.CHOICE.ALIAS'
                                                | translate
                                        }}"
                                        formControlName="alias"
                                        (keydown.enter)="onEnter(false)"
                                    />
                                </mat-form-field>
                                <!--<button mat-button *ngIf="rowType.value !== 'Other'" (click)="onOpenHtmlEditorClick(i)">Html</button>-->
                            </div>
                            <div class="cell actions-cell">
                                <button
                                    mat-icon-button
                                    (click)="onRemoveOptionClick(i)"
                                >
                                    <cb-svg-icon
                                        class="icon"
                                        [name]="'close'"
                                    ></cb-svg-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <!--<a class="cb-button-link" color="primary" type="button" (click)="addOption()">+ Add an Option</a>-->
        <div>
            <button
                mat-button
                class="cb-button-default button-add-column-matrix"
                [matMenuTriggerFor]="rowMenu"
            >
                <cb-svg-icon class="icon" [name]="'add-row'"></cb-svg-icon>
                {{ 'SURVEY-EDITOR.ITEMS.MATRIX.ADD-ROW' | translate }}
            </button>
        </div>
        <mat-menu
            #rowMenu="matMenu"
            [overlapTrigger]="false"
            class="action-button-menu-panel"
            xPosition="after"
        >
            <div class="menu-inner-content">
                <div class="menu-group-list">
                    <button mat-button (click)="addOption('Normal')">
                        {{ 'SURVEY-EDITOR.ITEMS.MATRIX.NORMAL' | translate }}
                    </button>
                    <button mat-button (click)="addOption('Other')">
                        {{ 'SURVEY-EDITOR.ITEMS.MATRIX.OTHER' | translate }}
                    </button>
                    <button mat-button (click)="addOption('Subheading')">
                        {{ 'SURVEY-EDITOR.ITEMS.MATRIX.HEADER' | translate }}
                    </button>
                </div>
            </div>
        </mat-menu>
    </form>
</mat-expansion-panel>
