import { Component, OnInit } from '@angular/core';
import { CurrentSurveyStore } from '../../store';

@Component({
  selector: 'cb-survey-nav',
  templateUrl: './survey-nav.component.html',
  styleUrls: ['./survey-nav.component.scss']
})
export class SurveyNavComponent implements OnInit {
  survey$ = this.currentSurveyStore.currentSurvey;
  params$ = this.currentSurveyStore.params;
  isLoading$ = this.currentSurveyStore.isLoading;
  constructor(private currentSurveyStore: CurrentSurveyStore) { }

  ngOnInit() {
  }

}
