import { Input, Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { SurveyDisplayType } from '../../../../../infrastructure/consts/surveys.consts';
import { SharedService } from '../../../../../infrastructure/services';
import { MessageItem } from '../../../../../shared/models/survey-items/display-items/messageItem';
import { TinyRichEditorComponent } from '../../../../../shared/components/rich-editor/tiny-rich-editor.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../../../shared/decorators/autoUnsubscribe.decorator';

@Component({
    selector: 'cb-message-item',
    templateUrl: './message-item.component.html',
    styleUrls: ['./message-item.component.scss']
})
@AutoUnsubscribe()
export class MessageItemComponent implements OnInit, OnDestroy {
    @Input() questionItem: MessageItem;
    @ViewChild('messageBodyEditor', { static: true }) private messageBodyEditor: TinyRichEditorComponent;

    itemText: string;
    form: FormGroup;
    editorText = '';
    editorCursorPos: number;
    public messageClass = '';
    public activeMessageToolbar = '';
    private componentDestroyed = new Subject();

    constructor(
        private fb: FormBuilder,
        private sharedService: SharedService
    ) {}

    ngOnInit() {
        if (this.questionItem) {
            this.itemText = this.questionItem.text;
        }

        this.form = this.fb.group({});
        this.form.addControl('messageText', new FormControl(this.itemText));
        this.createQuestionItemUpdateSubscription();
        this.updateQuestionItem();
    }

    onMergeText(mergeCode: string) {
        this.messageBodyEditor.insertMergeCode(mergeCode);
    }

    private createQuestionItemUpdateSubscription(): void {
        this.form.valueChanges
            .pipe(
                takeUntil(this.componentDestroyed)
            )
            .subscribe(s => {
                this.updateQuestionItem();
            });
    }

    updateQuestionItem() {
        const control = this.form.controls;
        const questionItem = <MessageItem>{
            item_type: SurveyDisplayType.MESSAGE,
            text: control['messageText'].value
        };
        this.sharedService.share('questionItem', questionItem);
    }

    ngOnDestroy() {}
}
