import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { SurveyQuestionType } from '../../../../../infrastructure/consts/surveys.consts';
import { SharedService } from '../../../../../infrastructure/services';
import { RatingItem } from '../../../../../shared/models/survey-items/question-items/ratingItem';
import { SurveyEditorValidator } from '../../../../../infrastructure/validators/survey-editor.validators';
import { Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../../../shared/decorators/autoUnsubscribe.decorator';
import { takeUntil } from 'rxjs/operators';
import { MultipleChoicesItemComponent } from '../multiple-choices-item.component';

@Component({
    selector: 'cb-rating-item',
    templateUrl: './rating-item.component.html',
    styleUrls: ['./rating-item.component.scss']
})
@AutoUnsubscribe()
export class RatingItemComponent extends MultipleChoicesItemComponent implements OnInit, OnDestroy {
    @Input() questionItem: RatingItem;
    editorText = '';
    editorCursorPos: number;
    rating = SurveyQuestionType.RATING;
    itemPosition: string;
    textPosition: string;
    ratingType: string;
    isHideOption: boolean;
    private componentDestroyed = new Subject();

    public form: FormGroup;

    constructor(
        private fb: FormBuilder,
        private sharedService: SharedService
    ) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.createFormGroup();
        this.createQuestionItemObject();
    }

    itemPositionSelected(value): void {
        this.itemPosition = value;
        this.form.controls['item_position'].setValue(value);
    }

    labelPositionSelected(value): void {
        this.textPosition = value;
        this.form.controls['question_text_position'].setValue(value);
    }

    private createFormGroup(): void {
        let isAllQuestionsRequired = false;
        if (!this.questionItem) {
            this.questionItem = <RatingItem>{};
        }
        if (!this.questionItem.id) {
            isAllQuestionsRequired = this.sharedService.surveyRespondentSettings.getValue() ?
            this.sharedService.surveyRespondentSettings.getValue().makeQuestionsRequired : false;
        }
        this.form = this.fb.group({
            // behavior
            alias: new FormControl(this.questionItem.alias),
            html_class: new FormControl(this.questionItem.html_class),
            is_required: new FormControl(this.questionItem.is_required || isAllQuestionsRequired),
            is_soft_required: new FormControl(this.questionItem.softRequiredEnabled ? !!this.questionItem.is_soft_required : false),
            option_text: new FormControl(this.questionItem.option_text),
            show_selected_value: new FormControl(
                !!this.questionItem.show_selected_value
            ),
            rating_type: new FormControl(
                this.questionItem.rating_type
                    ? this.questionItem.rating_type
                    : 'Star'
            ),
            option_size: new FormControl(
                this.questionItem.option_size
                    ? this.questionItem.option_size
                    : 50
            ),
            show_values: new FormControl(!!this.questionItem.show_values),

            // appearance
            item_position: new FormControl(this.questionItem.item_position),
            question_text_position: new FormControl(
                this.questionItem.question_text_position
            )
        });

        this.itemPosition = this.questionItem.item_position;
        this.textPosition = this.questionItem.question_text_position;
    }

    private createQuestionItemObject(): void {
        this.updateQuestionItem();
        this.form.valueChanges
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(s => {
                this.updateQuestionItem();
            });
    }

    private updateQuestionItem() {
        const control = this.form.controls;
        const questionItem = new RatingItem();
        if (
            control['show_values'].value &&
            this.checkOnHideOption(control['rating_type'].value)
        ) {
            this.form.controls['show_values'].setValue(false);
        }
        if (
            control['show_selected_value'].value === true &&
            control['rating_type'].value === 'Star'
        ) {
            this.form.controls['show_selected_value'].setValue(false);
        }
        questionItem.item_type = SurveyQuestionType.RATING;
        questionItem.alias = control['alias'].value;
        questionItem.html_class = control['html_class'].value;
        questionItem.is_required = control['is_required'].value;
        questionItem.is_soft_required = control['is_soft_required'].value;
        questionItem.rating_type = control['rating_type'].value;
        questionItem.show_values = control['show_values'].value;
        questionItem.option_size = control['option_size'].value;
        questionItem.show_selected_value = control['show_selected_value'].value;
        questionItem.question_text_position = control['question_text_position'].value;
        questionItem.item_position = control['item_position'].value;
        this.ratingType = questionItem.rating_type;
        this.isHideOption = this.checkOnHideOption(this.ratingType);
        this.sharedService.share('questionItemSettings', questionItem);
    }

    checkOnHideOption(type) {
        return (
            ['HorizontalBar', 'VerticalBar', 'Line', 'ReversedBox'].indexOf(
                type
            ) > -1
        );
    }

    public onMergeText(updatedText) {
        const field = this.form.controls['item'];
        field.setValue(updatedText);
    }

    public onNumericKeyUp(event: any) {
        return SurveyEditorValidator.validateNumericInput(event);
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
}
