import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SharedService } from '../../../../../infrastructure/services';
import { SurveyQuestionType } from '../../../../../infrastructure/consts/surveys.consts';
import { ImageChoiceItem } from '../../../../../shared/models/survey-items/question-items/imageChoiceItem';
import { SurveyEditorValidator } from '../../../../../infrastructure/validators/survey-editor.validators';
import { Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../../../shared/decorators/autoUnsubscribe.decorator';
import { takeUntil } from 'rxjs/operators';
import { MultipleChoicesItemComponent } from '../multiple-choices-item.component';

@Component({
    selector: 'cb-image-choice-item',
    templateUrl: './image-choice-item.component.html',
    styleUrls: ['./image-choice-item.component.scss']
})
@AutoUnsubscribe()
export class ImageChoiceItemComponent extends MultipleChoicesItemComponent implements OnInit, OnDestroy {
    @Input() questionItem: ImageChoiceItem;

    form: FormGroup;
    imageChoice = SurveyQuestionType.IMAGE_CHOICE;
    itemPosition: string;
    textPosition: string;
    private componentDestroyed = new Subject();

    constructor(
        private fb: FormBuilder,
        private sharedService: SharedService
    ) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.createFormGroup();
        this.createQuestionItemObject();
    }

    private createFormGroup(): void {
        let isAllQuestionsRequired = false;
        if (!this.questionItem) {
            this.questionItem = <ImageChoiceItem>{};
        }
        if (!this.questionItem.id) {
            isAllQuestionsRequired = this.sharedService.surveyRespondentSettings.getValue() ?
            this.sharedService.surveyRespondentSettings.getValue().makeQuestionsRequired : false;
        }
        this.form = this.fb.group({
            alias: new FormControl(this.questionItem.alias),
            html_class: new FormControl(this.questionItem.html_class),
            item_position: new FormControl(this.questionItem.item_position),
            question_text_position: new FormControl(
                this.questionItem.question_text_position
            ),
            select_min_limit: new FormControl(this.questionItem.select_min_limit ? this.questionItem.select_min_limit :
                                                            isAllQuestionsRequired ? 1 : null),
            select_max_limit: new FormControl(this.questionItem.select_max_limit),
            is_required: new FormControl(this.questionItem.is_required || isAllQuestionsRequired),
            is_soft_required: new FormControl(this.questionItem.softRequiredEnabled ? !!this.questionItem.is_soft_required : false),
            randomize: new FormControl(!!this.questionItem.randomize),
            image_width: new FormControl(this.questionItem.image_width),
            image_height: new FormControl(this.questionItem.image_height),
            layout: new FormControl(
                this.questionItem.layout ? this.questionItem.layout : 'Vertical'
            ),
            show_text_labels: new FormControl(!!this.questionItem.show_text_labels)
        });
        this.itemPosition = this.questionItem.item_position;
        this.textPosition = this.questionItem.question_text_position;
    }

    private createQuestionItemObject(): void {
        this.updateQuestionItemObject();
        this.form.valueChanges
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(() => {
                this.updateQuestionItemObject();
            });
    }

    private updateQuestionItemObject(): void {
        const control = this.form.controls;
        const questionItem = new ImageChoiceItem();
        questionItem.item_type = SurveyQuestionType.IMAGE_CHOICE;
        questionItem.alias = control['alias'].value;
        questionItem.html_class = control['html_class'].value;
        questionItem.randomize = control['randomize'].value;
        questionItem.is_required = control['is_required'].value;
        questionItem.is_soft_required = control['is_soft_required'].value;
        questionItem.layout = control['layout'].value;
        questionItem.select_min_limit = control['select_min_limit'].value
            ? control['select_min_limit'].value
            : questionItem.is_required ? 1 : null;
        questionItem.select_max_limit = control['select_max_limit'].value;
        questionItem.image_width = control['image_width'].value;
        questionItem.image_height = control['image_height'].value;
        questionItem.show_text_labels = control['show_text_labels'].value;
        questionItem.question_text_position = control['question_text_position'].value;
        questionItem.item_position = control['item_position'].value;
        this.sharedService.share('questionItemSettings', questionItem);
    }

    itemPositionSelected(value): void {
        this.itemPosition = value;
        this.form.controls['item_position'].setValue(value);
    }

    labelPositionSelected(value): void {
        this.textPosition = value;
        this.form.controls['question_text_position'].setValue(value);
    }

    public onNumericKeyUp(event: any) {
        return SurveyEditorValidator.validateNumericInput(event);
    }

    public onMinSelectChange(event) {
        if (+event.target.value > 0) {
            this.form.controls['is_required'].setValue(true);
        } else {
            this.form.controls['is_required'].setValue(false);
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
}
