import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppearanceSSSModel } from '../../../../../infrastructure/models/survey-settings.model';
import { Subscription } from 'rxjs';
import { SurveysSettingProvider } from '../../../../providers';

@Component({
    selector: 'cb-wcag-alert',
    templateUrl: './wcag-alert.component.html',
    styleUrls: ['./wcag-alert.component.scss']
})
export class WcagAlertComponent implements OnInit, OnDestroy {
    appearenceSettings: Partial<AppearanceSSSModel>;
    subscription: Subscription;

    constructor(private surveysSettingProvider: SurveysSettingProvider) {}

    ngOnInit() {
        this.subscription = this.surveysSettingProvider.storedAppearanceSettings.subscribe(
            data => {
                this.appearenceSettings = data;
            }
        );
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
