<form [formGroup]="form" autocomplete="off">
    <div class="form-section">
        <h3 class="form-section-title">
            {{ 'SURVEY-EDITOR.SHARED.QUESTION' | translate }}
        </h3>
        <div class="cb-change-type-section">
            <cb-item-type [itemType]="contactFormItemType"></cb-item-type>
        </div>
        <cb-question-and-subtext
            [questionItem]="contactFormItem"
        ></cb-question-and-subtext>
    </div>

    <div class="form-section">
        <cb-contact-form-fields
            [fields]="contactFormItem.fields"
            [contactFormItemId]="contactFormItem.id"
            [surveyId]="contactFormItem.surveyId"
            [pageId]="contactFormItem.page_id"
            [softRequiredEnabled]="contactFormItem.softRequiredEnabled"
            (fieldsUpdated)="onFormFieldsUpdated($event)"
        >
        </cb-contact-form-fields>
    </div>

    <cb-contact-form-behavior [parentForm]="form">
    </cb-contact-form-behavior>

    <div class="form-section border-top">
        <cb-question-collapsed-panel
            title="{{
                'SURVEY-EDITOR.SHARED.APPEARANCE.APPEARANCE' | translate
            }}"
        >
            <div class="form-group-position">
                <cb-item-position
                    class="position-control-group"
                    [selectedOption]="itemPosition"
                    (selected)="itemPositionSelected($event)"
                >
                </cb-item-position>
                <cb-label-position
                    class="position-control-group"
                    [selectedOption]="textPosition"
                    (selected)="labelPositionSelected($event)"
                >
                </cb-label-position>
            </div>
            <div class="form-fields-group">
                <div class="cb-form-field">
                    <span>{{
                        'SURVEY-EDITOR.ITEMS.CONTACT-FORM.FORM-WIDTH' | translate
                    }}</span>
                    <mat-form-field
                        floatLabel="never"
                        class="contact-form-width-input"
                    >
                        <input
                            matInput
                            autocomplete="off"
                            (keypress)="onNumericKeyUp($event)"
                            formControlName="width"
                        />
                    </mat-form-field>
                    <span>px</span>
                </div>
            </div>
        </cb-question-collapsed-panel>
    </div>

    <div class="form-section">
        <cb-question-collapsed-panel
            title="{{ 'SURVEY-EDITOR.SHARED.CONDITIONS' | translate }}"
            [tooltip]="'SURVEY-EDITOR.SHARED.TOOLTIPS.CONDITIONS' | translate"
        >
            <cb-groups-expressions-element
                [source]="contactFormItem"
            ></cb-groups-expressions-element>
        </cb-question-collapsed-panel>
    </div> 
</form>
