import { map, first } from 'rxjs/operators';
import {
    Component,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    OnInit
} from '@angular/core';
import { MergeGroup } from '../../../app-admin/models/merge-codes/mergeItems';
import { SurveyMergeCodesProvider } from '../../../app-admin/providers';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'cb-merge-text-list',
    templateUrl: './merge-text-list.component.html',
    styleUrls: ['./merge-text-list.component.scss']
})
export class MergeTextListComponent implements OnInit {
    @Input() surveyId: number;
    @Input() pageId: number;
    @Input() invitationId: number;
    @Input() text: string;
    @Input() cursorPos: number;
    @Output() updateText: EventEmitter<any> = new EventEmitter();
    @ViewChild('mergeListControl', { static: true }) mergeListControl;
    @Input() returnCode: boolean;
    @Input() isTemplate: boolean;

    isSurveyTemplate: boolean;
    mergeList: MergeGroup[];
    selected = '@@ApplicationLanguage';

    constructor(
        private mergeCodesProvider: SurveyMergeCodesProvider,
        private translateService: TranslateService,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        if (this.surveyId && this.pageId) {
            this.isSurveyTemplate = this.isTemplate || (this.route.snapshot.parent && this.route.snapshot.parent.data.isTemplate);
            this.mergeCodesProvider
                .getSurveyPageMergeCodes(this.surveyId, this.pageId, this.isSurveyTemplate)
                .pipe(first())
                .subscribe(data => {
                    this.mergeList = data;
                });
        } else if (this.surveyId && this.invitationId) {
            this.mergeCodesProvider
                .getSurveyInvitationMergeCodes(this.surveyId, this.invitationId)
                .pipe(first())
                .subscribe(data => {
                    this.mergeList = data;
                });
        } else {
            this.mergeList = [];
        }
    }

    onMergeToQuestionControl({ value }) {
        if (this.returnCode) {
            this.updateText.emit(value);
        } else {
            if (this.text == null) {
                this.text = '';
            }
            let updatedText = `${this.text.slice(0, this.cursorPos)}`;
            const sliced = `${this.text.slice(this.cursorPos)}`;
            if (this.cursorPos >= this.text.length - 1) {
                updatedText += `${sliced}${value}`;
            } else {
                updatedText += `${value}${sliced}`;
            }
            this.updateText.emit(updatedText);
        }
    }

    clickOnWrapper() {
        this.mergeListControl.open();
    }

    getTranslation(item): Observable<string> {
        const prefix = 'MERGE-CODES.';
        return this.translateService.get(prefix + item.code).pipe(
            map(value => {
                return value.indexOf(prefix) > -1 ? item.name : value;
            })
        );
    }
}
