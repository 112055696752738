import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'cb-position-element',
    templateUrl: './position-element.component.html',
    styleUrls: ['./position-element.component.scss']
})
export class PositionElementComponent {
    @Input() title: string;
    @Input() options: any[];
    @Input() selectedOption: string;
    @Output() selected: EventEmitter<any> = new EventEmitter();

    constructor() {}

    onOptionClicked(text: string): void {
        this.selectedOption = text;
        this.selected.emit(text);
    }
}
