import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChildren
} from '@angular/core';
import { SurveyQuestionType } from '../../../../../../infrastructure/consts/surveys.consts';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SharedService, ToasterService } from '../../../../../../infrastructure/services';
import { TranslateService } from '@ngx-translate/core';
import { isHTML } from '../../../../../../infrastructure/helpers/is-html.helper';
import { AutoUnsubscribe } from '../../../../../../shared/decorators/autoUnsubscribe.decorator';
import { Observable, Subject, Subscription } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { SurveysProvider } from '../../../../../../app-admin/providers';

const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);

@Component({
    selector: 'cb-multiple-options-item',
    templateUrl: './multiple-options-item.component.html',
    styleUrls: ['./multiple-options-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class MultipleOptionsItemComponent implements OnInit, OnDestroy {
    @ViewChildren('itemChoiceElements') itemChoiceElements: QueryList<ElementRef>;
    @Input() surveyId: number;
    @Input() pageId: number;
    @Input() options: any[];
    @Input() questionType: any;
    @Input() expanded = false;
    @Input() hideHtml = false;
    @Input() resetChoicesEvent: Observable<void>;
    @Input() isMatrix: boolean;
    @Input() isSurveyTemplate: boolean;

    items: any;
    form: FormGroup;
    quickEnterForm: FormGroup;
    surveyQuestions = SurveyQuestionType;
    editorText = '';
    itemsArray: FormArray = new FormArray([]);
    useQuickEnter = false;
    textYes = 'yes';
    textNo = 'no';
    isIEOrEdge: boolean;
    private componentDestroyed = new Subject();
    editedIndex = 0;
    isRatingScale: boolean;
    private resetChoicesSubscription: Subscription;
    private showOptionDeleteWarning: boolean;

    constructor(
        private fb: FormBuilder,
        private sharedService: SharedService,
        private translateService: TranslateService,
        private cdr: ChangeDetectorRef,
        private toasterService: ToasterService,
        private surveysProvider: SurveysProvider
    ) {
        this.isIEOrEdge = isIEOrEdge;
    }

    ngOnInit() {
        if (this.resetChoicesEvent) {
            this.resetChoicesSubscription = this.resetChoicesEvent.subscribe(() => this.resetChoices());
        }
        
        this.isRatingScale = this.questionType === this.surveyQuestions.RATINGSCALE;
        this.form = this.fb.group({
            options: this.fb.array([])
        });
        this.quickEnterForm = this.fb.group({
            quickEnter: new FormControl(null)
        });
        this.translateService
            .get('SURVEY-EDITOR.SHARED.CHOICE.YES')
            .subscribe((text: string) => {
                this.textYes = text;
            });
        this.translateService
            .get('SURVEY-EDITOR.SHARED.CHOICE.NO')
            .subscribe((text: string) => {
                this.textNo = text;
            });
        this.quickEnterForm.valueChanges
            .pipe(
                takeUntil(this.componentDestroyed),
                distinctUntilChanged(),
                debounceTime(500)
            )
            .subscribe(s => {
                this.parseChoices();
            });
        this.form.valueChanges.pipe(
            takeUntil(this.componentDestroyed),
            debounceTime(500),
            distinctUntilChanged()
        ).subscribe(s => {
            this.sharedService.share('multipleOptions', s);
        });
        this.initChoices();

        if (this.isMatrix && !this.isSurveyTemplate) {
            this.surveysProvider.checkSurveyIsAlive(this.surveyId)
                .pipe(takeUntil(this.componentDestroyed))
                .subscribe(res => {
                    this.showOptionDeleteWarning = res;
                });
        }        
    }

    private initChoices() {
        this.sortItems();
        this.items = this.form.get('options')['controls'];

        if (!this.items.length) {
            this.addOption();
        }        
    }

    private resetChoices() {
        const options = <FormArray>this.form.get('options');
        options.clear();
        this.initChoices();
    }

    public addOption(): void {
        const index = (<FormArray>this.form.get('options')).length;
        const control = new FormGroup({
            is_default: new FormControl(false),
            text: new FormControl(null),
            alias: new FormControl(null),
            points: this.isRatingScale ? new FormControl(this.preFillPoints(), Validators.required) : new FormControl(null),
            position: new FormControl(index),
            row_type: new FormControl(null),
            enabled: new FormControl(true)
        });
        (<FormArray>this.form.get('options')).push(control);
        this.normalizeChoicePosition();
        this.editedIndex = (<FormArray>this.form.get('options')).length - 1;
        this.setFocusOnEditedChoiceText();
    }

    private setFocusOnEditedChoiceText() {
        const choice = this.editedIndex != null && this.itemChoiceElements
            ? this.itemChoiceElements.find((element, index) => index === this.editedIndex)
            : null;

        if (choice) {
            setTimeout(() => {
                const choiceTextInputEl = choice && choice.nativeElement
                    ? choice.nativeElement.querySelector('.text-cell input')
                    : null;
                if (choiceTextInputEl)
                    choiceTextInputEl.focus();
            }, 0);
        }
    }

    preFillPoints() {
        const options = this.form.value.options;
        const optionsLength = options.length;
        switch (optionsLength) {
            case 0:
                return 0;
            case 1:
                const firstOption = options[0].points;
                return firstOption < 10 ? firstOption + 1 : firstOption - 1;
            default:
                const firstStep = Math.round(options[1].points - options[0].points);
                const isPositive = Math.sign(firstStep) + 1;
                const lastOption = options[optionsLength - 1].points;
                return isPositive ?  lastOption + 1 : lastOption - 1;
        }
    }

    public onDefaultChanged(index, event) {
        const choiceControls = (<FormArray>this.form.get('options')).controls;
        choiceControls.forEach((choiceForm: FormGroup, choiceIndex: number) => {
            choiceForm.patchValue({
                is_default: choiceIndex === index && !choiceForm.value.is_default
            });
        });
        event.preventDefault();
    }

    public toggleChoiceVisibility(index): void {
        const choiceControls = (<FormArray>this.form.get('options')).controls;
        choiceControls.forEach((choiceForm: FormGroup, choiceIndex: number) => {
            if (choiceIndex === index) {
                choiceForm.patchValue({
                    enabled: !choiceForm.value.enabled
                });
            }
        });
    }

    public onRemoveOptionClick(index): void {
        var option = this.items[index];
        const options = <FormArray>this.form.get('options');
        options.removeAt(index);
        this.normalizeChoicePosition();

        if (option.value.id && this.showOptionDeleteWarning) {
            this.toasterService.showWarning('DIALOG.DELETE-CHOICES-WARNING', true, 5000);
        }
    }

    private normalizeChoicePosition() {
        let i = 0;
        this.items.forEach(c => {
            c['controls'].position.setValue(i);
            i++;
        });
    }

    public defaultEnterBehavior(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            this.addOption();
            event.preventDefault();
            event.stopPropagation();
        }
    }

    private sortItems(): void {
        if (this.options) {
            this.editedIndex = undefined;
            this.options.sort(function(a, b) {
                return a.position - b.position;
            });
            this.options.forEach(element => {
                const control = new FormGroup({
                    id: new FormControl(element.id),
                    is_default: new FormControl(element.is_default),
                    text: new FormControl(element.text),
                    alias: new FormControl(element.alias),
                    points: new FormControl(element.points, this.isRatingScale ? Validators.required : null),
                    position: new FormControl(element.position),
                    enabled: new FormControl(this.isRatingScale || element.enabled)
                });
                (<FormArray>this.form.get('options')).push(control);
            });
        }
        this.form.valueChanges.subscribe(
            text => {
                this.cdr.detectChanges();
            }
        );
    }

    prepareQuickEnterChoices() {
        const options = [...this.form.get('options').value];
        if (options) {
            const preparedQuickChoices = [];
            const quickChoices = this.quickEnterForm.get('quickEnter');
            options.sort(function(a, b) {
                return a.position - b.position;
            });
            options.forEach(element => {
                preparedQuickChoices.push([
                    element.text,
                    element.is_default ? this.textYes : this.textNo,
                    element.alias
                ]);
            });
            quickChoices.setValue(preparedQuickChoices.join('\n'));
        }
    }

    toggleQuickChoices() {
        this.useQuickEnter = !this.useQuickEnter;
        if (this.useQuickEnter) {
            this.prepareQuickEnterChoices();
        }
    }

    onMergeDefaultText(updatedText, index) {
        const options: any = this.form.get('options');
        const field = options.controls[index].get('text');
        field.setValue(`${field.value || ''}${updatedText}`);
    }

    parseChoices() {
        const oldOptions = [...this.form.get('options')['controls']];
        this.form.setControl('options', this.fb.array([]));
        const quickChoices = this.quickEnterForm.get('quickEnter');
        const oldValue = this.quickEnterForm.get('quickEnter').value;
        const preparedChoices = quickChoices.value
            .split('\n')
            .map((item, index) => {
                const choice = item.split(',');
                let selected;
                let alias;
                let text = item;
                if (choice.length > 1) {
                    for (let i = 1; i < choice.length; i++) {
                        if (this.checkOnSelected(choice[i])) {
                            selected = this.parseSelected(choice[i]);
                            text = choice.slice(0, i).join();
                            alias = choice
                                .slice(i + 1, choice.length)
                                .join();
                            break;
                        }
                    }
                }

                const control = new FormGroup({
                    is_default: new FormControl(
                        selected === this.textYes ? true : false
                    ),
                    text: new FormControl(text),
                    alias: new FormControl(alias),
                    points: new FormControl(null),
                    position: new FormControl(index),
                    row_type: new FormControl(null),
                    enabled: new FormControl(true)
                });
                if (oldOptions.length > index && oldOptions[index].get('id')) {
                    control.addControl(
                        'id',
                        new FormControl(oldOptions[index].get('id').value)
                    );
                }
                (<FormArray>this.form.get('options')).push(control);
                item = choice.slice(0).join(',');
                return item;
            })
            .join('\n');
        if (oldValue !== preparedChoices) {
            quickChoices.setValue(preparedChoices);
        }
        this.items = this.form.get('options')['controls'];
        this.sharedService.share('multipleOptions', this.form.value);
    }

    parseSelected(selected: string) {
        return selected.toLowerCase().trim() === this.textYes
            ? this.textYes
            : this.textNo;
    }

    checkOnSelected(selected) {
        return (
            selected.toLowerCase().trim() === this.textYes ||
            selected.toLowerCase().trim() === this.textNo
        );
    }

    reorderItems(event: CdkDragDrop<any[]>) {
        moveItemInArray(this.items, event.previousIndex, event.currentIndex);
        this.normalizeChoicePosition();
    }

    onFocusDraggable(item: any) {
        if (this.isIEOrEdge)
            item.sortable = false;
    }

    onBlurDraggable(item: any) {
        if (this.isIEOrEdge)
            item.sortable = true;
    }

    editItem(i, item, ref) {
        if (isHTML(item.value.text)) {
            ref.onOpenHtmlEditorClick();
        } else {
            this.editedIndex = i;
            if (item.value.text)
                this.setFocusOnEditedChoiceText();
        }
    }

    clearEditIndex(choiceIndex) {
        if (choiceIndex === this.editedIndex)
            this.editedIndex = undefined;
    }

    ngOnDestroy() {
        if (this.resetChoicesSubscription) {
            this.resetChoicesSubscription.unsubscribe();
        }
    }
}
