import {
    Component,
    Input,
    OnChanges,
    ChangeDetectionStrategy,
    ComponentFactoryResolver,
    ViewContainerRef
} from '@angular/core';
import { scrollIntoView } from '../../../infrastructure/helpers/javascript.helper';

interface ISurveyComponent {
    options: any;
}

@Component({
    selector: 'cb-dynamic-factory',
    templateUrl: './dynamic-factory.component.html',
    styleUrls: ['./dynamic-factory.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicFactoryComponent implements OnChanges {
    @Input() component: any;
    @Input() options: any;
    @Input() scrollToElement: HTMLElement;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private viewContainerRef: ViewContainerRef
    ) {}

    public ngOnChanges() {
        this.createComponent();
    }

    private createComponent() {
        if (!this.component) {
            return;
        }
        const factory = this.componentFactoryResolver.resolveComponentFactory(
            this.component
        );
        this.viewContainerRef.clear();
        const ref = this.viewContainerRef.createComponent(factory);
        const instance = <ISurveyComponent>ref.instance;
        instance.options = this.options;
        ref.changeDetectorRef.detectChanges();
        setTimeout(() => {
            scrollIntoView(this.scrollToElement);
        }, 0);
    }
}
