import { SurveyGet } from "../../models";
import { Survey } from "../../../infrastructure/models";

export interface State {
    survey: Survey;
    isLoading: boolean;
    currentSurveyParams: SurveyGet;
    isPrinting: boolean;
}

export const initialState: State = {
    survey: null,
    isLoading: false,
    currentSurveyParams: null,
    isPrinting: false
};
