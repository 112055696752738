import { Component, Inject, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SurveyFolder } from '../../../../models';
import { FoldersProvider, SurveysProvider } from '../../../../providers';
import { AllSurveysListComponent } from '../../surveys-list/all-surveys-list/all-surveys-list.component';
import { forkJoin, Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../../../shared/decorators/autoUnsubscribe.decorator';
import { takeUntil } from 'rxjs/operators';
import { ApplicationSettingsProvider } from '../../../../../app-take-survey/providers';

@Component({
    selector: 'cb-copy-survey-dialog',
    templateUrl: './copy-survey-dialog.component.html',
    styleUrls: ['../add-dialog.scss']
})
@AutoUnsubscribe()
export class CopySurveyDialogComponent implements OnInit, OnDestroy {
    isShowCustomUrl = false;
    public form: FormGroup;
    folders: SurveyFolder[];
    survey: any;
    isLoading: boolean;
    private componentDestroyed = new Subject();

    constructor(
        private fb: FormBuilder,
        private surveysProvider: SurveysProvider,
        private folderProvider: FoldersProvider,
        private dialogRef: MatDialogRef<AllSurveysListComponent>,
        private applicationSettingsProvider: ApplicationSettingsProvider,
        private ref: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) private data
    ) {
    }

    ngOnInit() {
        this.initDataForSurvey();
        this.survey = this.data.survey;
        
        const copyName = this.survey.name + '-Copy';
        this.form = this.fb.group({
            name: [copyName, Validators.required],
            custom_url: [this.resolveCustomUrl(copyName)],
            folder_id: ['']
        });
    }

    onSubmit(): void {
        this.isLoading = true;
        const { name, folder_id, custom_url } = this.form.value;
        if (this.form.valid) {
            this.surveysProvider
                .copySurvey(this.survey.id, name, folder_id, custom_url)
                .pipe(
                    takeUntil(this.componentDestroyed)
                )
                .subscribe(
                    survey => {
                        this.dialogRef.close(survey);
                    },
                    error => this.errorHandler(error)
                );
        }
    }

    close(){
        this.dialogRef.close();
    }

    initDataForSurvey(): void {
        this.isLoading = true;
        forkJoin([
            this.folderProvider.loadFolders(),
            this.applicationSettingsProvider.getSurveyApplicationSettings()
        ])
            .pipe(
                takeUntil(this.componentDestroyed)
            )
            .subscribe(([folders, appSurveySettings]) => {
                this.folders = folders;
                this.isShowCustomUrl = appSurveySettings.survey_preferences.enable_custom_survey_urls;
                this.isLoading = false;
                this.ref.detectChanges();
        }, error => this.errorHandler(error));
    }

    setCustomUrl(event: KeyboardEvent) {
        if (this.isShowCustomUrl) {
            const customUrl = this.resolveCustomUrl((<HTMLInputElement>event.target).value);
            this.form.controls['custom_url'].setValue(customUrl);
        }
    }

    resolveCustomUrl(surveyName: string):string {
        let customUrl = surveyName;

        let result = '';
        for (let j = 0; j < customUrl.length; j++) {
            const char = customUrl.charAt(j);
            const hh = char.charCodeAt(0);
            if (
                hh === 45 ||
                (hh > 47 && hh < 58) ||
                (hh > 64 && hh < 91) ||
                (hh > 96 && hh < 123)
            ) {
                result += char;
            }
            if (hh === 32) {
                result += '-';
            }
        }
        customUrl = encodeURI(result);
        return customUrl;
    }

    disableSubmitButton() {
        return !this.form.valid || this.form.value.name.trim() === '';
    }

    errorHandler(error) {
        this.isLoading = false;
    }

    ngOnDestroy() {}
}
