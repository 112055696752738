import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { transformToCloudfrontUrl } from '../../../../../../../infrastructure/helpers/cloudfront.helper';

@Component({
    selector: 'cb-label-position',
    templateUrl: './label-position.component.html',
    styleUrls: ['./label-position.component.scss']
})
export class LabelPositionComponent implements OnInit {
    @Input() selectedOption: string;
    @Output() selected: EventEmitter<any> = new EventEmitter();

    options: any[];
    constructor() {}

    ngOnInit() {
        this.options = [
            {
                text: 'Top',
                imageUrl:
                    transformToCloudfrontUrl('assets/icons/appearance/align-top-default.png')
            },
            {
                text: 'Left',
                imageUrl:
                    transformToCloudfrontUrl('assets/icons/appearance/align-left-default.png')
            },
            {
                text: 'Bottom',
                imageUrl:
                    transformToCloudfrontUrl('assets/icons/appearance/align-bottom-default.png')
            },
            {
                text: 'Right',
                imageUrl:
                    transformToCloudfrontUrl('assets/icons/appearance/align-right-default.png')
            }
        ];
    }

    onOptionClicked(text: string): void {
        this.selectedOption = text;
        this.selected.emit(text);
    }
}
