<form [formGroup]="form">
    <div>
        <cb-tiny-rich-editor
            placeholder="{{ 'SURVEY-EDITOR.SHARED.QUESTION_TEXT' | translate }}"
            formControlName="question_text"
            #questionEditor
        ></cb-tiny-rich-editor>
        <cb-merge-text-list
            [surveyId]="questionItem.surveyId"
            [pageId]="questionItem.page_id"
            [returnCode]="true"
            (updateText)="onMergeToQuestionControl($event)"
        >
        </cb-merge-text-list>
    </div>

    <div *ngIf="!isMatrix && !isSubquestion">
        <cb-tiny-rich-editor
            placeholder="{{ 'SURVEY-EDITOR.SHARED.SUB_TEXT' | translate }}"
            formControlName="subtext"
            #subtextEditor
        ></cb-tiny-rich-editor>
            <cb-merge-text-list
                [surveyId]="questionItem.surveyId"
                [pageId]="questionItem.page_id"
                [returnCode]="true"
                (updateText)="onMergeToSubtextControl($event)"
            >
            </cb-merge-text-list>
        </div>

</form>
