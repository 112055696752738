import {
    Component,
    ChangeDetectionStrategy,
    Inject
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'cb-item-preview-dialog',
    templateUrl: './item-preview-dialog.component.html',
    styleUrls: ['../add-dialog.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ItemPreviewDialogComponent {

    public item;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.item = this.data.item;
    }

}
