<form [formGroup]="form" autocomplete="off">
    <div class="form-section">
        <cb-question-item-title></cb-question-item-title>
        <div class="cb-change-type-section">
            <cb-item-type [itemType]="signature"></cb-item-type>
        </div>
        <cb-question-and-subtext
            [questionItem]="questionItem"
            [isMatrix]="false"
        ></cb-question-and-subtext>
        <cb-wcag-alert></cb-wcag-alert>
    </div>
    <cb-behavior-options [parentForm]="form" [softRequiredEnabled]="questionItem.softRequiredEnabled"  [expanded]="true">
    </cb-behavior-options>
    <div class="form-section border-top">
        <cb-question-collapsed-panel
            title="{{
                'SURVEY-EDITOR.SHARED.APPEARANCE.APPEARANCE' | translate
            }}"
        >
            <div class="form-group-position">
                <cb-item-position
                    class="position-control-group"
                    [selectedOption]="itemPosition"
                    (selected)="itemPositionSelected($event)"
                ></cb-item-position>
                <cb-label-position
                    class="position-control-group"
                    [selectedOption]="textPosition"
                    (selected)="labelPositionSelected($event)"
                ></cb-label-position>
            </div>
            <div class="form-fields-group">
                <mat-form-field class="cb-form-field">
                    <input
                        matInput
                        maxlength="3"
                        (keypress)="onNumericKeyUp($event)"
                        formControlName="width"
                        placeholder="{{
                            'SURVEY-EDITOR.ITEMS.SIGNATURE.WIDTH' | translate
                        }}"
                    />
                </mat-form-field>
                <mat-form-field class="cb-form-field">
                    <input
                        matInput
                        maxlength="3"
                        (keypress)="onNumericKeyUp($event)"
                        formControlName="height"
                        placeholder="{{
                            'SURVEY-EDITOR.ITEMS.SIGNATURE.HEIGHT' | translate
                        }}"
                    />
                </mat-form-field>
                <div class="cb-form-field">
                    <mat-form-field class="cb-color-field">
                        <input
                            matInput
                            type="text"
                            formControlName="pen_color"
                            placeholder="{{
                                'SURVEY-EDITOR.ITEMS.SIGNATURE.COLOR'
                                    | translate
                            }}"
                            [(ngModel)]="color"
                        />
                    </mat-form-field>
                    <div class="cb-color-picker">
                        <input
                            [(colorPicker)]="color"
                            [style.background]="color"
                        />
                    </div>
                </div>
            </div>
        </cb-question-collapsed-panel>
    </div>
    <div class="form-section">
        <cb-question-collapsed-panel
            title="{{ 'SURVEY-EDITOR.SHARED.CONDITIONS' | translate }}"
            [tooltip]="'SURVEY-EDITOR.SHARED.TOOLTIPS.CONDITIONS' | translate"
        >
            <cb-groups-expressions-element
                [source]="questionItem"
            ></cb-groups-expressions-element>
        </cb-question-collapsed-panel>
    </div>
</form>
