<div class="form-section border-top" [formGroup]="parentForm">
    <cb-question-collapsed-panel
        title="{{ 'SURVEY-EDITOR.SHARED.BEHAVIOR' | translate }}"
        [expanded]="expanded"
    >
        <div class="form-fields-group">
            <div *ngIf="!hideRequired" class="form-fields-container-outer">
                <div class="form-fields-container-inner">
                    <mat-checkbox
                        class="cb-form-field"
                        formControlName="is_required"
                        *ngIf="!isContactFormField"
                    >
                        {{ 'SURVEY-EDITOR.SHARED.REQUIRED' | translate }}
                    </mat-checkbox>
                    <mat-checkbox
                        class="cb-form-field"
                        formControlName="is_required"
                        *ngIf="isContactFormField"
                    >
                        {{ 'SURVEY-EDITOR.SHARED.FIELD-REQUIRED' | translate }}
                    </mat-checkbox>
                </div>
                <cb-help-icon [tooltip]="'SURVEY-EDITOR.SHARED.TOOLTIPS.REQUIRED' | translate"></cb-help-icon>
            </div>
            <div *ngIf="withSoftRequired" class="form-fields-container-outer">
                <div class="form-fields-container-inner">
                    <mat-checkbox
                        class="cb-form-field"
                        formControlName="is_soft_required"
                        *ngIf="!isContactFormField"
                    >
                        {{ 'SURVEY-EDITOR.SHARED.SOFT-REQUIRED' | translate }}
                    </mat-checkbox>
                    <mat-checkbox
                        class="cb-form-field"
                        formControlName="is_soft_required"
                        *ngIf="isContactFormField"
                    >
                        {{ 'SURVEY-EDITOR.SHARED.FIELD-SOFT-REQUIRED' | translate }}
                    </mat-checkbox>
                </div>
                <cb-help-icon [tooltip]="'SURVEY-EDITOR.SHARED.TOOLTIPS.SOFT-REQUIRED' | translate"></cb-help-icon>
            </div>
        </div>
        <div class="form-fields-group">
            <div *ngIf="showAlias" class="form-fields-container-outer">
                <div class="form-fields-container-inner">
                    <mat-form-field class="cb-form-field">
                        <input
                            matInput
                            placeholder="{{
                                'SURVEY-EDITOR.SHARED.ALIAS' | translate
                            }}"
                            formControlName="alias"
                        />
                    </mat-form-field>
                </div>
                <cb-help-icon class="alias-help" [tooltip]="'SURVEY-EDITOR.SHARED.TOOLTIPS.ALIAS-QUESTION' | translate"></cb-help-icon>
            </div>
            <div class="form-fields-container-outer" *ngIf="showCustomCss && !isContactFormField">
                <div class="form-fields-container-inner">
                    <mat-form-field class="cb-form-field"
                    *ngIf="showHtml">
                    <input
                        matInput
                        placeholder="{{
                            'SURVEY-EDITOR.SHARED.CSS-CLASS' | translate
                        }}"
                        formControlName="html_class"
                    />
                </mat-form-field>    
                </div>
            </div>
        </div>

        <ng-content></ng-content>
        
    </cb-question-collapsed-panel>
</div>