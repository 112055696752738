import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect, act } from '@ngrx/effects';
import { SurveysProvider, SurveysSettingProvider, SurveyTemplatesProvider } from '../../providers';
import { error, currentSurvey } from '../actions';
import { catchError, filter, map, switchMap, takeUntil, tap, withLatestFrom } from "rxjs/operators";
import { Survey } from "../../../infrastructure/models";
import { merge, of } from "rxjs";
import { CurrentSurveyStore } from "../current-survey.store";
import { BasicSSSModel } from "../../../infrastructure/models/survey-settings.model";
import { SurveyStatus } from "../../../infrastructure/consts/surveys.consts";

@Injectable()
export class CurrentSurveyEffect {
    constructor(private actions$: Actions,
        private surveyProvider: SurveysSettingProvider,
        private surveyTemplatesProvider: SurveyTemplatesProvider,
        private currentSurveyProvider: SurveysProvider,
        private currentSurveyStore: CurrentSurveyStore) 
    {}

    loadSurvey$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(currentSurvey.LOAD_SURVEY),
            map((action: currentSurvey.LoadSurveyAction) => action.payload),
            switchMap(surveyGet => {
                if (surveyGet.isSurveyTemplate) {
                    return this.surveyTemplatesProvider.getSurveyTemplateById(surveyGet.id);
                } else {
                    return this.surveyProvider
                        .getBaseSettingsById(surveyGet.id)
                        .pipe(map((survey) => <Survey>survey.getBaseMappedObject()));
                }
            }),
            switchMap(s=> this.currentSurveyProvider.isFavorite(s.id).pipe(map(isFavorite => ({...s, is_favorite: isFavorite})))),
            map((survey) => new currentSurvey.LoadSurveySuccessAction(survey)),
            catchError(err => merge(
                of(new error.HandleErrorAction(err)),
                of(new currentSurvey.LoadSurveyFailedAction(err))
            ))
        )
    });
    toggleLock$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(currentSurvey.TOGGLE_LOCK),
            withLatestFrom(this.currentSurveyStore.currentSurvey),
            map(([_, survey]) => {return <Survey>{...survey, allow_edit_while_active: !survey.allow_edit_while_active}}),
            switchMap(survey => {
                const bsm = new BasicSSSModel(survey);
                return this.surveyProvider.putBaseSettingsById(bsm.id, bsm.getBaseMappedObject()).pipe(map(_ => survey));
            }),
            map(survey=> new currentSurvey.LoadSurveySuccessAction(survey)),
            catchError(err => merge(
                of(new error.HandleErrorAction(err)),
                of(new currentSurvey.LoadSurveyFailedAction(err))
            ))
        )
    });
    toggleFavorite$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(currentSurvey.TOGGLE_FAVORITE),
            withLatestFrom(this.currentSurveyStore.currentSurvey),
            filter(([_, survey]) => !(survey.status.toUpperCase() === SurveyStatus.ARCHIVED.toUpperCase() && !survey.is_favorite)),
            switchMap(([_, survey]) => 
                (
                    survey.is_favorite ? 
                    this.currentSurveyProvider.removeFavorite(survey.id) : 
                    this.currentSurveyProvider.setFavorite(survey.id)
                ).pipe(
                    map(_ => (<Survey>{...survey, is_favorite: !survey.is_favorite})))
                ),
            map(survey=> new currentSurvey.LoadSurveySuccessAction(survey)),
            catchError(err => merge(
                of(new error.HandleErrorAction(err)),
                of(new currentSurvey.LoadSurveyFailedAction(err))
            ))
        )
    });
    changeStatus$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(currentSurvey.CHANGE_STATUS),
            map((action: currentSurvey.ChangeStatusAction) => action),
            withLatestFrom(this.currentSurveyStore.currentSurvey),
            switchMap(([action, survey]) => {
                const newSurvey = <Survey>{...survey, status: action.payload};
                const model = new BasicSSSModel(newSurvey);
                return this.surveyProvider.putBaseSettingsById(model.id, model).pipe(map(_ => newSurvey));
            }),
            map(survey=> new currentSurvey.LoadSurveySuccessAction(survey)),
            catchError(err => merge(
                of(new error.HandleErrorAction(err)),
                of(new currentSurvey.LoadSurveyFailedAction(err))
            ))
        )
    });

}
