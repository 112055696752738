import { Input, Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SurveyReportType } from '../../../../../infrastructure/consts/surveys.consts';
import { SharedService } from '../../../../../infrastructure/services';
import * as _ from 'lodash';
import { ResponseSummaryItem } from '../../../../../shared/models/survey-items/report-items/responseSummaryItem';
import { SurveyEditorValidator } from '../../../../../infrastructure/validators/survey-editor.validators';
import { Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../../../shared/decorators/autoUnsubscribe.decorator';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'cb-response-summary-item',
    templateUrl: './response-summary-item.component.html',
    styleUrls: ['./response-summary-item.component.scss']
})
@AutoUnsubscribe()
export class ResponseSummaryItemComponent implements OnInit, OnDestroy {
    @Input() questionItem: ResponseSummaryItem;
    form: FormGroup;
    public itemPosition: string;
    public color: string;
    public items: any;
    public defaultDisplayTypeValue = 'displayInline';
    public linkDisplayTypeValue = 'link';
    private componentDestroyed = new Subject();

    constructor(
        private fb: FormBuilder,
        private sharedService: SharedService
    ) {}

    ngOnInit() {
        this.form = this.fb.group({
            display: this.fb.control(this.getDisplayType()),
            link_text: this.fb.control(this.questionItem.link_text),
            include_response_details: this.fb.control(
                _.has(this.questionItem, 'include_response_details')
                    ? this.questionItem.include_response_details
                    : true
            ),
            show_page_numbers: this.fb.control(
                _.has(this.questionItem, 'show_page_numbers')
                    ? this.questionItem.show_page_numbers
                    : true
            ),
            show_question_numbers: this.fb.control(
                _.has(this.questionItem, 'show_question_numbers')
                    ? this.questionItem.show_question_numbers
                    : true
            ),
            include_message_items: this.fb.control(
                _.has(this.questionItem, 'include_message_items')
                    ? this.questionItem.include_message_items
                    : false
            ),
            show_hidden_items: this.fb.control(
                _.has(this.questionItem, 'show_hidden_items')
                    ? this.questionItem.show_hidden_items
                    : true
            ),
            show_print_button: this.fb.control(
                _.has(this.questionItem, 'show_print_button')
                    ? this.questionItem.show_print_button
                    : false
            ),
            show_points: this.fb.control(!!this.questionItem.show_points),
            item_position: this.fb.control(this.questionItem.item_position),
            width: this.fb.control(
                this.questionItem.width ? this.questionItem.width : 250
            ),
            height: this.fb.control(
                this.questionItem.height ? this.questionItem.height : 250
            )
        });
        this.createQuestionItemObject();
    }

    private createQuestionItemObject(): void {
        this.updateQuestionItemObject();
        this.form.valueChanges
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(() => {
                this.updateQuestionItemObject();
            });
    }

    private updateQuestionItemObject(): void {
        const control = this.form.controls;
        const questionItem = new ResponseSummaryItem();
        questionItem.item_type = SurveyReportType.SUMMARY;
        questionItem.display_inline = control['display'].value === this.defaultDisplayTypeValue;
        questionItem.link_text = control['link_text'].value;
        questionItem.include_response_details = control['include_response_details'].value;
        questionItem.show_page_numbers = control['show_page_numbers'].value;
        questionItem.show_question_numbers = control['show_question_numbers'].value;
        questionItem.include_message_items = control['include_message_items'].value;
        questionItem.show_hidden_items = control['show_hidden_items'].value;
        questionItem.show_print_button = control['show_print_button'].value;
        questionItem.item_position = control['item_position'].value;
        questionItem.width = control['width'].value;
        questionItem.height = control['height'].value;
        questionItem.show_points = control['show_points'].value;
        this.sharedService.share('questionItemSettings', questionItem);
        this.itemPosition = control['item_position'].value;
    }

    public getDisplayType() {
        if (
            !_.has(this.questionItem, 'display_inline') ||
            this.questionItem.display_inline
        ) {
            return this.defaultDisplayTypeValue;
        } else {
            return this.linkDisplayTypeValue;
        }
    }

    public itemPositionSelected(value): void {
        this.itemPosition = value;
        this.form.controls['item_position'].setValue(value);
    }

    public onNumericKeyUp(event: any) {
        return SurveyEditorValidator.validateNumericInput(event);
    }

    ngOnDestroy() {}
}
