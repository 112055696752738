<form [formGroup]="form" autocomplete="off">
    <div class="form-section">
        <cb-question-item-title></cb-question-item-title>
        <div class="cb-change-type-section">
            <cb-item-type [itemType]="dropdownlist"></cb-item-type>
        </div>
        <cb-question-and-subtext
            [questionItem]="questionItem"
            [isMatrix]="isMatrix"
        ></cb-question-and-subtext>
    </div>
    <div class="form-section">
        <cb-question-collapsed-panel
            title="{{
                'SURVEY-EDITOR.SHARED.CHOICES' | translate
            }}"
        >
            <div class="url-wrapper">
                <mat-form-field>
                    <input
                        matInput
                        placeholder="{{
                        'SURVEY-EDITOR.ITEMS.CUSTOM-DROPDOWN.CHOICE-LIST-URL'
                            | translate
                    }}"
                        autocomplete="off"
                        formControlName="choice_list_url"
                    />
                </mat-form-field>

                <div class="url-btns">
                    <cb-merge-text-list
                        [surveyId]="questionItem.surveyId"
                        [pageId]="questionItem.page_id"
                        [returnCode]="true"
                        (updateText)="onTextMerge($event, 'choice_list_url')"
                    >
                    </cb-merge-text-list>

                    <button class="test-btn" mat-raised-button (click)="checkEnteredUrl()">
                        {{'SURVEY-EDITOR.ITEMS.CUSTOM-DROPDOWN.TEST-URL' | translate}}
                    </button>
                </div>
            </div>


            <p>{{'SURVEY-EDITOR.ITEMS.CUSTOM-DROPDOWN.VALUE-INFO' | translate}}</p>
            <mat-form-field>
                <input
                    matInput
                    placeholder="{{
                        'SURVEY-EDITOR.ITEMS.CUSTOM-DROPDOWN.VALUE-FIELD'
                            | translate
                    }}"
                    autocomplete="off"
                    formControlName="value_field"
                />
            </mat-form-field>
            <p *ngIf="valuePreviews.length">
                {{'SURVEY-EDITOR.ITEMS.CUSTOM-DROPDOWN.TESTED-DATA' | translate}}
            </p>
            <ul class="value-list" *ngIf="valuePreviews.length">
                <li *ngFor="let value of valuePreviews">
                    {{value}}
                </li>
            </ul>
        </cb-question-collapsed-panel>

    </div>

    <cb-behavior-options [parentForm]="form" [softRequiredEnabled]="questionItem.softRequiredEnabled">
        <div *ngIf="!isMatrix">
            <div class="form-fields-group">
                <mat-checkbox
                    class="cb-form-field"
                    formControlName="allow_other"
                    #allow_other
                >{{ 'SURVEY-EDITOR.SHARED.ALLOW-OTHER' | translate }}
                </mat-checkbox>
                <span class="cb-form-field" *ngIf="allow_other.checked">
                    <mat-form-field>
                        <input
                            matInput
                            placeholder="{{
                                'SURVEY-EDITOR.SHARED.ALLOW-OTHER-TEXT'
                                    | translate
                            }}"
                            autocomplete="off"
                            formControlName="other_text"
                        />
                    </mat-form-field>
                    <cb-html-options
                        [index]="'other_text'"
                        [form]="form"
                        [pageId]="pageId"
                        [surveyId]="surveyId"
                        [isField]="true"
                    ></cb-html-options>
                </span>
            </div>
        </div>
    </cb-behavior-options>

    <div class="form-section border-top" *ngIf="!isMatrix">
        <cb-question-collapsed-panel
            title="{{
                'SURVEY-EDITOR.SHARED.APPEARANCE.APPEARANCE' | translate
            }}"
        >
            <div class="form-group-position">
                <cb-label-position
                    class="position-control-group"
                    [selectedOption]="textPosition"
                    (selected)="labelPositionSelected($event)"
                ></cb-label-position>
                <cb-item-position
                    class="position-control-group"
                    [selectedOption]="itemPosition"
                    (selected)="itemPositionSelected($event)"
                ></cb-item-position>
            </div>
            <div class="form-fields-group">
                <mat-checkbox
                    class="cb-form-field"
                    formControlName="show_number_labels"
                >{{
                    'SURVEY-EDITOR.SHARED.APPEARANCE.SHOW-NUMBER-LABELS'
                        | translate
                    }}
                </mat-checkbox>
            </div>
        </cb-question-collapsed-panel>
    </div>

    <div class="form-section" *ngIf="!isMatrix">
        <cb-question-collapsed-panel
            title="{{ 'SURVEY-EDITOR.SHARED.CONDITIONS' | translate }}"
            [tooltip]="'SURVEY-EDITOR.SHARED.TOOLTIPS.CONDITIONS' | translate"
        >
            <cb-groups-expressions-element
                [source]="questionItem"
            ></cb-groups-expressions-element>
        </cb-question-collapsed-panel>
    </div>
</form>

