<h4 class="group-title">{{ title }}</h4>
<div class="buttons-group">
    <button
        mat-icon-button
        *ngFor="let o of options"
        (click)="onOptionClicked(o.text)"
        [matTooltip]="'SURVEY-EDITOR.SHARED.' + o.text | translate"
        [ngClass]="selectedOption === o.text ? 'selected' : ''"
    >
        <img
            [src]="o.imageUrl"
            [ngClass]="selectedOption === o.text ? 'selected' : ''"
        />
    </button>
</div>
