import * as survey from '../actions/surveys.actions';
import { State, initialState } from './surveys.state';

export function surveysReducer(
    state = initialState,
    action: survey.Actions
): State {
    switch (action.type) {
        case survey.RELOAD_SURVEY_LIST:{
            return {
                ...state,
                isLoading: true,
                selectedSurveyIds: [],
                getData: action.payload ?? state.getData

            };
        }
        case survey.RELOAD_SURVEY_LIST_SUCCESS: {
            const data = action.payload;
            return {
                ...state,
                isLoading: false,
                selectedSurveyIds: [],
                surveyList: data
            };
        }
        case survey.LOAD_MORE_SURVEY_LIST: {
            return {
                ...state,
                isLoading: true,
                getData: {
                    ...state.getData,
                    paging_info: {
                        ...state.getData.paging_info,
                        page_num: Math.floor(state.getData.paging_info.page_size / 20) + 1,
                        page_size: 20
                    }
                }
            };
        }
            
        case survey.RELOAD_FAVORITE_SURVEYS_LIST : {
            return {
                ...state,
                isLoading: true,
                favoriteGetData: action.payload ?? state.favoriteGetData
            };
        }
        case survey.LOAD_MORE_SURVEY_LIST_SUCCESS: {
            const data = action.payload;
            return {                
                ...state,
                isLoading: false,
                surveyList: {
                    ...data,
                    items: state.surveyList.items.concat(data.items)
                },
                getData: {
                    ...state.getData,
                    paging_info: {
                        ...state.getData.paging_info,
                        page_size: state.getData.paging_info.page_num * 20,
                        page_num: 1
                    }
                }
            };
        }
        case survey.RELOAD_FAVORITE_SURVEYS_LIST_SUCCESS: {
            const data = action.payload;
            return {
                ...state,
                isLoading: false,
                favoriteSurveys: data
            };
        }
        case survey.SELECT_SURVEY: {
            return {
                ...state,
                selectedSurveyIds: action.payload,
            };
        }
        case survey.SURVEY_FAILED: {
            return {
                ...state,
                isLoading: false
            };
        }
        case survey.SURVEY_CHANGE_FAVORITE: {
            return {
                ...state,
                surveyList: {
                    ...state.surveyList,
                    items: state.surveyList.items.map(item => {
                        if (action.payload.surveyId === item.id) {
                            return {
                                ...item,
                                is_favorite: action.payload.isFavorite
                            };
                        }
                        return item;
                    })
                }
            }
        }
        default:
            return state;
    }
}
