import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'cb-archive-surveys-confirmation',
    templateUrl: './archive-surveys-confirmation.component.html',
    styleUrls: ['./archive-surveys-confirmation.component.scss']
})

export class ArchiveSurveysConfirmationComponent implements OnInit {
    public title: string;
    public message: string;
    public buttonName: string;

    constructor(
        public dialogRef: MatDialogRef<ArchiveSurveysConfirmationComponent>,
        @Inject(MAT_DIALOG_DATA) private data,
        private translateService: TranslateService
    ) { }

    ngOnInit() {
        const textPrefix = this.data && this.data.surveyIds && this.data.surveyIds.length > 1
            ? 'SURVEYS-LIST.ARCHIVE-MULTIPLE-'
            : 'SURVEYS-LIST.ARCHIVE-SINGLE-'

        this.translateService.get(textPrefix + 'TITLE').subscribe((data: string) => {
            this.title = data;
        });

        this.translateService.get(textPrefix + 'MESSAGE').subscribe((data: string) => {
            this.message = data;
        });

        this.translateService.get('SURVEYS-LIST.ARCHIVE').subscribe((data: string) => {
            this.buttonName = data;
        });
    }

    cancel() {
        this.dialogRef.close(false);
    }

    archive() {
        this.dialogRef.close(true);
    }
}
