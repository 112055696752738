import { OnInit, Component, Input, OnDestroy } from '@angular/core';
import {
    MaxDiffItem,
    maxDiffItemOrientationEnum
} from '../../../../../shared/models/survey-items/question-items/maxDiffItem';
import {
    FormGroup,
    FormBuilder,
    FormControl,
    Validators
} from '@angular/forms';
import { SharedService } from '../../../../../infrastructure/services';
import { SurveyQuestionType } from '../../../../../infrastructure/consts/surveys.consts';
import { SurveyEditorValidator } from '../../../../../infrastructure/validators/survey-editor.validators';
import { Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../../../shared/decorators/autoUnsubscribe.decorator';
import { takeUntil } from 'rxjs/operators';
import { MultipleChoicesItemComponent } from '../multiple-choices-item.component';

@Component({
    selector: 'cb-max-diff-item',
    templateUrl: './max-diff-item.component.html',
    styleUrls: ['./max-diff-item.component.scss']
})
@AutoUnsubscribe()
export class MaxDiffComponent extends MultipleChoicesItemComponent implements OnInit, OnDestroy {
    @Input() questionItem: MaxDiffItem;
    form: FormGroup;
    maxDiffItemOrientationEnum = maxDiffItemOrientationEnum;
    maxDiff = SurveyQuestionType.MAXDIFF;
    itemPosition: string;
    textPosition: string;
    private componentDestroyed = new Subject();

    constructor(
        private fb: FormBuilder,
        private sharedService: SharedService
    ) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.createFormGroup();
        this.createQuestionItemObject();
    }

    onItemPositionSelected(value): void {
        this.itemPosition = value;
        this.form.controls['item_position'].setValue(value);
    }

    onLabelPositionSelected(value): void {
        this.textPosition = value;
        this.form.controls['question_text_position'].setValue(value);
    }

    private createFormGroup(): void {
        let isAllQuestionsRequired = false;
        if (!this.questionItem) {
            this.questionItem = <MaxDiffItem>{};
        }
        if (!this.questionItem.id) {
            isAllQuestionsRequired = this.sharedService.surveyRespondentSettings.getValue() ?
            this.sharedService.surveyRespondentSettings.getValue().makeQuestionsRequired : false;
        }
        this.form = this.fb.group({
            // behavior
            alias: new FormControl(this.questionItem.alias),
            html_class: new FormControl(this.questionItem.html_class),
            is_required: new FormControl(this.questionItem.is_required || isAllQuestionsRequired),
            is_soft_required: new FormControl(this.questionItem.softRequiredEnabled ? !!this.questionItem.is_soft_required : false),
            best_label: new FormControl(
                this.questionItem.best_label
                    ? this.questionItem.best_label
                    : 'Most Appealing'
            ),
            worst_label: new FormControl(
                this.questionItem.worst_label
                    ? this.questionItem.worst_label
                    : 'Least Appealing'
            ),
            item_label: new FormControl(
                this.questionItem.item_label
                    ? this.questionItem.item_label
                    : 'Item'
            ),
            number_sets: new FormControl(this.questionItem.number_sets || 1, [
                Validators.required,
                Validators.min(1),
                Validators.max(99)
            ]),
            number_items_per_set: new FormControl(
                this.questionItem.number_items_per_set || 2,
                [Validators.required, Validators.min(2), Validators.max(99)]
            ),
            orientation: new FormControl(
                this.questionItem.orientation ||
                    maxDiffItemOrientationEnum.BEST_ITEM_WORST
            ),
            // appearance
            item_position: new FormControl(this.questionItem.item_position),
            question_text_position: new FormControl(
                this.questionItem.question_text_position
            )
        });

        this.itemPosition = this.questionItem.item_position;
        this.textPosition = this.questionItem.question_text_position;
    }

    getControlErrors(controlName: string) {
        const control = this.form.get(controlName);
        return SurveyEditorValidator.getControlErrorsText(control);
    }

    private createQuestionItemObject(): void {
        this.updateQuestionItemObject();
        this.form.valueChanges
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(s => {
                this.updateQuestionItemObject();
            });
    }

    private updateQuestionItemObject(): void {
        const control = this.form.controls;
        const questionItem = new MaxDiffItem();
        questionItem.item_type = SurveyQuestionType.MAXDIFF;
        questionItem.alias = control['alias'].value;
        questionItem.html_class = control['html_class'].value;
        questionItem.is_required = control['is_required'].value;
        questionItem.is_soft_required = control['is_soft_required'].value;
        questionItem.best_label = control['best_label'].value;
        questionItem.worst_label = control['worst_label'].value;
        questionItem.item_label = control['item_label'].value;
        questionItem.number_sets = control['number_sets'].value;
        questionItem.number_items_per_set = control['number_items_per_set'].value;
        questionItem.orientation = control['orientation'].value;
        questionItem.item_position = control['item_position'].value;
        questionItem.question_text_position = control['question_text_position'].value;
        this.sharedService.share('questionItemSettings', questionItem);
    }

    public onNumericKeyUp(event: any) {
        return SurveyEditorValidator.validateNumericInput(event);
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
}
