import { SurveyListGetData } from '../../models';
import { SurveyListItem, PagedList, EmptyPagedList } from '../../../infrastructure/models';

export interface State {
    isLoading: boolean;
    //addedSurvey: Survey;
    surveyList: PagedList<SurveyListItem>;
    favoriteSurveys: PagedList<SurveyListItem>;
    selectedSurveyIds: number[];
    getData: SurveyListGetData;
    favoriteGetData?:SurveyListGetData;
}

export const initialState: State = {
    isLoading: false,
    selectedSurveyIds: [],
    surveyList: EmptyPagedList<SurveyListItem>(),
    favoriteSurveys: EmptyPagedList<SurveyListItem>(),
    getData: {
        folder_id: 0,
        paging_info: {
            page_num: 1,
            page_size: 20
        },
        search_text: '',
        status_filter: '',
        sort_criteria: {
            sort_by: '',
            sort_order: ''
        },
        date_from: '',
        date_to: ''
    },
    favoriteGetData: {
        folder_id: 0,
        paging_info: {
            page_num: 0,
            page_size: 0
        },
        search_text: '',
        status_filter: '',
        sort_criteria: {
            sort_by: '',
            sort_order: ''
        },
        date_from: '',
        date_to: ''
    }
};
