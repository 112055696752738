import { Component, OnInit } from '@angular/core';
import { CurrentSurveyStore } from '../../../store';
import { SurveyStatus } from '../../../../infrastructure/consts/surveys.consts';
import { StorageProvider } from '../../../../infrastructure/providers';
import { UserRoles } from '../../../../infrastructure/consts/auth.consts';
import { concatMap, first, tap } from 'rxjs/operators';
import { SurveysProvider, SurveysSettingProvider } from '../../../providers';
import { ToasterService } from '../../../../infrastructure/services';
import { SurveyListDialogsService } from '../../../surveys/survey-edit/dialog-windows/survey-list-dialogs.service';
import { Survey } from '../../../../infrastructure/models';
import { Router } from '@angular/router';
import _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { YesNoDialogComponent } from '../../yes-no-dialog/yes-no-dialog.component';
@Component({
  selector: 'cb-header-level2-survey-detail',
  templateUrl: './header-level2-survey-detail.component.html',
  styleUrls: ['./header-level2-survey-detail.component.scss']
})
export class HeaderLevel2SurveyDetailComponent implements OnInit {
  public survey$ = this.currentSurveyStore.currentSurvey;

  isMenuOpened = false;
  surveyStatus = this.getStatusesArray();
  currentUserRoles: string[];
  archiveConfirmation = '';

  public get userRoles() {
    return UserRoles;
  }
  constructor(private currentSurveyStore: CurrentSurveyStore,
    private storageProvider: StorageProvider,
    private surveyListDialogs: SurveyListDialogsService,
    private surveysSettingProvider: SurveysSettingProvider,
    private surveysProvider: SurveysProvider,
    private toasterService: ToasterService,
    private router: Router,
    private translateService: TranslateService,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.storageProvider.getIdentity().pipe(first()).subscribe(_identity => {
      const identity = JSON.parse(_identity);
      this.currentUserRoles = identity.roles
        .split(',')
        .map((role: string) => role.trim());
    });
    this.translateService.get('SURVEYS-LIST.ARCHIVED-MESSAGE').subscribe((text: string) => {
      this.archiveConfirmation = text;
    });
  }
  private getStatusesArray() {
    const keys = Object.keys(SurveyStatus);
    return keys.map((key) => {
      return { key, value: SurveyStatus[key] };
    });
  }

  public appMenuOpen() {
    this.isMenuOpened = true;
  }
  public appMenuClose() {
    this.isMenuOpened = false;
  }
  public changeStatus(status: string, survey: Survey) {
    if (
      status !== survey.status &&
      status === SurveyStatus.ARCHIVED
    ) {

      this.dialog.open(YesNoDialogComponent, {
        width: '460px',
        data: {
          message: this.archiveConfirmation,
        }
      }).afterClosed().subscribe((result) => {
        if (result) {
          this.currentSurveyStore.changeStatus(status);
        }
      });

    } else if (status !== survey.status) {
      this.currentSurveyStore.changeStatus(status);
    }

  }
  public toggleFavorite() {
    this.currentSurveyStore.toggleFavorite();
  }
  public toggleLock() {
    this.currentSurveyStore.toggleLock();
  }

  hasRole(...roles: string[]): boolean {
    if (this.currentUserRoles && this.currentUserRoles.length) {
      const foundRole = this.currentUserRoles.find((r) => {
        return roles.includes(r);
      });
      return !!foundRole;
    }
    return false;
  }

  duplicateSurvey(survey: Survey) {
    this.surveyListDialogs.copySurveyDialog(survey);
  }
  exportJson(survey: Survey) {
    this.surveysSettingProvider.getLanguageSettingsById(
      survey.id.toString(), false
    ).pipe(
      first(),
      concatMap(lang => this.surveysProvider.exportSurveyJSON(survey.id, lang)),
    ).subscribe();
    this.toasterService.showInfo('SHARED.EXPORT_JSON_MESSAGE', true, null, 'outline');
  }
  saveAsTemplate(survey: Survey) {
    this.surveyListDialogs.saveAsTemplateDialog(survey);
  }
  deleteSurvey(survey: Survey) {
    this.surveyListDialogs.deleteSelectedSurveysDialog([survey.id])
      .pipe(
        tap(_ => this.currentSurveyStore.disposeSurvey()),
        tap(_ => this.router.navigate(['/surveys']))).subscribe();
  }

}
