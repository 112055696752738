import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { SurveyQuestionType } from '../../../../../infrastructure/consts/surveys.consts';
import { SharedService } from '../../../../../infrastructure/services';
import { SliderItem } from '../../../../../shared/models/survey-items/question-items/sliderItem';
import { SurveyEditorValidator } from '../../../../../infrastructure/validators/survey-editor.validators';
import { Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../../../shared/decorators/autoUnsubscribe.decorator';
import { takeUntil } from 'rxjs/operators';
import { MultipleChoicesItemComponent } from '../multiple-choices-item.component';

@Component({
    selector: 'cb-slider-item',
    templateUrl: './slider-item.component.html',
    styleUrls: ['./slider-item.component.scss']
})
@AutoUnsubscribe()
export class SliderItemComponent extends MultipleChoicesItemComponent implements OnInit, OnDestroy {
    @Input() questionItem: SliderItem;
    @Input() isMatrix: boolean;

    form: FormGroup;
    slider = SurveyQuestionType.SLIDER;
    textPosition: string;
    itemPosition: string;
    private componentDestroyed = new Subject();

    constructor(
        private fb: FormBuilder,
        private sharedService: SharedService
    ) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.createFormGroup();
        this.createQuestionItemObject();
    }

    onItemPositionSelected(value): void {
        this.itemPosition = value;
        this.form.controls['item_position'].setValue(value);
    }

    onLabelPositionSelected(value): void {
        this.textPosition = value;
        this.form.controls['question_text_position'].setValue(value);
    }

    private createFormGroup(): void {
        let isAllQuestionsRequired = false;

        if (!this.questionItem) {
            this.questionItem = <SliderItem>{};
        }

        if (!this.questionItem.id) {
            isAllQuestionsRequired = this.sharedService.surveyRespondentSettings.getValue()
                ? this.sharedService.surveyRespondentSettings.getValue().makeQuestionsRequired
                : false;
        }

        this.form = this.fb.group({
            // behavior
            alias: new FormControl(this.questionItem.alias),
            html_class: new FormControl(this.questionItem.html_class),
            is_required: new FormControl(this.questionItem.is_required || isAllQuestionsRequired),
            randomize: new FormControl(this.questionItem.randomize),
            allow_other: new FormControl(this.questionItem.allow_other),
            item_position: new FormControl(this.questionItem.item_position),
            value: new FormControl(this.questionItem.default_value),
            min_value: new FormControl(this.questionItem.min_value),
            max_value: new FormControl(this.questionItem.max_value),
            step_size: new FormControl(this.questionItem.step_size),
            value_type: new FormControl(
                this.questionItem.value_type
                    ? this.questionItem.value_type
                    : 'NumberRange'
            ),

            // appearance
            alias_position: new FormControl(this.questionItem.alias_position),
            image_position: new FormControl(this.questionItem.image_position),
            question_text_position: new FormControl(
                this.questionItem.question_text_position
            ),
            width: new FormControl(this.questionItem.width),
            show_value: new FormControl(!!this.questionItem.show_value)
        });

        this.itemPosition = this.questionItem.item_position;
        this.textPosition = this.questionItem.question_text_position;
    }

    private createQuestionItemObject(): void {
        this.updateQuestionItemObject();
        this.form.valueChanges
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(s => {
                this.updateQuestionItemObject();
            });
    }

    private updateQuestionItemObject(): void {
        const control = this.form.controls;
        const questionItem = <SliderItem>{
            item_type: SurveyQuestionType.SLIDER,
            value_type: control['value_type'].value,
            min_value: control['min_value'].value,
            max_value: control['max_value'].value,
            step_size: control['step_size'].value,
            alias: control['alias'].value,
            default_value: control['value'].value,
            html_class: control['html_class'].value,
            randomize: control['randomize'].value,
            allow_other: control['allow_other'].value,
            is_required: control['is_required'].value,
            alias_position: control['alias_position'].value,
            image_position: control['image_position'].value,
            question_text_position: control['question_text_position'].value,
            width: control['width'].value,
            show_value: control['show_value'].value,
            item_position: control['item_position'].value
        };

        // if (this.isMatrix) {
        //     questionItem.width = control['width'].value;
        // }
        this.sharedService.share('questionItemSettings', questionItem);
    }

    public onNumericKeyUp(event: any) {
        return SurveyEditorValidator.validateNumericInput(event);
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
}
