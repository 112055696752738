import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DashboardsProvider, SurveysProvider } from '../../../../providers';
import { Survey } from '../../../../models';
import { concatMap, finalize, first, flatMap } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import _ from 'lodash';
import { of } from 'rxjs';
import { ToasterService } from '../../../../../infrastructure/services';

@Component({
  selector: 'app-rename-survey-dialog',
  templateUrl: './rename-survey-dialog.component.html',
  styleUrls: ['./rename-survey-dialog.component.scss']
})
export class RenameSurveyDialogComponent implements OnInit {
  survey!: Survey;
  isLoading = false;
  public form: FormGroup = this.fb.group({
    name: ['', Validators.required],
  });

  constructor(private surveysProvider: SurveysProvider,
    private dashboardsProvider: DashboardsProvider,
    private toasterService: ToasterService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<RenameSurveyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any) { }

  ngOnInit() {
    this.isLoading = true;
    this.surveysProvider.getSurvey(this.data.surveyId)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(survey => {
        this.survey = survey;
        this.form.patchValue({ name: survey.name });
      });
  }

  onSubmit() {
    if (this.form.value.name !== this.survey.name && this.form.valid) {
      this.isLoading = true;
      this.survey.name = this.form.value.name;
      this.surveysProvider
        .updateSurvey(this.survey.id, this.survey)
        .pipe(first(),
          concatMap(() => this.renameQuickReport()),
          finalize(() => {
            this.toasterService.showSuccess('SURVEY-SETTINGS.NOTIFICATIONS.SURVEY-RENANED', true, null, 'outline');
            this.isLoading = false;
            this.dialogRef.close(true);
          }),
        )
        .subscribe();
    }
  }
  renameQuickReport() {
    return this.dashboardsProvider
      .getQuickDashboardBySurveyId(this.survey.id)
      .pipe(
        first(),
        flatMap((id) =>
          _.isNull(id)
            ? of(null)
            : this.dashboardsProvider.loadDashboardById(id)
        ),
        flatMap((report) =>
          _.isNull(report)
            ? of(null)
            : this.dashboardsProvider.updateDashboardById(
              report.id,
              { ...report, ...{ name: this.survey.name } }
            )
        )
      )
  }
  disableSubmitButton() {
    return (
        !this.form.valid ||
        (this.survey.name === this.form.value.name) ||
        this.form.value.name.trim() === ''
    );
}
  close() {
    this.dialogRef.close();
  }

}
