import {
    Component,
    Input,
    ChangeDetectionStrategy,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import { Bar100, Bar100Item } from '../../../app-admin/models/visual.model';
import * as _ from 'lodash';

@Component({
    selector: 'cb-bar100',
    templateUrl: './bar100.component.html',
    styleUrls: ['./bar100.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class Bar100Component implements OnChanges {
    @Input() barData: Bar100;
    public visualModel: Bar100Item[] = [];
    public showLegend = false;
    public showValue = true;
    public showTitle = false;
    public showResponded = false;

    ngOnChanges(changes: SimpleChanges) {
        this.createBarChart();
    }

    private createBarChart() {
        if (this.barData) {
            const reducer = (acc, item) => acc + item.value;
            const totalValue = this.barData.items.reduce(reducer, 0);
            this.visualModel = this.barData.items.map(item => {
                const itemWidth =
                    ((item.value || 0) / (totalValue * 1.01)) * 100;
                const width =
                    totalValue !== 0
                        ? `${
                              itemWidth > 0 && itemWidth < 0.3 ? 0.3 : itemWidth
                          }%`
                        : `${100 / this.barData.items.length}%`;
                return _.extend({}, item, { width });
            });
            this.showLegend = this.barData.showLegend;
            this.showValue = this.barData.showValue;
            this.showTitle = this.barData.showTitle;
            this.showResponded = this.barData.showResponded;
        }
    }
}
