import { Component, Input, OnInit } from '@angular/core';
import { SurveyEditorProvider, SurveyTemplatesProvider } from '../../../app-admin/providers';
import { ActivatedRoute } from '@angular/router';
import { SurveyPage } from '../../../app-admin/models';
import { SurveysPageTypes } from '../../../infrastructure/consts/surveys.consts';
import { LanguageItemModel } from '../../../infrastructure/models/survey-settings.model';
import { takeUntil } from 'rxjs/operators';
import { AutoUnsubscribe } from '../../decorators/autoUnsubscribe.decorator';
import { Subject } from 'rxjs';
import { SharedService } from '../../../infrastructure/services';
import { CaptchaKeys } from '../../../app-take-survey/models';
import { AddFromTemplateDialogComponent } from '../../../app-admin/surveys/survey-edit/dialog-windows/add-from-template-dialog/add-from-template-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'cb-page-edit-toolbar',
    templateUrl: './page-edit-toolbar.component.html',
    styleUrls: ['./page-edit-toolbar.component.scss']
})
@AutoUnsubscribe()
export class PageEditToolbarComponent implements OnInit {
    @Input() page: SurveyPage;
    @Input() pages: SurveyPage[];
    @Input() selectedLanguage: LanguageItemModel;
    @Input() allowJavascript: boolean;
    @Input() allowFileUpload: boolean;
    @Input() allowWebhookItem: boolean;
    @Input() softRequiredEnabled: boolean;
    isSurveyTemplate: boolean;
    public showInvisibleCaptchaQuestion: boolean;
    public showVisibleCaptchaQuestion: boolean;
    public itemType: string;
    public surveyId: string;
    public pageId: number;
    public pageTitles: any[];
    surveyPageType = SurveysPageTypes;
    id;
    private componentDestroyed = new Subject();

    constructor(
        private route: ActivatedRoute,
        private provider: SurveyEditorProvider,
        private sharedService: SharedService,
        private dialog: MatDialog
    ) {}

    ngOnInit() {
        this.isSurveyTemplate = this.route.snapshot.parent.data.isTemplate;
        this.id = this.isSurveyTemplate ? this.page.survey_template_id : this.page.survey_id;
        const selectedLanguage =
            this.selectedLanguage && this.selectedLanguage.value;
        this.provider
            .getQuestions(this.id, this.page.id, selectedLanguage, this.isSurveyTemplate)
            .subscribe(c => {
                this.page.items = c;
            });

        this.sharedService.captchaKeys
            .pipe(
                takeUntil(this.componentDestroyed)
            )
            .subscribe((keys: CaptchaKeys) => {
                this.showInvisibleCaptchaQuestion = !!keys.invisible_recaptcha_site_key;
                this.showVisibleCaptchaQuestion = !!keys.v2_recaptcha_site_key;
            });

        if (this.pages) {
            this.pageTitles = this.pages
                .filter(
                    x =>
                        x.page_type !== this.surveyPageType.HiddenItems &&
                        x.page_type !== this.surveyPageType.Completion
                )
                .map(page => ({
                    title: page.title,
                    pageId: page.id
                }));
        }
    }

    openFromTemplateDialog() {
        this.updateIds();
        this.dialog.open(
            AddFromTemplateDialogComponent,
            {
                width: '1068px',
                data: {
                    pageId: this.pageId,
                    surveyId: this.surveyId,
                    pageType: this.page.page_type
                }
            }
        );
    }

    onOpenSurveyQuestionsDialog(type) {
        this.updateIds();
        this.itemType = type;
    }

    updateIds() {
        this.route.parent.paramMap.subscribe(params => {
            this.surveyId = params.get('id');
            this.pageId = this.page.id;
        });
    }
}
