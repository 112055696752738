import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SurveyListOptions } from '../../../../models/surveys/survey-list-options.model';
import { SurveyStatus } from '../../../../../infrastructure/consts/surveys.consts';
import { convertDateToNonTimezoneString } from '../../../../../infrastructure/helpers/date.helper';

@Component({
  selector: 'cb-surveylist-filter',
  templateUrl: './survey-list-filter.component.html',
  styleUrls: ['./survey-list-filter.component.scss']
})
export class SurveylistFilterComponent implements OnInit, OnChanges {
  active = true;
  draft = true;
  archived = true;
  startDate: Date = null;
  endDate: Date = null;
  @Input() surveyOptions: SurveyListOptions = {
    searchText: '',
    statusFilters: '',
    pageNumber: 1,
    dateFrom: '',
    dateTo: ''
  };;
  @Output() apply = new EventEmitter<void>();
  @Output() reset = new EventEmitter<void>();
  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    this.optionsToModel();
  }

  ngOnInit() {
    this.optionsToModel();
  }
  
  optionsToModel(){
    if (!this.surveyOptions.statusFilters) {
      this.active = true;
      this.draft = true;
      this.archived = true;      
    }
    else {
      this.active = this.surveyOptions.statusFilters.includes(SurveyStatus.ACTIVE);
      this.draft = this.surveyOptions.statusFilters.includes(SurveyStatus.DRAFT);
      this.archived = this.surveyOptions.statusFilters.includes(SurveyStatus.ARCHIVED);
    }
    this.startDate = this.surveyOptions.dateFrom ? new Date(this.surveyOptions.dateFrom) : null;
    this.endDate = this.surveyOptions.dateTo ? new Date(this.surveyOptions.dateTo) : null;
  }

  public onFilterClick() {
    this.applyFilters();
    this.apply.emit();
  }
  public onResetClick() {
    this.applyFilters();
    this.reset.emit();
  }
  private applyFilters() {
    this.generateFilterString();
  }
  private generateFilterString() {
    this.surveyOptions.statusFilters = '';

    if (this.active) {
      this.addFilter(SurveyStatus.ACTIVE);
    }
    if (this.draft) {
      this.addFilter(SurveyStatus.DRAFT);
    }
    if (this.archived) {
      this.addFilter(SurveyStatus.ARCHIVED);
    }
    if (this.startDate && this.endDate) {
      this.surveyOptions.dateFrom = convertDateToNonTimezoneString(this.startDate);
      this.surveyOptions.dateTo = convertDateToNonTimezoneString(this.endDate);
    }
    else {
      this.surveyOptions.dateFrom = '';
      this.surveyOptions.dateTo = '';
    }
  }

  private addFilter(filter) {
    if (!this.surveyOptions.statusFilters) {
      this.surveyOptions.statusFilters += filter;
    } else {
      this.surveyOptions.statusFilters += ',' + filter;
    }
  }
}
