import { Input, Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray } from '@angular/forms';
import { SurveyDisplayType } from '../../../../../infrastructure/consts/surveys.consts';
import { SharedService } from '../../../../../infrastructure/services';
import {
    ScoringMessageItem,
    TextMessage
} from '../../../../../shared/models/survey-items/display-items/scoringMessageItem';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { AutoUnsubscribe } from '../../../../../shared/decorators/autoUnsubscribe.decorator';
import { Subject } from 'rxjs';
import { TinyRichEditorComponent } from '../../../../../shared/components/rich-editor/tiny-rich-editor.component';

@Component({
    selector: 'cb-scoring-message-item',
    templateUrl: './scoring-message-item.component.html',
    styleUrls: ['./scoring-message-item.component.scss']
})
@AutoUnsubscribe()
export class ScoringMessageItemComponent implements OnInit, OnDestroy {
    @Input() questionItem: ScoringMessageItem;
    @ViewChild('messageEditor') private messageEditor: TinyRichEditorComponent;

    form: FormGroup;
    public pageTitles: string[] = [];
    public itemPosition: string;
    public color: string;
    public items: any;
    defaultScoreScopeValue = 'survey';
    private componentDestroyed = new Subject();
    public scoringMessageHtml = '';

    constructor(
        private fb: FormBuilder,
        private sharedService: SharedService
    ) {}

    ngOnInit() {
        this.questionItem.pageTitles = _.takeWhile(
            this.questionItem.pageTitles,
            x => x.pageId !== this.questionItem.page_id
        );
        this.form = this.fb.group({
            messages: this.fb.array([]),
            score_scope_page_id: new FormControl(
                this.questionItem.score_scope_page_id
                    ? this.questionItem.score_scope_page_id
                    : null
            ),
            html_class: new FormControl(this.questionItem.html_class),
            item_position: new FormControl(this.questionItem.item_position),
            font_color: new FormControl(this.questionItem.font_color),
            font_size: new FormControl(this.questionItem.font_size)
        });
        if (this.questionItem.messages) {
            this.questionItem.messages.forEach((message: TextMessage) => {
                this.addMessage(
                    message.low_score,
                    message.high_score,
                    message.text
                );
            });
        }
        this.color = this.questionItem.font_color;
        this.itemPosition = this.questionItem.item_position;
        this.createQuestionItemObject();
        this.items = this.form.get('messages')['controls'];
    }

    private createQuestionItemObject(): void {
        this.updateQuestionItemObject();
        this.form.valueChanges
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(() => {
                this.updateQuestionItemObject();
            });
    }

    private updateQuestionItemObject(): void {
        const control = this.form.controls;
        const questionItem = new ScoringMessageItem();
        questionItem.item_type = SurveyDisplayType.SCORING_MESSAGE;
        questionItem.messages = control['messages'].value;
        questionItem.score_scope_page_id = control['score_scope_page_id'].value;
        questionItem.html_class = control['html_class'].value;
        questionItem.item_position = control['item_position'].value;
        questionItem.font_color = control['font_color'].value;
        questionItem.font_size = control['font_size'].value;
        this.sharedService.share('questionItemSettings', questionItem);
    }

    public onAddMessageClick(minScore: number, maxScore: number, text: string) {
        this.addMessage(minScore, maxScore, text);
    }

    public addMessage(minScore: number, maxScore: number, text: string): void {
        const control = new FormGroup({
            low_score: new FormControl(minScore),
            high_score: new FormControl(maxScore),
            text: new FormControl(text)
        });
        (<FormArray>this.form.get('messages')).push(control);
    }

    public onRemoveOptionClick(index) {
        const options = <FormArray>this.form.get('messages');
        options.removeAt(index);
    }

    public itemPositionSelected(value): void {
        this.itemPosition = value;
        this.form.controls['item_position'].setValue(value);
    }

    public setFormVontrolValue($event, controlName: string) {
        this.form['controls'][controlName].setValue($event);
    }

    onMergeToMessageControl(mergeCode) {
        this.messageEditor.insertMergeCode(mergeCode);
    }

    ngOnDestroy() {}
}
