<table class="bar100">
    <tr class="bar100-legend" *ngIf="showLegend">
        <td
            class="bar100-legend-item"
            *ngFor="let visualModelItem of visualModel"
        >
            <div
                [ngClass]="['bar100-legend-square', visualModelItem.colorClass]"
            ></div>
            <div class="bar100-legend-title">
                {{ visualModelItem.legendTitle | translate }}
            </div>
        </td>
        <td class="response-rate bar100-legend-item" *ngIf="showResponded">
            <div class="bar100-legend-title">
                Response Rate 
            </div>
        </td>
    </tr>    
    <tr class="bar100-container">
        <ng-container *ngFor="let visualModelItem of visualModel">
            <td
                [ngClass]="['bar100-item', visualModelItem.colorClass]"
                [style.width]="visualModelItem.width"
            >
                <span class="bar100-bar-value" *ngIf="showValue">{{
                    visualModelItem.value
                }}</span>
                <span class="bar100-bar-title" *ngIf="showTitle">{{
                    visualModelItem.title | translate
                }}</span>
            </td>            
        </ng-container>
        <td class="bar100-item bar100-lead-transparent" *ngIf="showResponded">{{barData.totalSent === 0 || barData.responded === 0 ? 0 : (100 / barData.totalSent * barData.responded).toFixed(2)}} %</td>
    </tr>
</table>
