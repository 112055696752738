import { Component, Input, OnInit } from '@angular/core';

import {
    SurveyQuestionType,
    SurveyActionType,
    SurveyDisplayType,
    SurveysPagesActions,
    SurveyItemView,
    SurveyReportType,
    EditorSurveyQuestionType
} from '../../../infrastructure/consts/surveys.consts';
import { QuestionItemDialogComponent } from '../../../app-admin/surveys/survey-edit-items/question-items/question-item-dialog/question-item-dialog.component';
import { ActionItemDialogComponent } from '../../../app-admin/surveys/survey-edit-items/action-items/action-item-dialog/action-item-dialog.component';
import { MatrixItemDialogComponent } from '../../../app-admin/surveys/survey-edit-items/question-items/matrix-item-dialog/matrix-item-dialog.component';
import { SurveyPage } from '../../../app-admin/models';
import { SharedService } from '../../../infrastructure/services';
import { SurveyQuestionOptions } from '../../../app-admin/models/page-items.model';
import { EnvironmentProvider } from '../../../infrastructure/providers/environment.provider';
import { FeatureFlagService } from '../../../shared/services/feature-flag.service';
import { questionTypeMenuFeatureFlagMap } from '../../../shared/consts/featureflag.const';

@Component({
    selector: 'cb-menu-dialog',
    templateUrl: './menu-dialog.component.html',
    styleUrls: ['./menu-dialog.component.scss']
})
export class MenuDialogComponent {
    @Input() page: SurveyPage;
    @Input() itemType: string;
    @Input() surveyId: string;
    @Input() pageTitles: any[];
    @Input() allowJavascript: boolean;
    @Input() allowFileUpload: boolean;
    @Input() allowWebhookItem: boolean;
    @Input() isSurveyTemplate: boolean;
    @Input() showInvisibleCaptchaQuestion: boolean;
    @Input() showVisibleCaptchaQuestion: boolean;
    @Input() softRequiredEnabled: boolean;

    public reportType = SurveyReportType;
    public questionType = SurveyQuestionType;
    public actionType = SurveyActionType;
    public questionView = SurveyItemView;
    public questionTypes = this.chunkArray(Object.values(EditorSurveyQuestionType), 3);
    public actionTypes = Object.values(SurveyActionType);
    public displayTypes = Object.values(SurveyDisplayType);

    constructor(private sharedService: SharedService,
        private envProvider: EnvironmentProvider, private featureService: FeatureFlagService) {
    }

    onCreateQuestionItemButtonClick(itemType: string): void {
        if (itemType === this.questionType.MATRIX) {
            this.openQuestionForm(itemType, MatrixItemDialogComponent);
        } else {
            this.openQuestionForm(itemType, QuestionItemDialogComponent);
        }
    }

    onCreateActionItemButtonClick(itemType: string): void {
        this.openQuestionForm(itemType, ActionItemDialogComponent);
    }

    onCreateDisplayOrReportItemButtonClick(itemType: string): void {
        this.openQuestionForm(itemType, QuestionItemDialogComponent);
    }

    private openQuestionForm(itemType: string, component: any): void {
        const numberOfQuestions = this.page.items.length;
        const data = {
            component,
            options: <SurveyQuestionOptions>{
                itemType,
                matrix: false,
                surveyId: this.surveyId,
                pageId: this.page.id,
                pageTitles: this.pageTitles,
                numberOfQuestions,
                isSurveyTemplate: this.isSurveyTemplate,
                softRequiredEnabled: this.softRequiredEnabled
            },
        };
        this.sharedService.share(
            SurveysPagesActions.ACTIVATE_SURVEY_ITEM,
            data
        );
    }

    chunkArray(arr, chunkCount) {
        const chunks = [];
        while (arr.length) {
            const chunkSize = Math.ceil(arr.length / chunkCount--);
            const chunk = arr.slice(0, chunkSize);
            chunks.push(chunk);
            arr = arr.slice(chunkSize);
        }
        return chunks;
    }

    checkOnHideItem(item) {
        if ((item === this.questionType.FILE_UPLOAD && !this.allowFileUpload)
        || (this.menuItemFeatureFlagOn(item))
        || (item === this.actionType.JAVASCRIPT && !this.allowJavascript)
        || (item === this.actionType.WEBHOOK && !this.allowWebhookItem)
        || (item === this.questionType.CAPTCHA && (!this.showInvisibleCaptchaQuestion && !this.showVisibleCaptchaQuestion))) {
            return false;
        }
        return true;
    }
    menuItemFeatureFlagOn(item: string){
        const featureFlag = questionTypeMenuFeatureFlagMap[item];
        if(featureFlag){
            return !this.featureService.isFeatureOn(featureFlag);
        }
        return false;
    }

}
