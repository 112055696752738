import { Observable, Subject } from 'rxjs';
import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormArray } from '@angular/forms';
import {
    SurveyExpressionsSourceType,
    SurveyExpressionsValueType
} from '../../../../../infrastructure/consts/surveys.consts';
import { RightOperandSource } from '../../../../models';
import { stripHtml } from '../../../../../infrastructure/helpers/strip-html.helper';
import {
    SurveyExpressionsProvider,
    SurveysSettingProvider
} from '../../../../providers';
import * as _ from 'lodash';
import { SharedService } from '../../../../../infrastructure/services';
import {
    defineKeySourceName,
    defineKeyValueName
} from '../../../../../infrastructure/helpers/surveys-expressions.helper';
import { DatepickerMode } from '../../../../../shared/components/date-time-picker/date-time-picker.component';
import { NgxMatMomentAdapter } from '@angular-material-components/moment-adapter';
import { first, takeUntil } from 'rxjs/operators';
import { PageExpressionsGroupsType, PageExpressionsType } from '../../../../../infrastructure/consts/surveys.consts';
import { AutoUnsubscribe } from '../../../../../shared/decorators/autoUnsubscribe.decorator';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'cb-groups-expressions-element',
    templateUrl: './groups-expressions-item.component.html',
    styleUrls: ['./groups-expressions-item.component.scss']
})
@AutoUnsubscribe()
export class GroupsExpressionsItemComponent implements OnInit, OnDestroy {
    @Input() source: any;
    @Input() pageTitles: string[];
    @Input() selectedPageId: number;
    @Input() ruleType: string;
    @Input() isTemplate: boolean;
    @Input() isSubquestion: boolean;
    @Input() parentQuestionId: number;
    languageCode: string;
    form: FormGroup;
    sourceType = SurveyExpressionsSourceType;
    defaultSourceType = this.sourceType.DEFAULT;
    questionsList: any[];
    branchingRulesExpressions: any;
    expressionJoinLogicalOperator = 'AND';
    isPageBranchingRules: boolean;
    leftOperandSourceBuffer: any;
    operators: Observable<string[]>;
    DatepickerMode = DatepickerMode;
    isSurveyTemplate: boolean;
    private componentDestroyed = new Subject();

    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private expressionsProvider: SurveyExpressionsProvider,
        private sharedService: SharedService,
        private adapter: NgxMatMomentAdapter,
        private cdr: ChangeDetectorRef,
        private surveysSettingProvider: SurveysSettingProvider
    ) {}

    ngOnInit() {
        if (!this.ruleType) {
            this.ruleType = 'ItemIncludeCondition';
        }

        this.isSurveyTemplate = this.isTemplate || (this.route.snapshot.parent && this.route.snapshot.parent.data.isTemplate);
        this.isPageBranchingRules = this.ruleType === PageExpressionsType.PAGE_BRANCHING_RULES;
        const collection = this.defineSourceGroupsCollection();
        this.form = this.fb.group({
            branching_rules: this.fb.array([])
        });
        if (this.source.id) {
            this.form.addControl('id', new FormControl(this.source.id));
        }
        this.form.valueChanges
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(s =>
                this.sharedService.share(
                    this.isSubquestion
                        ? PageExpressionsGroupsType.SUBQUESTION_EXPRESSIONS_GROUP
                        : PageExpressionsGroupsType.EXPRESSIONS_GROUP,
                    s)
            );
        this.branchingRulesExpressions = this.form.get('branching_rules')['controls'];

        this.surveysSettingProvider
            .getCurrentSurveySelectedLanguage()
            .pipe(first())
            .subscribe(data => {
                this.languageCode = data ? data.value : null;
                this.initGroups();
                if (_.isEmpty(collection)) {
                    this.addBranchingRule(this.selectedPageId, 0, 0);
                }
            });
    }

    getSurveyIdFromSource(source: any) {
        return this.isSurveyTemplate ? source.survey_template_id || source.surveyId :
             this.ruleType === 'ItemIncludeCondition'
                ? source.surveyId
                : source.survey_id;
    }

    getSurveyPageIdFromSource(source: any) {
        return this.ruleType === 'ItemIncludeCondition'
            ? source.page_id
            : source.id;
    }

    getSurveyItemIdFromSource(source: any) {
        return this.ruleType === 'ItemIncludeCondition'
            ? this.parentQuestionId || source.id || ''
            : '';
    }

    changeDatepickerFormat(answer_format) {
        if (answer_format.value && answer_format.value === 'CurrentDateROTW') {
            this.adapter.setLocale('en-GB');
        } else {
            this.adapter.setLocale('en-US');
        }
    }

    initGroups() {
        if (this.isPageBranchingRules) {
            const { branching_rules } = this.source;
            branching_rules.forEach((rule, index) => {
                this.addBranchingRule(rule.go_to_page_id, null, null, false, rule.id);
                const { groups } = rule.condition;
                this.initExpressions(index, groups);
            });
        } else {
            this.addBranchingRule(null);
            const groups = this.source.include_condition
                ? this.source.include_condition.groups
                : [];
            this.initExpressions(0, groups);
        }
    }

    initExpressions(ruleIndex, groups: any[]) {
        groups.forEach(group => {
            const groupIndex = this.addGroup(ruleIndex) - 1;
            const { expressions } = group;
            expressions.forEach((expression, index) => {
                this.addExpression(ruleIndex, groupIndex, index, expression);
            });
        });
    }

    addBranchingRule(
        go_to_page_id,
        ruleIndex = null,
        groupIndex = null,
        editMode = false,
        id = 0
    ) {
        const control = this.fb.group({
            go_to_page_id: new FormControl(go_to_page_id),
            groups: this.fb.array([])
        });
        if (id) {
            control.addControl('id', new FormControl(id));
        }
        (<FormArray>this.form.get('branching_rules')).push(control);
        if (_.isNumber(ruleIndex) && _.isNumber(groupIndex)) {
            this.addGroup(ruleIndex, 0, editMode);
        }
    }

    addGroup(ruleIndex = 0, groupIndex = 0, editMode = false) {
        const collection = this.defineSourceGroupsCollection();
        const control = this.fb.group({
            expressions: this.fb.array([]),
            logical_operator: new FormControl(
                this.expressionJoinLogicalOperator
            )
        });
        (<FormArray>(
            this.form
                .get('branching_rules')
                ['controls'][ruleIndex].get('groups')
        )).push(control);
        if (_.isEmpty(collection) || editMode) {
            this.addExpression(ruleIndex, groupIndex);
        }

        return this.form
            .get('branching_rules')
            ['controls'][ruleIndex].get('groups')['controls'].length;
    }

    addExpression(
        ruleIndex,
        groupIndex,
        expressionIndex = null,
        expression = null
    ) {
        let keySourceName;
        let keyValueName;
        if (expression) {
            this.fetchInitialExpressionsData(
                ruleIndex,
                groupIndex,
                expressionIndex,
                expression
            );
            keySourceName = defineKeySourceName(expression);
            keyValueName = defineKeyValueName(expression);
        }

        const control = this.fb.group({
            source: new FormControl(
                expression
                    ? expression.left_operand.operand_type
                    : this.defaultSourceType
            ),
            question: new FormControl(
                expression
                    ? expression.left_operand[keySourceName]
                    : this.defaultSourceType
            ),
            operator: new FormControl(
                expression ? expression.operator : this.defaultSourceType
            ),
            value: new FormControl(
                expression && expression.right_operand
                    ? expression.right_operand[keyValueName]
                    : ''
            ),
            questions_list: new FormControl([]),
            operand_type: new FormControl(''),
            expression_choices: new FormControl([]),
            is_value_type_text: new FormControl(false),
            is_value_type_date: new FormControl(false),
            is_value_type_choices: new FormControl(false)
        });
        (<FormArray>this.form
            .get('branching_rules')
            ['controls'][ruleIndex].get('groups')
            ['controls'][groupIndex].get('expressions')).push(control);
    }

    removeExpression(ruleIndex, groupIndex, expressionIndex) {
        const expressions = <FormArray>(
            this.branchingRulesExpressions[ruleIndex]
                .get('groups')
                ['controls'][groupIndex].get('expressions')
        );
        const expressionsLength = (<FormArray>(
            this.branchingRulesExpressions[ruleIndex]
                .get('groups')
                ['controls'][groupIndex].get('expressions')
        )).length;
        const groups = <FormArray>(
            this.branchingRulesExpressions[ruleIndex].get('groups')
        );
        if (expressionsLength > 1) {
            expressions.removeAt(expressionIndex);
        } else if (groups.length > 1) {
            groups.removeAt(groupIndex);
        } else {
            expressions.removeAt(expressionIndex);
            const control = this.fb.group({
                source: new FormControl(this.defaultSourceType),
                question: new FormControl(this.defaultSourceType),
                operator: new FormControl(this.defaultSourceType),
                value: new FormControl(''),
                questions_list: new FormControl([]),
                operand_type: new FormControl(''),
                expression_choices: new FormControl([]),
                is_value_type_text: new FormControl(false),
                is_value_type_date: new FormControl(false),
                is_value_type_choices: new FormControl(false)
            });
            expressions.push(control);
        }
    }

    fetchInitialExpressionsData(
        ruleIndex,
        groupIndex,
        expressionIndex = null,
        expression = null
    ) {
        const survey_id = this.getSurveyIdFromSource(this.source);
        const keySourceName =
            expression.left_operand[defineKeySourceName(expression)];
        const { operand_type } = expression.left_operand;
        this.setupLeftSource(
            operand_type,
            ruleIndex,
            groupIndex,
            expressionIndex
        );
        this.getOperators(survey_id, operand_type, keySourceName);
        if (expression.right_operand) {
            this.getExpressionValue(
                survey_id,
                operand_type,
                keySourceName,
                expression.operator,
                ruleIndex,
                groupIndex,
                expressionIndex
            );
        }
    }

    getExpressionsLength(group) {
        return group.get('expressions').controls.length;
    }

    getFormControlValue(
        formControlName: string,
        ruleIndex,
        groupIndex,
        expressionIndex
    ) {
        return this.branchingRulesExpressions[ruleIndex]
            .get('groups')
            ['controls'][groupIndex].get('expressions')
            .controls[expressionIndex].get(formControlName).value;
    }

    setFormControlValue(
        formControlName: string,
        value,
        ruleIndex,
        groupIndex,
        expressionIndex
    ) {
        return this.branchingRulesExpressions[ruleIndex]
            .get('groups')
            ['controls'][groupIndex].get('expressions')
            .controls[expressionIndex].get(formControlName)
            .setValue(value);
    }

    setDefaultControlValue(
        formControlName: string,
        ruleIndex,
        groupIndex,
        expressionIndex
    ) {
        this.branchingRulesExpressions[ruleIndex]
            .get('groups')
            ['controls'][groupIndex].get('expressions')
            .controls[expressionIndex].get(formControlName)
            .setValue(this.defaultSourceType);
    }

    defineSourceGroupsCollection() {
        return this.isPageBranchingRules
            ? this.source.branching_rules
            : this.source.include_condition
            ? this.source.include_condition.groups
            : [];
    }

    isDefaultTypeDefined(
        formControlName: string,
        ruleIndex,
        groupIndex: number,
        expressionIndex: number
    ) {
        return (
            this.branchingRulesExpressions[ruleIndex]
                .get('groups')
                ['controls'][groupIndex].get('expressions')
                .controls[expressionIndex].get(formControlName).value !==
            this.defaultSourceType
        );
    }

    isSourceTypeDefined(
        ruleIndex,
        groupIndex: number,
        expressionIndex: number
    ) {
        return this.isDefaultTypeDefined(
            'source',
            ruleIndex,
            groupIndex,
            expressionIndex
        );
    }

    isQuestionDefined(ruleIndex, groupIndex: number, expressionIndex: number) {
        return (
            this.isSourceTypeDefined(ruleIndex, groupIndex, expressionIndex) &&
            this.isDefaultTypeDefined(
                'question',
                ruleIndex,
                groupIndex,
                expressionIndex
            )
        );
    }

    isOperatorDefined(ruleIndex, groupIndex: number, expressionIndex: number) {
        return (
            this.isQuestionDefined(ruleIndex, groupIndex, expressionIndex) &&
            this.isDefaultTypeDefined(
                'operator',
                ruleIndex,
                groupIndex,
                expressionIndex
            )
        );
    }

    onSourceTypeChanged($event, ruleIndex, groupIndex, expressionIndex) {
        this.setDefaultControlValue(
            'question',
            ruleIndex,
            groupIndex,
            expressionIndex
        );
        this.setupLeftSource(
            $event.value,
            ruleIndex,
            groupIndex,
            expressionIndex
        );
    }

    setupLeftSource(flag, ruleIndex, groupIndex, expressionIndex) {
        switch (flag) {
            case this.sourceType.QUESTION:
                this.getLeftOperandSource(
                    this.source,
                    ruleIndex,
                    groupIndex,
                    expressionIndex,
                    'questionAttributes'
                );
                break;
            case this.sourceType.USER_ATTRIBUTE:
                this.getLeftOperandSource(
                    this.source,
                    ruleIndex,
                    groupIndex,
                    expressionIndex,
                    'userAttributes'
                );
                break;
            case this.sourceType.RESPONSE_PROPERTY:
                this.getLeftOperandSource(
                    this.source,
                    ruleIndex,
                    groupIndex,
                    expressionIndex,
                    'responseAttributes'
                );
                break;
            case this.sourceType.DEFAULT:
                break;
        }
    }

    onQuestionChanged($event, ruleIndex, groupIndex, expressionIndex) {
        this.changeDatepickerFormat($event);
        this.setDefaultControlValue(
            'operator',
            ruleIndex,
            groupIndex,
            expressionIndex
        );
        if ($event.value === this.sourceType.DEFAULT) {
            return;
        }
        const survey_id = this.getSurveyIdFromSource(this.source);
        const left_operand_type = this.getFormControlValue(
            'source',
            ruleIndex,
            groupIndex,
            expressionIndex
        );
        const left_operand_value = $event.value;
        this.getOperators(survey_id, left_operand_type, left_operand_value);
    }

    getOperators(survey_id, left_operand_type, left_operand_value) {
        this.operators = this.expressionsProvider.getOperators(
            survey_id,
            left_operand_type,
            left_operand_value,
            this.isSurveyTemplate
        );
    }

    onOperatorChanged($event, ruleIndex, groupIndex, expressionIndex) {
        this.branchingRulesExpressions[ruleIndex]
            .get('groups')
            ['controls'][groupIndex].get('expressions')
            .controls[expressionIndex].get('value')
            .setValue('');
        [
            'is_value_type_text',
            'is_value_type_date',
            'is_value_type_choices'
        ].forEach(val => {
            this.setFormControlValue(
                val,
                false,
                ruleIndex,
                groupIndex,
                expressionIndex
            );
        });

        if ($event.value === this.sourceType.DEFAULT) {
            return;
        }
        const survey_id = this.getSurveyIdFromSource(this.source);
        const left_operand_type = this.getFormControlValue(
            'source',
            ruleIndex,
            groupIndex,
            expressionIndex
        );
        const left_operand_value = this.getFormControlValue(
            'question',
            ruleIndex,
            groupIndex,
            expressionIndex
        );
        const operator = $event.value;
        this.getExpressionValue(
            survey_id,
            left_operand_type,
            left_operand_value,
            operator,
            ruleIndex,
            groupIndex,
            expressionIndex
        );
    }

    getExpressionValue(
        survey_id,
        left_operand_type,
        left_operand_value,
        operator,
        ruleIndex,
        groupIndex,
        expressionIndex
    ) {
        this.expressionsProvider
            .getExpressionValueData(
                survey_id,
                left_operand_type,
                left_operand_value,
                operator,
                this.languageCode,
                this.isSurveyTemplate
            )
            .subscribe(data => {
                this.defineExpressionfieldType(
                    data,
                    ruleIndex,
                    groupIndex,
                    expressionIndex
                );
                this.cdr.detectChanges();
            });
    }

    defineExpressionfieldType(
        data: RightOperandSource,
        ruleIndex,
        groupIndex,
        expressionIndex
    ) {
        this.setFormControlValue(
            'operand_type',
            data.operand_type,
            ruleIndex,
            groupIndex,
            expressionIndex
        );
        switch (data.data_type) {
            case SurveyExpressionsValueType.DEFAULT:
            case SurveyExpressionsValueType.TEXT:
                this.setFormControlValue(
                    'is_value_type_text',
                    true,
                    ruleIndex,
                    groupIndex,
                    expressionIndex
                );
                break;
            case SurveyExpressionsValueType.DATE:
                this.setFormControlValue(
                    'is_value_type_date',
                    true,
                    ruleIndex,
                    groupIndex,
                    expressionIndex
                );
                break;
            case SurveyExpressionsValueType.CHOICES:
                this.setFormControlValue(
                    'is_value_type_choices',
                    true,
                    ruleIndex,
                    groupIndex,
                    expressionIndex
                );
                this.setFormControlValue(
                    'expression_choices',
                    data.choices,
                    ruleIndex,
                    groupIndex,
                    expressionIndex
                );
                break;
            default:
                break;
        }
    }

    getLeftOperandSource(
        source: any,
        ruleIndex,
        groupIndex,
        expressionIndex,
        attributeName: string
    ) {
        const survey_id = this.getSurveyIdFromSource(source);
        const page_id = this.getSurveyPageIdFromSource(source);
        const item_id = this.getSurveyItemIdFromSource(source);
        const language_code = this.languageCode || '';

        if (this.leftOperandSourceBuffer) {
            this.setQuestionListFormControl(
                attributeName,
                this.leftOperandSourceBuffer,
                ruleIndex,
                groupIndex,
                expressionIndex
            );
        } else {
            this.expressionsProvider
                .getLeftOperands(
                    survey_id,
                    page_id,
                    item_id,
                    this.ruleType,
                    language_code,
                    this.isSurveyTemplate
                )
                .subscribe(data => {
                    this.leftOperandSourceBuffer = data;
                    this.setQuestionListFormControl(
                        attributeName,
                        data,
                        ruleIndex,
                        groupIndex,
                        expressionIndex
                    );
                    this.cdr.detectChanges();
                });
        }
    }

    isAnsweredComparisonType(ruleIndex, groupIndex, expressionIndex) {
        const operator = this.getFormControlValue(
            'operator',
            ruleIndex,
            groupIndex,
            expressionIndex
        );
        if (operator === 'Answered' || operator === 'NotAnswered') {
            return true;
        }
    }

    setQuestionListFormControl(
        attributeName: string,
        source: any,
        ruleIndex,
        groupIndex,
        expressionIndex
    ) {
        this.setFormControlValue(
            'questions_list',
            this.defineLeftSource(attributeName, source),
            ruleIndex,
            groupIndex,
            expressionIndex
        );
    }

    defineLeftSource(attributeName: string, source: any) {
        switch (attributeName) {
            case 'questionAttributes':
                return _.chain(source.items)
                    .map(x => ({ key: x.id, name: x.text }))
                    .forEach(x => {
                        x.name = stripHtml(x.name);
                    })
                    .value();
            case 'userAttributes':
                return source.profile_properties;
            case 'responseAttributes':
                return source.response_properties;
            default:
                break;
        }
    }

    ngOnDestroy() {}
}
