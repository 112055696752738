<form [formGroup]="form" autocomplete="off">
    <div class="form-section">
        <cb-question-item-title></cb-question-item-title>
        <div class="cb-change-type-section">
            <cb-item-type [itemType]="radiobuttons"></cb-item-type>
        </div>
        <cb-question-and-subtext
            [questionItem]="questionItem"
            [isMatrix]="isMatrix"
        ></cb-question-and-subtext>
    </div>
    <div class="form-section">
        <cb-multiple-options-item
            [surveyId]="questionItem.surveyId"
            [pageId]="questionItem.page_id"
            [options]="questionItem.choices | pureOptionsOnly"
            [expanded]="true"
            [questionType]="radiobuttons"
            [resetChoicesEvent] = "resetChoicesSubject.asObservable()"
            [isMatrix]="isMatrix"
            [isSurveyTemplate] = "isSurveyTemplate"
        ></cb-multiple-options-item>
    </div>
    
    <cb-behavior-options [parentForm]="form" [softRequiredEnabled]="questionItem.softRequiredEnabled">
        <div *ngIf="!isMatrix">
            <div class="form-fields-group">
                <mat-checkbox
                    class="cb-form-field"
                    formControlName="randomize"
                >
                    {{ 'SURVEY-EDITOR.SHARED.RANDOMIZE' | translate }}
                </mat-checkbox>
                <mat-checkbox
                    class="cb-form-field"
                    formControlName="allow_other"
                    #allow_other
                >
                    {{ 'SURVEY-EDITOR.SHARED.ALLOW-OTHER' | translate }}
                </mat-checkbox>
                <span class="cb-form-field" *ngIf="allow_other.checked">
                    <span 
                        class="text-preview"
                        [innerHTML]="otherValue"
                        (click)="editItem(ref)"
                        *ngIf="otherValue && otherValue.length && !editOther">
                    </span>
                    <mat-form-field 
                        *ngIf="editOther || !otherValue ||  !otherValue.length">
                        <input 
                            (focusout)="editOther = false"
                            matInput
                            placeholder="{{
                                'SURVEY-EDITOR.SHARED.ALLOW-OTHER-TEXT'
                                    | translate
                            }}"
                            formControlName="other_value"
                            (click)="editOther = true"
                        />
                    </mat-form-field>
                    <cb-html-options
                        #ref
                        [index]="'other_value'"
                        [form]="form"
                        [pageId]="pageId"
                        [surveyId]="surveyId"
                        [isField]="true"
                    ></cb-html-options>
                </span>
            </div>
        </div>
    </cb-behavior-options>

    <div class="form-section border-top" *ngIf="!isMatrix">
        <cb-question-collapsed-panel
            title="{{
                'SURVEY-EDITOR.SHARED.APPEARANCE.APPEARANCE' | translate
            }}"
        >
            <div class="form-group-position">
                <cb-label-position
                    class="position-control-group"
                    [selectedOption]="textPosition"
                    (selected)="labelPositionSelected($event)"
                ></cb-label-position>
                <cb-item-position
                    class="position-control-group"
                    [selectedOption]="itemPosition"
                    (selected)="itemPositionSelected($event)"
                ></cb-item-position>
            </div>
            <div class="form-fields-group">
                <mat-form-field class="cb-form-field">
                    <mat-select
                        placeholder="{{
                            'SURVEY-EDITOR.SHARED.APPEARANCE.LAYOUT' | translate
                        }}"
                        formControlName="layout"
                    >
                        <mat-option value="Vertical"
                            >{{
                                'SURVEY-EDITOR.SHARED.APPEARANCE.VERTICAL'
                                    | translate
                            }}
                        </mat-option>
                        <mat-option value="Horizontal">
                            {{
                                'SURVEY-EDITOR.SHARED.APPEARANCE.HORIZONTAL'
                                    | translate
                            }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="cb-form-field">
                    <input
                        matInput
                        type="number"
                        placeholder="{{
                            'SURVEY-EDITOR.SHARED.APPEARANCE.COLUMNS'
                                | translate
                        }}"
                        (keypress)="onNumericKeyUp($event)"
                        formControlName="columns"
                        min="0"
                    />
                </mat-form-field>
            </div>
            <div class="form-fields-group">
                <mat-checkbox
                    class="cb-form-field"
                    formControlName="show_number_labels"
                >
                    {{
                        'SURVEY-EDITOR.SHARED.APPEARANCE.SHOW-NUMBER-LABELS'
                            | translate
                    }}
                </mat-checkbox>
            </div>
        </cb-question-collapsed-panel>
    </div>

    <div class="form-section" *ngIf="!isMatrix">
        <cb-question-collapsed-panel
            title="{{ 'SURVEY-EDITOR.SHARED.CONDITIONS' | translate }}"
            [tooltip]="'SURVEY-EDITOR.SHARED.TOOLTIPS.CONDITIONS' | translate"
        >
            <cb-groups-expressions-element
                [source]="questionItem"
            ></cb-groups-expressions-element>
        </cb-question-collapsed-panel>
    </div>
</form>
