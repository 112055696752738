import { OnInit, Component, Input, OnDestroy } from '@angular/core';
import { SignatureItem } from '../../../../../shared/models/survey-items/question-items/signatureItem';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { SurveyQuestionType } from '../../../../../infrastructure/consts/surveys.consts';
import { SharedService } from '../../../../../infrastructure/services';
import { SurveyEditorValidator } from '../../../../../infrastructure/validators/survey-editor.validators';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../../../shared/decorators/autoUnsubscribe.decorator';

@Component({
    selector: 'cb-signature-item',
    templateUrl: './signature-item.component.html',
    styleUrls: ['./signature-item.component.scss']
})
@AutoUnsubscribe()
export class SignatureItemComponent implements OnInit, OnDestroy {
    @Input() questionItem: SignatureItem;
    form: FormGroup;
    signature = SurveyQuestionType.SIGNATURE;
    itemPosition: string;
    textPosition: string;
    color: string;
    private componentDestroyed = new Subject();

    constructor(
        private fb: FormBuilder,
        private sharedService: SharedService
    ) {}

    ngOnInit() {
        this.createFormGroup();
        this.createQuestionItemObject();
    }

    itemPositionSelected(value): void {
        this.itemPosition = value;
        this.form.controls['item_position'].setValue(value);
    }

    labelPositionSelected(value): void {
        this.textPosition = value;
        this.form.controls['question_text_position'].setValue(value);
    }

    private createFormGroup(): void {
        let isAllQuestionsRequired = false;
        if (!this.questionItem) {
            this.questionItem = <SignatureItem>{};
        }
        if (!this.questionItem.id) {
            isAllQuestionsRequired = this.sharedService.surveyRespondentSettings.getValue() ?
            this.sharedService.surveyRespondentSettings.getValue().makeQuestionsRequired : false;
        }
        this.form = this.fb.group({
            alias: new FormControl(this.questionItem.alias),
            html_class: new FormControl(this.questionItem.html_class),
            is_required: new FormControl(this.questionItem.is_required || isAllQuestionsRequired),
            is_soft_required: new FormControl(this.questionItem.softRequiredEnabled ? !!this.questionItem.is_soft_required : false),
            item_position: new FormControl(this.questionItem.item_position),
            question_text_position: new FormControl(
                this.questionItem.question_text_position
            ),
            width: new FormControl(
                this.questionItem.width ? this.questionItem.width : '250'
            ),
            height: new FormControl(
                this.questionItem.height ? this.questionItem.height : '250'
            ),
            pen_color: new FormControl(
                this.questionItem.pen_color
                    ? this.questionItem.pen_color
                    : '#000'
            )
        });

        this.itemPosition = this.questionItem.item_position;
        this.textPosition = this.questionItem.question_text_position;
        this.color = this.questionItem.pen_color
            ? this.questionItem.pen_color
            : '#000';
    }

    private createQuestionItemObject(): void {
        this.updateQuestionItemObject();
        this.form.valueChanges.pipe(
            takeUntil(this.componentDestroyed),
            debounceTime(0)
        ).subscribe(s => {
            // workaround used! consider refactor of this part code to rely OR on template-driven forms or on reactive-driven forms.
            // ValueChanges is fired before model change does, it can cause some sort of data update delay in some components.
            // debounceTime(0) should fix it, but it still relies on execution order, which can not be guaranteed.
            this.updateQuestionItemObject();
        });
    }

    private updateQuestionItemObject(): void {
        const control = this.form.controls;
        const questionItem = new SignatureItem();
        questionItem.item_type = SurveyQuestionType.SIGNATURE;
        questionItem.is_required = control['is_required'].value;
        questionItem.is_soft_required = control['is_soft_required'].value;
        questionItem.alias = control['alias'].value;
        questionItem.html_class = control['html_class'].value;
        questionItem.width = control['width'].value;
        questionItem.height = control['height'].value;
        questionItem.pen_color = this.color;
        questionItem.question_text_position = control['question_text_position'].value;
        questionItem.item_position = control['item_position'].value;

        this.sharedService.share('questionItemSettings', questionItem);
    }

    public onNumericKeyUp(event: any) {
        return SurveyEditorValidator.validateNumericInput(event);
    }

    ngOnDestroy() {}
}
