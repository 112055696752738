import { CaptchaItem } from '../../../../../shared/models/survey-items/question-items/captchaItem';
import { MatrixSumTotalItem } from '../../../../../shared/models/survey-items/question-items/sumTotalItem';
import { FileUploadItem } from '../../../../../shared/models/survey-items/question-items/fileUploadItem';
import { SignatureItem } from '../../../../../shared/models/survey-items/question-items/signatureItem';
import { RankOrderItem } from '../../../../../shared/models/survey-items/question-items/rankOrder';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { SharedService } from '../../../../../infrastructure/services';
import {
    SurveyQuestionType,
    SurveyDisplayType,
    SurveysPagesActions,
    SurveyReportType
} from '../../../../../infrastructure/consts/surveys.consts';
import { SingleLineItem } from '../../../../../shared/models/survey-items/question-items/singleLineItem';
import { MessageItem } from '../../../../../shared/models/survey-items/display-items/messageItem';
import { MultilinesItem } from '../../../../../shared/models/survey-items/question-items/multilinesItem';
import { DropdownListItem } from '../../../../../shared/models/survey-items/question-items/dropdownListItem';
import { CustomSourceDropdownListItem } from '../../../../../shared/models/survey-items/question-items/customSourceDropdownListItem';
import { CheckboxesItem } from '../../../../../shared/models/survey-items/question-items/checkboxesItem';
import { NetPromoterScoreItem } from '../../../../../shared/models/survey-items/question-items/netPromoterScoreItem';
import { RadiobuttonsItem } from '../../../../../shared/models/survey-items/question-items/radiobuttonsItem';
import { SliderItem } from '../../../../../shared/models/survey-items/question-items/sliderItem';
import { RatingItem } from '../../../../../shared/models/survey-items/question-items/ratingItem';
import { ImageChoiceItem } from '../../../../../shared/models/survey-items/question-items/imageChoiceItem';
import { RatingScaleItem } from '../../../../../shared/models/survey-items/question-items/ratingScaleItem';
import { MaxDiffItem } from '../../../../../shared/models/survey-items/question-items/maxDiffItem';
import { composeExpressionsCondition } from '../../../../../infrastructure/helpers/surveys-expressions.helper';
import { SurveyEditorValidator } from '../../../../../infrastructure/validators/survey-editor.validators';
import { SurveyQuestionOptions } from '../../../../models/page-items.model';
import { ScoringMessageItem } from '../../../../../shared/models/survey-items/display-items/scoringMessageItem';
import { ResponseSummaryItem } from '../../../../../shared/models/survey-items/report-items/responseSummaryItem';
import * as _ from 'lodash';
import { ValidationObject } from '../../../../../infrastructure/models';
import { Subject, Subscription } from 'rxjs';
import { PageExpressionsGroupsType } from '../../../../../infrastructure/consts/surveys.consts';
import { DialogsService } from '../../../../../infrastructure/services/dialogs.service';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { SurveysProvider } from '../../../../providers';
import { ContactFormItem } from '../../../../../shared/models/survey-items/question-items/contactFormItem';
import { DrillDownItem } from '../../../../../shared/models/survey-items/question-items/drillDownItem';

@Component({
    selector: 'cb-question-item-dialog',
    templateUrl: './question-item-dialog.component.html',
    styleUrls: ['./question-item-dialog.component.scss']
})
export class QuestionItemDialogComponent implements OnInit, OnDestroy {
    @Input() options: SurveyQuestionOptions;
    public questionType = SurveyQuestionType;
    public displayType = SurveyDisplayType;
    public reportType = SurveyReportType;
    public itemType: string;
    public questionItemData: any = {};
    public choices: any[] = [];
    public branching_rules: any[];
    public questionItemSettings: any = {};
    public editMode: boolean;
    public isMatrix: boolean;
    public isUploading: boolean;
    public isSaveButtonClicked = false;
    public columns: any[];
    public matrixColumnUnique: boolean;
    public matrixColumnWidth: number;
    public columnOptions: any;
    public validationMessages: ValidationObject[] = [];
    public questionTextData: any = {};
    public resetChoicesSubject: Subject<void> = new Subject<void>();
    subscriptions: { [key: string]: Subscription } = {};
    isSurveyTemplate: boolean;
    private drillDownItem: DrillDownItem;

    private componentDestroyed = new Subject();

    translates = {
        textConfirmChoicesDelete: 'DIALOG.CONFIRM-CHOICES-DELETE',
        textDeleteChoicesInfo: 'DIALOG.DELETE-CHOICES-MESSAGE'
    };

    constructor(
        private sharedService: SharedService,
        private translateService: TranslateService,
        private dialogsService: DialogsService,
        private surveysProvider: SurveysProvider) {}

    ngOnInit() {
        this.isSurveyTemplate = this.options.isSurveyTemplate;
        this.shareServiceSubscriptions();
        this.setOptions();
        this.getTranslates();
    }

    private setOptions() {
        if (this.options) {
            this.itemType = this.options.itemType;
            this.isMatrix = this.options.matrix;

            this.matrixColumnUnique = this.options.require_unique_answers;
            this.matrixColumnWidth = this.options.width;

            this.editMode = !!this.options.itemData;
            if (this.editMode) {
                this.questionItemData = this.options.itemData;
                this.questionItemSettings.data = this.options.itemData;
                this.columnOptions = {
                    unique_answer: this.options.require_unique_answers,
                    width: this.options.width
                };
            }

            this.questionItemData.enabled = this.options.itemData ? this.options.itemData.enabled : true;
            this.questionItemData.surveyId = this.options.surveyId;
            this.questionItemData.page_id = this.options.pageId;
            this.questionItemData.pageTitles = this.options.pageTitles;
            this.questionItemData.softRequiredEnabled = this.options.softRequiredEnabled;
        }
    }

    private getTranslates() {
        this.translateService
            .get(Object.values(this.translates))
            .subscribe((data) => {
                for (const key in this.translates) {
                    if (this.translates.hasOwnProperty(key)) {
                        this.translates[key] = data[this.translates[key]];
                    }
                }
            });
    }

    private isValid(itemData) {
        const errors = SurveyEditorValidator.validate(itemData);
        this.validationMessages = _.uniqBy(errors, 'validationMessage');
        return errors.length === 0;
    }

    private questionItemInit() {
        const itemData = this.createQuestionItem();
        if (this.editMode) {
            itemData.id = this.options.itemData.id;
            if (this.isMatrix) {
                itemData.position = this.options.itemData.position;
            }
        } else {
            itemData.position = this.options.numberOfQuestions + 1;
        }
        return itemData;
    }

    private createQuestionItem() {
        switch (this.itemType) {
            case this.questionType.SINGLE_LINE_TEXT:
                const singleLineItem = new SingleLineItem();
                this.initItem(singleLineItem);
                return singleLineItem;
            case this.questionType.MULTI_LINE_TEXT:
                const multilinesItem = new MultilinesItem();
                this.initItem(multilinesItem);
                return multilinesItem;
            case this.questionType.DROPDOWNLIST:
                const dropdownListItem = new DropdownListItem();
                dropdownListItem.choices = this.getChoices();
                this.initItem(dropdownListItem);
                return dropdownListItem;
            case this.questionType.DRILL_DOWN:
                const drillDownItem = new DrillDownItem();
                this.initItem(drillDownItem);
                return drillDownItem;
            case this.questionType.CUSTOM_SOURCE_DROPDOWNLIST:
                const customSourceDropdownListItem = new CustomSourceDropdownListItem();
                this.initItem(customSourceDropdownListItem);
                return customSourceDropdownListItem;
            case this.questionType.CHECKBOXES:
                const checkboxesItem = new CheckboxesItem();
                checkboxesItem.choices = this.getChoices();
                this.initItem(checkboxesItem);
                return checkboxesItem;
            case this.questionType.NET_PROMOTER_SCORE:
                const netPromoterScoreItem = new NetPromoterScoreItem();
                this.initItem(netPromoterScoreItem);
                return netPromoterScoreItem;
            case this.questionType.RADIOBUTTONS:
                const radiobuttonsItem = new RadiobuttonsItem();
                radiobuttonsItem.choices = this.getChoices();
                this.initItem(radiobuttonsItem);
                return radiobuttonsItem;
            case this.questionType.IMAGE_CHOICE:
                const imageChoiceItem = new ImageChoiceItem();
                imageChoiceItem.choices = this.getChoices();
                this.initItem(imageChoiceItem);
                return imageChoiceItem;
            case this.questionType.SLIDER:
                const sliderItem = new SliderItem();
                sliderItem.choices = this.getChoices();
                this.initItem(sliderItem);
                return sliderItem;
            case this.questionType.SUM_TOTAL:
                const sumTotalItem = new MatrixSumTotalItem();
                this.initItem(sumTotalItem);
                return sumTotalItem;
            case this.questionType.CAPTCHA:
                const captchaItem = new CaptchaItem();
                this.initItem(captchaItem);
                return captchaItem;
            case this.questionType.RATING:
                const ratingItem = new RatingItem();
                ratingItem.choices = this.getChoices();
                this.initItem(ratingItem);
                return ratingItem;
            case this.questionType.RATINGSCALE:
                const ratingScaleItem = new RatingScaleItem();
                ratingScaleItem.choices = this.getUserChoices();
               this.initItem(ratingScaleItem);
                return ratingScaleItem;
            case this.questionType.RANKORDER:
                const rankOrderItem = new RankOrderItem();
                rankOrderItem.choices = this.getChoices();
                this.initItem(rankOrderItem);
                return rankOrderItem;
            case this.questionType.MAXDIFF:
                const maxDiffItem = new MaxDiffItem();
                maxDiffItem.choices = this.getChoices();
                this.initItem(maxDiffItem);
                return maxDiffItem;
            case this.questionType.SIGNATURE:
                const signatureItem = new SignatureItem();
                this.initItem(signatureItem);
                return signatureItem;
            case this.questionType.FILE_UPLOAD:
                const fileUploadItem = new FileUploadItem();
                this.initItem(fileUploadItem);
                return fileUploadItem;
            case this.questionType.CONTACT_FORM:
                const contactFormItem = new ContactFormItem();
                this.initItem(contactFormItem);
                return contactFormItem;
            case this.displayType.MESSAGE:
                const messageItem = new MessageItem();
                this.initDisplayItem(messageItem);
                return messageItem;
            case this.displayType.IMAGE:
                const imageItem = new MessageItem();
                this.initDisplayItem(imageItem);
                return imageItem;
            case this.displayType.SCORING_MESSAGE:
                const scoringItem = new ScoringMessageItem();
                this.initDisplayItem(scoringItem);
                return scoringItem;
            case this.reportType.SUMMARY:
                const reportItem = new ResponseSummaryItem();
                this.initItem(reportItem);
                return reportItem;
        }
    }

    private initDisplayItem(itemData: any): void {
        this.getQuestionSettings(itemData);

        itemData.page_id = this.options.pageId;
        itemData.item_type = this.itemType;
        itemData.text = this.questionTextData.text;
        itemData.enabled = this.questionItemData.enabled;
        if (!this.isMatrix && this.branching_rules) {
            itemData.include_condition = composeExpressionsCondition(
                this.branching_rules,
                true
            );
        }
    }

    private initItem(itemData: any): void {
        this.getQuestionSettings(itemData);
        itemData.page_id = this.options.pageId;
        itemData.item_type = this.itemType;
        itemData.question_text = this.questionTextData.question_text;
        itemData.subtext = this.questionTextData.subtext;
        itemData.enabled = this.questionItemData.enabled;
        if (this.columnOptions && this.isMatrix) {
            itemData.require_unique_answers = this.columnOptions.unique_answer;
            itemData.width = this.columnOptions.width;
        }
        if (!this.isMatrix && this.branching_rules) {
            itemData.include_condition = composeExpressionsCondition(
                this.branching_rules,
                true
            );
        }
        itemData.is_soft_required = (itemData.is_soft_required ?? this.questionItemData.is_soft_required) ?? false;
    }

    private getUserChoices(): any[] {
        const filteredChoices = _.filter(
            this.choices,
            c => c.text || c.image_id || !_.isNil(c.points)
        );
        return filteredChoices;
    }


    private getChoices(): any[] {
        const filteredChoices = _.filter(
            this.choices,
            c => c.text || c.image_id || !_.isNil(c.points)
        );
        const oldSpecialChoices = _.filter(
            this.questionItemData.choices,
            c => c.is_none_of_above || c.is_other
        );
        return _.concat(filteredChoices, oldSpecialChoices);
    }

    private getQuestionSettings(itemData: any): void {
        if (this.questionItemSettings && this.questionItemSettings.data) {

            Object.keys(this.questionItemSettings.data).forEach(key => {
                if (key !== 'choices') {
                    itemData[key] = this.questionItemSettings.data[key];
                }
            });
        }
    }

    subscribeTo(subscriptionChannel: string, callback: Function) {
        this.subscriptions[subscriptionChannel] = this.sharedService
            .getData(subscriptionChannel)
            .subscribe((data) => {
                callback(data);
            });
    }

    private shareServiceSubscriptions(): void {
        this.subscribeTo('questionItem', question => {
            this.questionTextData = question.data;
        });
        this.subscribeTo(PageExpressionsGroupsType.EXPRESSIONS_GROUP, gr => {
            if (gr.data.id === _.get(this.options, 'itemData.id')) {
                this.branching_rules = gr.data.branching_rules;
            }
        });
        this.subscribeTo('multipleOptions', multipleOptions => {
            this.choices = multipleOptions.data.options;
        });
        this.subscribeTo('questionItemSettings', settings => {
            console.log('settings', settings)
            this.questionItemSettings = settings;
        });
        this.subscribeTo('matrixColumns', matrixColumns => {
            this.columns = matrixColumns.data.columns;
        });
        this.subscribeTo('columnOptions', options => {
            this.columnOptions = options.data;
        });
        this.subscribeTo('newDrillDownItem', event => {
            this.drillDownItem = event.data;
        });
    }

    onQuestionItemUpload(uploadStatus) {
        this.isUploading = uploadStatus;
    }

    public onQuestionItemSaveClick() {
        const itemData = this.questionItemInit();
        if (!this.isValid(itemData)) {
            return;
        }

        const oldChoices = this.questionItemData.choices;
        const isNps = itemData instanceof NetPromoterScoreItem;
        const showDeleteChoicesWarning = !this.isSurveyTemplate && !this.isMatrix && !isNps && oldChoices && this.anyChoicesRemoved(oldChoices);
        if (showDeleteChoicesWarning) {
            this.surveysProvider.checkSurveyIsAlive(+this.options.surveyId)
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(isAlive => {
                if (isAlive) {
                    this.showDeleteChoicesWarningDialog(itemData);
                } else {
                    this.save(itemData);
                }
            });
        } else {
            this.save(itemData);
        }
    }

    private showDeleteChoicesWarningDialog(itemData) {
        this.dialogsService
        .confirm(this.translates.textConfirmChoicesDelete, this.translates.textDeleteChoicesInfo, '')
        .pipe(takeUntil(this.componentDestroyed))
        .subscribe(res => {
            if (res) {
                this.save(itemData);
            } else {
                this.resetChoicesSubject.next();
                return false;
            }
        });
    }

    private anyChoicesRemoved(oldChoices): boolean {
        var newChoices = this.getChoices();
        const ids = _.filter(oldChoices, oc =>
            oc.id && !_.some(newChoices, nc => nc.id == oc.id));

        return ids.length > 0;
    }

    private save(itemData) {
        let messageName: string;
        this.isSaveButtonClicked = true;
        if (this.isMatrix) {
            messageName = this.editMode
                ? SurveysPagesActions.UPDATE_MATRIX_ITEM
                : SurveysPagesActions.ADD_MATRIX_ITEM;
        } else {
            messageName = this.editMode
                ? SurveysPagesActions.UPDATE_SURVEY_ITEM
                : SurveysPagesActions.ADD_SURVEY_ITEM;
        }

        if (this.itemType === this.questionType.RATINGSCALE) {
            delete (<RatingScaleItem>itemData).end_value;
            delete (<RatingScaleItem>itemData).start_value;
        }

        this.sharedService.share(messageName, itemData);
    }

    public cancel() {
        if (this.isMatrix) {
            this.sharedService.share(
                SurveysPagesActions.CANCEL_MATRIX_ITEM,
                null
            );
        } else {
            this.sharedService.share(
                SurveysPagesActions.CANCEL_SURVEY_ITEM,
                null
            );
        }
    }

    ngOnDestroy(): void {
        _.forEach(this.subscriptions, subscr => subscr.unsubscribe());
    }
}
