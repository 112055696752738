<form [formGroup]="form" autocomplete="off">
    <div class="form-section">
        <cb-question-collapsed-panel
            title="{{ 'SURVEY-EDITOR.ITEMS.CAPTCHA.BEHAVIOR' | translate }}"
            expanded="true"
        >
            <p class="error" *ngIf="checkCaptchaConfig">{{
                'SURVEY-EDITOR.ITEMS.CAPTCHA.CAPTCHA-TYPE-ERROR'
                    | translate
            }}</p>
            <div class="form-fields-group">
                <mat-form-field class="cb-form-field">
                    <mat-select
                        placeholder="{{
                            'SURVEY-EDITOR.ITEMS.CAPTCHA.CAPTCHA-TYPE'
                                | translate
                        }}"
                        formControlName="captcha_type"
                        [value]="questionItem.captcha_type"
                        [disabled]="!showVisibleCaptchaQuestion && !showInvisibleCaptchaQuestion"
                        #type
                    >
                        <mat-option value="Visible" [disabled]="!showVisibleCaptchaQuestion">{{
                            'SURVEY-EDITOR.ITEMS.CAPTCHA.VISIBLE' | translate
                        }}</mat-option>
                        <mat-option value="Invisible"  [disabled]="!showInvisibleCaptchaQuestion">{{
                            'SURVEY-EDITOR.ITEMS.CAPTCHA.INVISIBLE' | translate
                        }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <div
                    class="cb-form-field"
                    *ngIf="type.value !== captchaType.INVISIBLE"
                >
                    <mat-form-field>
                        <mat-select
                            placeholder="{{
                                'SURVEY-EDITOR.ITEMS.CAPTCHA.CAPTCHA-THEME'
                                    | translate
                            }}"
                            formControlName="theme"
                            [value]="questionItem.theme"
                        >
                            <mat-option value="Light">{{
                                'SURVEY-EDITOR.ITEMS.CAPTCHA.LIGHT' | translate
                            }}</mat-option>
                            <mat-option value="Dark">{{
                                'SURVEY-EDITOR.ITEMS.CAPTCHA.DARK' | translate
                            }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div
                    class="cb-form-field"
                    *ngIf="type.value !== captchaType.INVISIBLE"
                >
                    <mat-form-field>
                        <mat-select
                            placeholder="{{
                                'SURVEY-EDITOR.ITEMS.CAPTCHA.CAPTCHA-SIZE'
                                    | translate
                            }}"
                            formControlName="size"
                            [value]="questionItem.size"
                        >
                            <mat-option value="Normal">{{
                                'SURVEY-EDITOR.ITEMS.CAPTCHA.NORMAL' | translate
                            }}</mat-option>
                            <mat-option value="Compact">{{
                                'SURVEY-EDITOR.ITEMS.CAPTCHA.COMPACT'
                                    | translate
                            }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </cb-question-collapsed-panel>
    </div>
    <div class="form-section">
        <cb-question-collapsed-panel
            title="{{ 'SURVEY-EDITOR.SHARED.CONDITIONS' | translate }}"
            [tooltip]="'SURVEY-EDITOR.SHARED.TOOLTIPS.CONDITIONS' | translate"
        >
            <cb-groups-expressions-element
                [source]="questionItem"
            ></cb-groups-expressions-element>
        </cb-question-collapsed-panel>
    </div>
</form>
