import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { transformToCloudfrontUrl } from '../../../../../../../infrastructure/helpers/cloudfront.helper';

@Component({
    selector: 'cb-item-position',
    templateUrl: './item-position.component.html',
    styleUrls: ['./item-position.component.scss']
})
export class ItemPositionComponent implements OnInit {
    @Input() title: string;
    @Input() selectedOption: string;
    @Output() selected: EventEmitter<any> = new EventEmitter();

    options: any[];
    constructor() {}

    ngOnInit() {
        if (!this.title) {
            this.title = 'Item position:';
        }

        this.options = [
            {
                text: 'Left',
                imageUrl:
                    transformToCloudfrontUrl('assets/icons/appearance/align-left-default.png')
            },
            {
                text: 'Center',
                imageUrl:
                    transformToCloudfrontUrl('assets/icons/appearance/align-center-default.png')
            },
            {
                text: 'Right',
                imageUrl:
                    transformToCloudfrontUrl('assets/icons/appearance/align-right-default.png')
            }
        ];
    }

    onOptionClicked(text: string): void {
        this.selectedOption = text;
        this.selected.emit(text);
    }
}
