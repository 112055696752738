<form [formGroup]="form" autocomplete="off">
    <div class="form-section">
        <cb-question-item-title
            title="{{ 'SURVEY-EDITOR.SHARED.DISPLAY_ITEM' | translate }}"
        ></cb-question-item-title>
        <div
            [ngClass]="[
                'form-editors-group',
                'toggle-editor-toolbar',
                activeMessageToolbar
            ]"
        >
            <div
                class="reach-editor-section-overlay"
            ></div>
            <cb-tiny-rich-editor
                [ngClass]="['cb-rich-editor', messageClass]"
                placeholder="{{
                    'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.MESSAGE-FORMAT'
                        | translate
                }}"
                formControlName="messageText"
                #messageBodyEditor
            ></cb-tiny-rich-editor>
            <cb-merge-text-list
                [surveyId]="questionItem.surveyId"
                [pageId]="questionItem.page_id"
                [returnCode]="true"
                (updateText)="onMergeText($event)"
            >
            </cb-merge-text-list>
        </div>
    </div>
    <div class="form-section">
        <h3 class="form-section-title">
            {{ 'SURVEY-EDITOR.SHARED.CONDITIONS' | translate }}
            <cb-help-icon [tooltip]="'SURVEY-EDITOR.SHARED.TOOLTIPS.CONDITIONS' | translate"></cb-help-icon>
        </h3>
        <cb-groups-expressions-element
            [source]="questionItem"
        ></cb-groups-expressions-element>
    </div>
</form>
