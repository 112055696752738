<div class="dialog-content">
    <h2 mat-dialog-title class="dialog-title">
        {{ 'FROM-TEMPLATE-DIALOG.ITEM-PREVIEW' | translate }}
    </h2>
    <div class="item-card-preview">
        <cb-survey-item-preview [item]="item"></cb-survey-item-preview>
    </div>

    <div class="buttons-container">
        <button
            cdkFocusInitial
            mat-button
            type="button"
            class="btn-cancel"
            mat-dialog-close
        >
            {{ 'SHARED.CLOSE' | translate }}
        </button>
    </div>
</div>
