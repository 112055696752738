import { JavascriptItem } from '../../../../../shared/models/survey-items/action-items/javascriptItem';
import { SharedService } from '../../../../../infrastructure/services';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../../../shared/decorators/autoUnsubscribe.decorator';
import { takeUntil } from 'rxjs/operators';
import { CodeModel } from '@ngstack/code-editor';


@Component({
    selector: 'cb-javascript-item',
    templateUrl: './javascript-item.component.html',
    styleUrls: ['./javascript-item.component.scss']
})
@AutoUnsubscribe()
export class JavascriptItemComponent implements OnInit, OnDestroy {
    @Input() actionItem: JavascriptItem;

    form: FormGroup;
    script: string;
    private componentDestroyed = new Subject();

    theme = 'vs-dark';

    scriptModel: CodeModel = {
        language: 'javascript',
        uri: 'main.js',
        value: ''
    };

    constructor(
        private fb: FormBuilder,
        private sharedService: SharedService
    ) {}

    ngOnInit() {
        if (!this.actionItem) {
            this.actionItem = <JavascriptItem>{};
        }

        this.form = this.fb.group({});
        this.form.addControl('script', new FormControl(this.actionItem.script));
        this.script = this.actionItem.script;
        this.scriptModel.value = this.script;
        this.createActionItemObject();
    }

    onChange(code) {
        this.script = code;
        this.form['controls']['script'].setValue(code);
    }
    loaded() {
        console.log('LOADED:');
      }

    private createActionItemObject(): void {
        const control = this.form.controls;
        this.form.valueChanges
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(s => {
                const actionItem = {
                    ...this.actionItem,
                    ...(<JavascriptItem>{
                        script: control['script'].value
                    })
                };
                this.sharedService.share('actionItemSettings', actionItem);
            });
    }

    ngOnDestroy() {}
}
