import { FileExtension } from '../../../../models';
import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FileUploadItem } from '../../../../../shared/models/survey-items/question-items/fileUploadItem';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { SharedService } from '../../../../../infrastructure/services';
import { SurveyQuestionType } from '../../../../../infrastructure/consts/surveys.consts';
import { FileExtensionsProvider } from '../../../../providers';
import { Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../../../shared/decorators/autoUnsubscribe.decorator';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'cb-file-upload-item',
    templateUrl: './file-upload-item.component.html',
    styleUrls: ['./file-upload-item.component.scss']
})
@AutoUnsubscribe()
export class FileUploadItemComponent implements OnInit, OnDestroy {
    @Input() questionItem: FileUploadItem;

    form: FormGroup;

    fileUpload = SurveyQuestionType.FILE_UPLOAD;
    itemPosition: string;
    textPosition: string;
    allowedFileTypes: any;
    allowed_file_types: any[];
    allowedExtension: any[];
    isLoading: boolean;
    private componentDestroyed = new Subject();

    constructor(
        private fb: FormBuilder,
        private sharedService: SharedService,
        private fileExtensionsProvider: FileExtensionsProvider,
        private cdr: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
        this.isLoading = true;
        this.fileExtensionsProvider.getFileExtensions().subscribe(types => {
            this.allowedFileTypes = types;
            this.allowedExtension = this.allowedFileTypes.map(item => item.extension);
            this.allowed_file_types = this.questionItem.allowed_file_types
                ? this.questionItem.allowed_file_types.filter(item => {
                    if (this.allowedExtension.includes(item)) {
                        return item;
                    }
                })
                : [];
            this.createFormGroup();
            this.createQuestionItemObject();
            this.isLoading = false;
            this.cdr.detectChanges();
        }, err => this.isLoading = false);
    }

    itemPositionSelected(value): void {
        this.itemPosition = value;
        this.form.controls['item_position'].setValue(value);
    }

    labelPositionSelected(value): void {
        this.textPosition = value;
        this.form.controls['question_text_position'].setValue(value);
    }

    onTypeSelected(type) {
        if (this.allowed_file_types.includes(type)) {
            this.allowed_file_types = this.allowed_file_types.filter(
                c => c !== type
            );
        } else {
            this.allowed_file_types.push(type);
        }
        this.form['controls']['allowed_file_types'].setValue(
            this.allowed_file_types
        );
    }

    isChecked(type) {
        if (!this.allowed_file_types) {
            return false;
        }
        return this.allowed_file_types.includes(type);
    }

    maxSizeErrorMessage() {
        return this.form.controls['max_size'].hasError('min')
            ? 'You must enter a value more then 0'
            : this.form.controls['max_size'].hasError('max')
            ? 'You must enter a value less then 2 000 000 001'
            : '';
    }

    private createFormGroup(): void {
        let isAllQuestionsRequired = false;
        if (!this.questionItem) {
            this.questionItem = <FileUploadItem>{};
        }
        if (!this.questionItem.id) {
            isAllQuestionsRequired = this.sharedService.surveyRespondentSettings.getValue() ?
            this.sharedService.surveyRespondentSettings.getValue().makeQuestionsRequired : false;
        }
        this.form = this.fb.group({
            alias: new FormControl(this.questionItem.alias),
            item_position: new FormControl(this.questionItem.item_position),
            question_text_position: new FormControl(
                this.questionItem.question_text_position
            ),
            html_class: new FormControl(this.questionItem.html_class),
            is_required: new FormControl(this.questionItem.is_required || isAllQuestionsRequired),
            is_soft_required: new FormControl(this.questionItem.softRequiredEnabled ? !!this.questionItem.is_soft_required : false),
            allowed_file_types: new FormControl(this.allowed_file_types),
            max_size: new FormControl(this.questionItem.max_size, [Validators.min(1), Validators.max(2000000000)]),
            expiration_in_hours: new FormControl(this.questionItem.expiration_in_hours)
        });

        this.itemPosition = this.questionItem.item_position;
        this.textPosition = this.questionItem.question_text_position;
    }

    private createQuestionItemObject(): void {
        this.updateQuestionItemObject();
        this.form.valueChanges
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(() => {
                this.updateQuestionItemObject();
            });
    }

    private updateQuestionItemObject(): void {
        const control = this.form.controls;
        const questionItem = new FileUploadItem();
        questionItem.item_type = SurveyQuestionType.FILE_UPLOAD;
        questionItem.alias = control['alias'].value;
        questionItem.question_text_position =
            control['question_text_position'].value;
        questionItem.item_position = control['item_position'].value;
        questionItem.html_class = control['html_class'].value;
        questionItem.is_required = control['is_required'].value;
        questionItem.is_soft_required = control['is_soft_required'].value;
        questionItem.allowed_file_types = control['allowed_file_types'].value;
        questionItem.max_size = this.getPositiveMaxSize(control['max_size'].value);
        questionItem.expiration_in_hours = control['expiration_in_hours'].value;

        this.sharedService.share('questionItemSettings', questionItem);
    }

    private getPositiveMaxSize(maxSize: number): number {
        if (maxSize == null) {
            return maxSize;
        }

        const maxSizeRounded = Math.round(Math.abs(maxSize));
        return maxSizeRounded == 0
            ? 1
            : maxSizeRounded;
    }

    ngOnDestroy() {}
}
