<form [formGroup]="form" autocomplete="off">
    <div class="form-section">
        <cb-question-item-title></cb-question-item-title>
        <div class="cb-change-type-section">
            <cb-item-type [itemType]="rankOrder"></cb-item-type>
        </div>
        <cb-question-and-subtext
            [questionItem]="questionItem"
        ></cb-question-and-subtext>
    </div>
    <div class="form-section">
        <cb-multiple-options-image-item
            *ngIf="questionItem.option_type === 'Image'"
            [options]="questionItem.choices"
            [questionType]="rankOrder"
            [resetChoicesEvent] = "resetChoicesSubject.asObservable()"
        ></cb-multiple-options-image-item>
        <cb-multiple-options-item
            *ngIf="questionItem.option_type !== 'Image'"
            [surveyId]="questionItem.surveyId"
            [pageId]="questionItem.page_id"
            [options]="questionItem.choices"
            [expanded]="true"
            [questionType]="rankOrder"
            [hideHtml]="false"
            [resetChoicesEvent] = "resetChoicesSubject.asObservable()"
        >
        </cb-multiple-options-item>
    </div>

    <cb-behavior-options 
        [parentForm]="form"
        [softRequiredEnabled]="questionItem.softRequiredEnabled"
        [hideRequired]="rankOrderType === rankOrderTypes.DRAGN_DROPPABLE"
        >
        <div class="form-fields-group">
            <mat-form-field class="cb-form-field">
                <mat-select
                    formControlName="rank_order_type"
                    placeholder="{{
                        'SURVEY-EDITOR.ITEMS.RANK_ORDER.TYPE' | translate
                    }}"
                    (selectionChange)="checkRankType($event)"
                    required
                >
                    <mat-option value="SelectableDragnDrop">{{
                        'SURVEY-EDITOR.ITEMS.RANK_ORDER.SELECTABLE'
                            | translate
                    }}</mat-option>
                    <mat-option value="DragnDroppable">{{
                        'SURVEY-EDITOR.ITEMS.RANK_ORDER.DRAG-DROP'
                            | translate
                    }}</mat-option>
                    <mat-option value="Numeric">{{
                        'SURVEY-EDITOR.ITEMS.RANK_ORDER.NUMERIC' | translate
                    }}</mat-option>
                    <mat-option value="TopN">{{
                        'SURVEY-EDITOR.ITEMS.RANK_ORDER.DROP-DOWN'
                            | translate
                    }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field
                class="cb-form-field"
                *ngIf="rankOrderType !== rankOrderTypes.TOP_N"
            >
                <mat-select
                    formControlName="option_type"
                    placeholder="{{
                        'SURVEY-EDITOR.ITEMS.RANK_ORDER.OPTION-TYPE'
                            | translate
                    }}"
                    [value]="questionItem.option_type"
                    (selectionChange)="changeType($event)"
                    required
                >
                    <mat-option value="Text">{{
                        'SURVEY-EDITOR.SHARED.TEXT' | translate
                    }}</mat-option>
                    <mat-option value="Image">{{
                        'SURVEY-EDITOR.SHARED.IMAGE' | translate
                        }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field
                class="cb-form-field"
                *ngIf="rankOrderType !== rankOrderTypes.DRAGN_DROPPABLE"
            >
                <input
                    matInput
                    type="text"
                    formControlName="min_to_rank"
                    (keydown)="onNumericKeyUp($event)"
                    placeholder="{{
                        'SURVEY-EDITOR.ITEMS.RANK_ORDER.MIN-TO-RANK'
                            | translate
                    }}"
                />
            </mat-form-field>
            <mat-form-field
                class="cb-form-field"
                *ngIf="rankOrderType !== rankOrderTypes.DRAGN_DROPPABLE"
            >
                <input
                    matInput
                    type="text"
                    formControlName="max_to_rank"
                    (keydown)="onNumericKeyUp($event)"
                    placeholder="{{
                        'SURVEY-EDITOR.ITEMS.RANK_ORDER.MAX-TO-RANK'
                            | translate
                    }}"
                />
            </mat-form-field>
            
            <mat-checkbox class="cb-form-field" formControlName="randomize"
            >{{ 'SURVEY-EDITOR.SHARED.RANDOMIZE' | translate }}
        </mat-checkbox>
        </div>
    </cb-behavior-options>

    <div class="form-section border-top">
        <cb-question-collapsed-panel
            title="{{
                'SURVEY-EDITOR.SHARED.APPEARANCE.APPEARANCE' | translate
            }}"
        >
            <div class="form-group-position">
                <cb-label-position
                    class="position-control-group"
                    [selectedOption]="textPosition"
                    (selected)="onLabelPositionSelected($event)"
                ></cb-label-position>
                <cb-item-position
                    class="position-control-group"
                    [selectedOption]="itemPosition"
                    (selected)="onItemPositionSelected($event)"
                ></cb-item-position>
            </div>
        </cb-question-collapsed-panel>
    </div>
    <div class="form-section">
        <cb-question-collapsed-panel
            title="{{ 'SURVEY-EDITOR.SHARED.CONDITIONS' | translate }}"
            [tooltip]="'SURVEY-EDITOR.SHARED.TOOLTIPS.CONDITIONS' | translate"
        >
            <cb-groups-expressions-element
                [source]="questionItem"
            ></cb-groups-expressions-element>
        </cb-question-collapsed-panel>
    </div>
    <cb-wcag-alert
        *ngIf="
            rankOrderType === rankOrderTypes.DRAGN_DROPPABLE ||
            rankOrderType === rankOrderTypes.SELECTABLE_DRAGN_DROP
        "
    ></cb-wcag-alert>
</form>
