import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ApplicationSettingsProvider } from '../../../../../../app-admin/settings/application-settings/application-settings.provider';

@Component({
    selector: 'cb-behavior-options',
    templateUrl: './behavior-options.component.html',
    styleUrls: ['./behavior-options.component.scss']
})

export class BehaviorOptionsComponent implements OnInit {
    @Input() parentForm: FormGroup;
    @Input() softRequiredEnabled: boolean;
    @Input() hideRequired: boolean;
    @Input() expanded: boolean;
    @Input() isContactFormField: boolean = false;
    showHtml: boolean;
    showAlias: boolean;
    onlyRequired: boolean;
    withSoftRequired: boolean;
    requiredOptions: string;
    controls;
    showCustomCss: boolean;

    constructor(
        private applicationSettingsProvider: ApplicationSettingsProvider
    ) {
    }

    ngOnInit() {
        this.controls = this.parentForm.controls;
        if (this.softRequiredEnabled && !!this.controls.is_soft_required) {
            this.subscribeOnRequired();
        }
        this.showHtml = !!this.controls.html_class;
        this.showAlias = !!this.controls.alias;
        this.onlyRequired = !this.softRequiredEnabled && !!this.controls.is_required;
        this.withSoftRequired = this.softRequiredEnabled && !!this.controls.is_soft_required;

        this.applicationSettingsProvider
            .getSurveyApplicationSettings()
            .pipe(first())
            .subscribe(data => {
                this.showCustomCss = data.advanced_survey_controls_settings.enable_survey_wide_css;
            });
    }

    subscribeOnRequired() {
        this.controls[
            'is_required'
        ].valueChanges.subscribe(
            option => {
                if (option) {
                    if (!!this.controls['select_min_limit'] && !this.controls['select_min_limit'].value) {
                        this.controls['select_min_limit'].setValue(1);
                    }
                    if (this.controls['is_soft_required'].value) {
                        this.controls['is_soft_required'].setValue(false);
                    }
                } else {
                    if (!!this.controls['select_min_limit']) {
                        this.controls['select_min_limit'].setValue(null);
                    }
                }
            }
        );
        this.controls[
            'is_soft_required'
        ].valueChanges.subscribe(
            option => {
                if (option &&  this.controls['is_required'].value) {
                    this.controls['is_required'].setValue(false);
                }
            }
        );
    }
}
