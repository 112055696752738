import { merge as observableMerge, of } from 'rxjs';

import { catchError, map, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';

import { error, surveys } from '../actions';
import { PagedList } from '../../../infrastructure/models';
import { Survey, SurveyListGetData } from '../../models';
import { SurveyListProvider } from '../../providers';
import { switchMap } from 'rxjs/operators';
import { SurveysStore } from '../survey.store';

@Injectable()
export class SurveyEffect {
    constructor(
        private actions$: Actions,
        private surveyListProvider: SurveyListProvider,
        private surveyStore: SurveysStore
    ) {}

    loadSurveyList$ = createEffect(() =>{
        return this.actions$.pipe(
            ofType(surveys.RELOAD_SURVEY_LIST),
            map((action: surveys.ReloadSurveyListAction) => action.payload),
            withLatestFrom(this.surveyStore.parameters),
            switchMap(([before, data]) =>
                this.surveyListProvider.loadSurveys(data??before).pipe(
                    map((s: PagedList<Survey>) => new surveys.ReloadSurveyListSuccessAction(s)),
                    catchError(err =>
                        observableMerge(
                            of(new error.HandleErrorAction(err)),
                            of(new surveys.SurveyFailedAction())
                        )
                    )
                )
            )
        );
    });
    loadMoreSurveyList$ = createEffect(() =>{
        return this.actions$.pipe(
            ofType(surveys.LOAD_MORE_SURVEY_LIST),
            map((action: surveys.MoreSurveyListAction) => action),
            withLatestFrom(this.surveyStore.parameters),
            switchMap(([_, data]) =>
                this.surveyListProvider.loadSurveys(data).pipe(
                    map((s: PagedList<Survey>) => new surveys.MoreSurveyListSuccessAction(s)),
                    catchError(err =>
                        observableMerge(
                            of(new error.HandleErrorAction(err)),
                            of(new surveys.SurveyFailedAction())
                        )
                    )
                )
            )
        );
    });
    loadSurveyFavorites$ = createEffect(() =>{
        return this.actions$.pipe(
            ofType(surveys.RELOAD_FAVORITE_SURVEYS_LIST),
            map((action: surveys.ReloadSurveyFavoritesAction) => action.payload),
            withLatestFrom(this.surveyStore.favoriteParameters),
            switchMap(([beforeGetData, data]) =>
                this.surveyListProvider.loadFavoriteSurveys(data??beforeGetData).pipe(
                    map((s: PagedList<Survey>) => new surveys.ReloadSurveyFavoritesSuccessAction(s)),
                    catchError(err =>
                        observableMerge(
                            of(new error.HandleErrorAction(err)),
                            of(new surveys.SurveyFailedAction())
                        )
                    )
                )
            )
        );
    });
}
